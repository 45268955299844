import React, {useState, useEffect} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import {tipoInstalaciones} from '../js/constdata';

export function YCSFormFieldTipoDeCentro(props){
    const [tipoDeInstalaciones, setTipoDeInstalaciones] = useState([]);
    useEffect(() => {
        setTipoDeInstalaciones(tipoInstalaciones);
    }, []);
    
    return (
        <Form.Field
            {...props} control={Dropdown}  placeholder='Seleccione' 
             fluid selection search name='tipos' options={tipoDeInstalaciones}
            value={props.value} onChange={props.onChange} 
        />
    );
}
