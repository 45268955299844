import _ from 'lodash'
import React, { Component } from 'react';
import {Message, Checkbox, TextArea, Form, Header, Modal, Button, Menu, Dropdown, Input, Grid, Popup} from 'semantic-ui-react';
import {Storage} from './js/storage';
import 'react-datepicker/dist/react-datepicker.css';
import {Treebeard} from 'react-treebeard';
import ListaAvisos from './ListaAvisos'
import tree from './js/treebeard'
import {prioridades} from './js/constdata';
import {IO} from './js/IO';
import { YCSFormFieldCentroDeportivo } from './YCSComponents/YCSFormFieldCentroDeportivo';
import { AppContext } from './context/AppContext';

class Avisos extends Component {
	static contextType = AppContext;
	tableData = [];
	offset = 0;
	constructor(props) {
		super(props);
		this.state = {
			data: this.tableData,
			selected: {},
			centro: this.updateFiltro('centro',false),
			empresa: this.assignEmpresa(),
			user: Storage.getStorage('user'),
			text:  this.updateFiltro('text',false,""),
		};
		
		this.updateList = this.updateList.bind(this);
		this.paginate = this.paginate.bind(this);
	};
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresa: (next.empresageneral!==-1?next.empresageneral:null)}, () => this.getData());
	}
	borrarFiltros = () => {
		this.props.history.replace({...this.props.history.location, search: ""})
		this.setState({centro:null,empresa:this.assignEmpresa(),text:""}, () => this.getData());
	 } 
	assignEmpresa = () => {
		if(this.props.empresageneral && this.props.empresageneral!==-1){
			return this.props.empresageneral;
		}
		return this.updateFiltro('empresa',false);
	 }
	assignFiltros = () => {
		const query = new URLSearchParams(this.props.history.location.search);
	}
	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value }, () => this.getData());
		this.updateURLFiltros(name,value);
	 }
	updateFiltro = (name,array,defaul) =>{
		const query = new URLSearchParams(this.props.history.location.search);
		var filtro = query.get(name);
		if (!filtro || filtro===""){
			return defaul == null ? (array ? [] : null) :  defaul ;
		}else{
			return  array ? filtro.split(',') : filtro;
		}
	 } 
	updateURLFiltros = (name,value) =>{
		const query = new URLSearchParams(this.props.history.location.search);
		query.set(name, value);
		this.props.history.replace({...this.props.history.location, search: query.toString()})
	 }
	handleFilter = (e, { value }) => {
		this.updateURLFiltros('text',value);
		this.setState({ text: value }, () => this.getData());
	}
	updateList = (nuevo) => { 
		this.getData();
	 }
	paginate = () => this.getData(true);

	getData = (paginate) => {	
		const {setNumberOfWarnings} = this.context;
		if(!this.state.loading){
			if(paginate){
				if(this.offset===-1) return;
				this.offset = this.offset+1; 
				this.setState({loading: true});
			}else{
				this.offset = this.offset=0; 
				this.tableData = [];
				this.setState({data: [],  loading: true});
			}
			
			IO.axios.get('/data/avisos', {
						params: {
							centro: this.state.centro,
							empresa: this.state.empresa,
							offset : this.offset,
							texto : this.state.text ? this.state.text : null
						}	
			}).then(response => {
				setNumberOfWarnings(response.data.length);		// Actualizamos el pill con el número actual de avisos
				this.tableData = paginate ? _.concat(this.tableData,response.data) : response.data;
				this.setState({ data: this.tableData, loading: false});
				if(response.data.length===0) this.offset=-1
			});
		}
	 }
	componentDidMount() {
		this.getData();
	 } 

	render() {
		const { data, centro, empresa, loading} = this.state;
		// console.log("this.state Avisos.jsx: ", this.state);
		
		return (
			<Grid  style={{ margin: '1em' }}>
				<Grid.Row>
				  <Grid.Column width={4}>
						<Menu vertical>
							{this.state.user.roles.SERVICIO && 
							<Menu.Item>
								Acciones
								 <Menu.Menu>								
										<Menu.Item>  <NuevoAviso fluid updateList={this.updateList} 
										empresageneral={this.props.empresageneral}
										usuario={this.state.user.nombre}/>
										</Menu.Item> 
								</Menu.Menu>
							</Menu.Item>
							}
							<Menu.Item>
								Filtros
								<Menu.Menu>
									<Menu.Item>
										Texto
										<Input placeholder='...' value={this.state.text} onChange={this.handleFilter}/>
									</Menu.Item>
									<Menu.Item>
										Centro
										<Dropdown placeholder='Seleccione' fluid search selection options={Storage.getStorage('centros_padres')} name="centro" value={centro} onChange={this.handleChange}/>
									</Menu.Item>
									{this.state.user.roles.IMD && <Menu.Item>
										Empresa
										<Dropdown disabled={this.props.empresageneral !== -1} placeholder='Seleccione' fluid selection options={Storage.getStorage('empresas')} name="empresa" value={empresa} onChange={this.handleChange}/>
									</Menu.Item>}
									<Menu.Item>
										<Button content='Borrar Filtros' floated='right' size='mini' onClick={this.borrarFiltros} style={{marginBottom:'5px'}}/>
									</Menu.Item>
								</Menu.Menu>
							</Menu.Item>
						</Menu>
					</Grid.Column>
					<Grid.Column width={12}> 
						<ListaAvisos data={data} update={this.getData} loading={loading} 
								paginate={this.paginate} 
								empresageneral={this.props.empresageneral}
								onSelect={(selected) => this.setState({selected, openParte:true})}/> 
						{this.state.user.roles.IMD && <NuevaIncidencia 
									row={this.state.selected} 
									open={this.state.openParte}
									onClose={() => this.setState({openParte:false})}
									updateList={() => this.updateList()}></NuevaIncidencia>}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
  }
}

class NuevaIncidencia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			centro: this.props.row.centro_id, 
			subcentro : this.props.row.subcentro_id,
			empresa: this.props.row.empresa_id, 
			inspector: null, 
			descripcion: this.props.row.descripcion, 
			prioridad: 'NORMAL',
			creador:"-1", idimd : "", otro : this.props.row.autor,
			equipamiento: this.props.row.material_id, 
			email : false, otro : this.props.row.otro, equipamientos:[],
			invalidate : null, diablebutton: false, subcentros : [], 
		};
		this.updateEquipamiento = this.updateEquipamiento.bind(this);
	};
	componentDidMount() {
		IO.axios.get('/data/inventario', {
			params: {centro: this.props.centro}})
			.then(response => {
				this.setState({equipamientos : _.map(response.data, o => {return {key: o.id, text:o.nombre+' ['+o.codigo+']', value:o.id}})}); 
			});
		this.setState({subcentros:  _.filter(Storage.getStorage('centros_todos'),{centro_id:this.props.centro})}); 
	} 
	componentWillReceiveProps(next){
		if(next.row.id === this.props.row.id) return
		this.setState({
			centro: next.row.centro_id, 
			subcentro : next.row.subcentro_id,
			empresa: next.row.empresa_id, 
			descripcion: next.row.descripcion, 
			equipamiento: next.row.material_id, 
			creador:"-1",
			otro : next.row.autor
		});
	}
	handleChange = (e, { name, value }) => {
		if(name === 'email'){
			this.setState({ [name]: !this.state.email });
		}else if (name === 'creador' && value && value!=='-1'){
			this.setState({ [name]: value });
			this.setState({ otro: _.filter(Storage.getStorage('auxiliares'),{id:value})[0].text });                                  
		}else
			this.setState({ [name]: value });
		if (this.state.centro && (this.state.subcentro || this.state.subcentros.length===0) && this.state.empresa && this.state.inspector && this.state.descripcion && this.state.prioridad) {
			this.setState({invalidate:false});
		}
	}
	handleCentroChange = (e, { name, value }) => {
		this.setState({ [name]: value });
		IO.axios.get('/data/inventario', {params: {centro: value}})
			.then(response => {
				this.setState({equipamientos :
					_.map(response.data, o => {return {key: o.id, text:o.nombre+' ['+o.codigo+']', value:o.id}}) }); 
			});
		this.setState({subcentros:  _.filter(Storage.getStorage('centros_todos'),{centro_id:value})}); 
	}

	updateEquipamiento = (o) => { 
		var equip = {key: o.id, text:o.nombre+' ['+o.codigo+']', value:o.id};
		this.state.equipamientos.push(equip); 
		this.setState({equipamiento:equip.value});
	}
	createIncidencia = () => {
		if (this.state.centro && this.state.empresa && this.state.inspector && this.state.descripcion && this.state.prioridad) {
			this.setState({invalidate:false, diablebutton:true});
			var allFields = '{ "centro":"'+this.state.centro+
			'", "subcentro":"' +this.state.subcentro+
			'", "empresa":"' +this.state.empresa+
			'", "inspector":"' +this.state.inspector+
			'", "prioridad":"'+this.state.prioridad+
			'", "descripcion":"'+this.state.descripcion.replace(/\r?\n/g, '<br/>')+
			'", "email":'+ this.state.email +
			', "material":"'+ (this.state.equipamiento && this.state.equipamiento)+
			'", "idimd":"'+ this.state.idimd+
			'", "autor":"'+this.state.otro.replace(/\r?\n/g, '<br/>')+'"}';
			IO.axios({
				url:'/data/partes', 
				method: 'post',
				data: allFields})
				.then(response => {
					IO.axios({
						url:'/data/avisos.php/'+this.props.row.id, 
						method: 'delete'})
						.then(resp => {
							this.setState({ open: false });
							this.props.updateList(response.data.result);	
							this.resetComponent();
							this.close();	
						});
				});
		}else{
			this.setState({invalidate:true});
		}
	}
	open = () => this.setState({ open: true })
	close = () => this.props.onClose()
	cancel = () => {
		this.resetComponent();
		this.close();
	}
	resetComponent = () => this.setState({
			centro: this.props.row.centro_id, 
			subcentro : this.props.row.subcentro_id,
			empresa: this.props.row.empresa_id, 
			inspector: null, 
			descripcion: this.props.row.descripcion, 
			prioridad: 'NORMAL',
			creador:"-1", idimd : "", otro : this.props.row.autor,
			equipamiento: this.props.row.material_id, 
			email : false, otro : this.props.row.otro, 
			invalidate : null, diablebutton: false
	  })
  render() {
		const { creador, equipamientos, equipamiento, invalidate, centro, empresa, inspector, descripcion, prioridad, idimd , otro, email, subcentros, subcentro} = this.state
    return (
      <Modal open={this.props.open} onOpen={this.open} onClose={this.close}>
        <Modal.Header>Nueva Incidencia</Modal.Header>
        <Modal.Content>
					<Form error={invalidate}>
						<Form.Group widths='equal'>
							<Form.Field control={Dropdown} label='[Servicio] Empresa' placeholder='Seleccione' fluid selection search 
													name='empresa' options={Storage.getStorage('empresas')} value={empresa}
													error={!empresa && invalidate} disabled={true}/>
							<Form.Field control={Dropdown} label='Prioridad' placeholder='Seleccione' fluid selection search 
													name='prioridad' options={prioridades} value={prioridad}
													error={!prioridad && invalidate} onChange={this.handleChange}/>
						</Form.Group>
						<Form.Group widths='equal'>
							<YCSFormFieldCentroDeportivo value={centro} onChange={this.handleCentroChange} error={!centro && invalidate}/>
							{subcentros.length >0 && <Form.Field control={Dropdown} label='Subcentro' placeholder='Seleccione' fluid selection search 
													name='subcentro' options={subcentros} value={subcentro} 
													error={subcentros.length>0 && !subcentro && invalidate} onChange={this.handleChange}/>}
						</Form.Group>
						<Form.Group widths='equal'>
							<Form.Field control={Dropdown} label='Inspector' placeholder='Seleccione' fluid selection search 
												name='inspector' options={Storage.getStorage('inspectores')}  value={inspector}
												error={!inspector && invalidate} onChange={this.handleChange}/>
							<Form.Field control={Dropdown} label='Autor' placeholder='Seleccione' fluid selection search  value={creador}
												name='creador' options={Storage.getStorage('auxiliares')} disabled={true}/>
						</Form.Group>
						{ creador==="-1" && <Form.Field control={Input} placeholder='Describa el autor' name='otro' disabled={true} value={otro}/>}
						<Grid>
								<Grid.Row>
										<Grid.Column width={10}>
											<Form.Field control={Dropdown} label='Equipamiento' placeholder='Seleccione' fluid selection search 
													value={equipamiento} name='equipamiento' options={equipamientos} onChange={this.handleChange}/>
										</Grid.Column>
										<Grid.Column width={3}>
											<Form.Field control={Input} label='Id IMD' name='idimd' onChange={this.handleChange} value={idimd}/>
										</Grid.Column>
										<Grid.Column width={3} verticalAlign='bottom'>
											<Form.Field control={CreateInventarioModal} centro={centro} disabled={!centro} update={this.updateEquipamiento}/>
										</Grid.Column>
										
								</Grid.Row>
						</Grid>
						<Form.Field control={TextArea} label='Descripción' name='descripcion' error={!descripcion && invalidate} onChange={this.handleChange} value={descripcion}/>
						<Form.Field control={Checkbox} label='Marque para enviarse el correo a usted mismo' name='email' onChange={this.handleChange} checked={email}/>
					
						<Message
							error
							header='Error en formulario'
							content='Complete los campos obligatorios (en rojo).'
						/>
					</Form>
        </Modal.Content>
        <Modal.Actions>
        	<Button  content='Aceptar' onClick={this.createIncidencia} disabled={this.state.diablebutton}/>	
			<Button  content='Cancelar' onClick={this.cancel}  />
        </Modal.Actions>
      </Modal>
    )
  }
}

class NuevoAviso extends Component {
	static contextType = AppContext;

	constructor(props) {
		super(props);
		this.state = {
			open: false,
			centro: this.props.centro, 
			empresa: this.props.empresageneral !== -1 ? this.props.empresageneral : null, 
			descripcion: '',
			creador: null,
			equipamientos:[], 
			equipamiento: (this.props.equipamiento==null) ? null : this.props.equipamiento, 
			email : false, 
			otro : this.props.usuario.trim(), 
			invalidate : null, diablebutton: false, 
			subcentros : [], subcentro : null
		};
		if(this.props.centro){
			IO.axios.get('/data/inventario', {
				params: {centro: this.props.centro}})
				.then(response => {
					this.setState({equipamientos : _.map(response.data, o => {return {key: o.id, text:o.nombre+' ['+o.codigo+']', value:o.id}})}); 
				});
			this.setState({subcentros:  _.filter(Storage.getStorage('centros_todos'),{centro_id:this.props.centro})}); 
		}
		this.updateEquipamiento = this.updateEquipamiento.bind(this);
	};
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresa: (next.empresageneral!==-1?next.empresageneral:null)});
	}
	handleChange = (e, { name, value }) => {
		if(name === 'email'){
			this.setState({ [name]: !this.state.email });
		}else
			this.setState({ [name]: value });
		if (this.state.centro && (this.state.subcentro || this.state.subcentros.length===0) && this.state.empresa && this.state.descripcion) {
			this.setState({invalidate:false});
		}
	}
	handleCentroChange = (e, { name, value }) => {
		this.setState({ [name]: value });
		IO.axios.get('/data/inventario', {params: {centro: value}})
			.then(response => {
				this.setState({equipamientos :
					_.map(response.data, o => {return {key: o.id, text:o.nombre+' ['+o.codigo+']', value:o.id}}) }); 
			});
		this.setState({subcentros:  _.filter(Storage.getStorage('centros_todos'),{centro_id:value})}); 
	}
	updateEquipamiento = (o) => { 
		var equip = {key: o.id, text:o.nombre+' ['+o.codigo+']', value:o.id};
		this.state.equipamientos.push(equip); 
		this.setState({equipamiento:equip.value});
	}
	createAviso = () => {
		if (this.state.centro && this.state.empresa && this.state.descripcion ) {
			this.setState({invalidate:false, diablebutton:true});
			var allFields = '{ "centro":"'+this.state.centro+
			'", "subcentro":"' +this.state.subcentro+
			'", "empresa":"' +this.state.empresa+
			'", "descripcion":"'+this.state.descripcion.replace(/\r?\n/g, '<br/>')+
			'", "email":'+ this.state.email +
			', "material":"'+ (this.state.equipamiento && this.state.equipamiento)+
			'", "autor":"'+this.state.otro.replace(/\r?\n/g, '<br/>')+'"}';
			IO.axios({
				url:'/data/avisos', 
				method: 'post',
				data: allFields})
				.then(response => {
							this.setState({ open: false });
							this.props.updateList(response.data.result);	
							this.resetComponent();
				});
		}else{
			this.setState({invalidate:true});
		}
	}

	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	cancel = () => {
		this.resetComponent();
		this.close();
	}
	resetComponent = () => this.setState({
		centro: this.props.centro, descripcion: '',
		empresa: this.props.empresageneral !== -1 ? this.props.empresageneral : null, 
		creador: null, equipamiento: null,email : false,otro : this.props.usuario.trim(),
		equipamientos:[], invalidate : null, diablebutton:false,  subcentros : [], subcentro : null
		})
	render() {
		const { open, equipamientos, equipamiento, invalidate, centro, descripcion, email, subcentros, subcentro} = this.state;
		// console.log("this.state: Avisos.jsx", this.state);

		return (
		<Modal
			open={open}
			onOpen={this.open}
			onClose={this.close}
			size='small'
			trigger={<Button primary fluid={this.props.fluid}>Nuevo Aviso</Button>}
		>
			<Modal.Header>Nuevo Aviso</Modal.Header>
			<Modal.Content>
						<Form error={invalidate}>
							<Form.Group widths='equal'>
								<Form.Field control={Dropdown} label='Centro Deportivo' placeholder='Seleccione' fluid selection search 
														name='centro' options={Storage.getStorage('centros_padres')} value={centro} 
														error={!centro && invalidate} onChange={this.handleCentroChange}/>
								{subcentros.length >0 && <Form.Field control={Dropdown} label='Subcentro' placeholder='Seleccione' fluid selection search 
														name='subcentro' options={subcentros} value={subcentro} 
														error={subcentros.length>0 && !subcentro && invalidate} onChange={this.handleChange}/>}
							</Form.Group>
							<Grid>
								<Grid.Row>
										<Grid.Column width={10}>
											<Form.Field control={Dropdown} label='Equipamiento' placeholder='Seleccione' fluid selection search 
													value={equipamiento} name='equipamiento' options={equipamientos} onChange={this.handleChange}/>
										</Grid.Column>
										<Grid.Column width={3} verticalAlign='bottom'>
											<Form.Field control={CreateInventarioModal} centro={centro} disabled={!centro} update={this.updateEquipamiento}/>
										</Grid.Column>
										
								</Grid.Row>
							</Grid>
							<Form.Field control={TextArea} label='Descripción' name='descripcion' error={!descripcion && invalidate} onChange={this.handleChange} value={descripcion}/>
							<Form.Field control={Checkbox} label='Marque para enviarse el correo a usted mismo' name='email' onChange={this.handleChange} checked={email}/>
						
							<Message
								error
								header='Error en formulario'
								content='Complete los campos obligatorios (en rojo).'
							/>
						</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button  content='Aceptar' onClick={this.createAviso} disabled={this.state.diablebutton}/>	
				<Button  content='Cancelar' onClick={this.cancel}  />
			</Modal.Actions>
		</Modal>
		)
	}
}

class CreateInventarioModal extends Component {
  constructor(props) {
		super(props);
		this.state = {
			open: false,
			nombre: null,
			tipos: [],
			cursor: null,
			invalidate : null
		};
		this.onToggle = this.onToggle.bind(this);
	};

	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value });
		if (this.state.nombre && this.state.cursor) {
			this.setState({invalidate:false});
		}
	 }
	createEquipamiento = () => {
		if (this.state.nombre && this.state.cursor) {
			this.setState({invalidate:false});
			var allFields = '{ "centro":"'+this.props.centro+
					'", "nombre":"' +this.state.nombre+
					'", "tipo":"'+this.state.cursor.key+'"}';
			
			IO.axios({
				url:'/data/inventario', 
				method: 'post',
				data: allFields})
				.then(response => {
							this.setState({ open: false });
							this.props.update(response.data.result);	
							this.resetComponent();
				});
		}else{
			this.setState({invalidate:true});
		}
	 }
	resetComponent = () => this.setState({nombre: null, cursor: null,	invalidate : null})
  open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	cancel = () => {
		this.resetComponent();
		this.close();
	 }
	componentDidMount() {
		IO.axios.get('/data/tipomaterial')
		.then(response => {
			this.setState({ tipos: JSON.parse(_.replace(JSON.stringify(response.data),/title/g,'name')) });
		});
	 }
	onToggle(node, toggled){
		if(this.state.cursor){
			var cursor = this.state.cursor;
			cursor.active = false;
			cursor.toggled = _.filter(cursor.children,{key:node.key}).length > 0 ? true : false;
			this.setState({cursor:cursor});
		}
        node.active = true;
        if(node.children){ node.toggled = toggled; }
        this.setState({ cursor: node });
    }
  render() {
    const { open, tipos, invalidate, nombre } = this.state

    return (
      <Modal
        open={open}
        onOpen={this.open}
        onClose={this.close}
        size='small'
        trigger={<Button>Nuevo</Button>}
      >
        <Modal.Header>Crear Equipamiento</Modal.Header>
        <Modal.Content>
					<Form error={invalidate}>
						<Form.Field control={Input} value={nombre} label='Nombre' name='nombre' onChange={this.handleChange} 	error={!nombre && invalidate} />
						<Form.Field label='Material' control={Treebeard} data={tipos} onToggle={this.onToggle} style={tree}/>
						<Message
							error
							header='Error en formulario'
							content='Complete todos los campos.'
						/>
					</Form>
        </Modal.Content>
        <Modal.Actions>
					<Button  content='Aceptar' onClick={this.createEquipamiento} />
					<Button  content='Cancelar' onClick={this.cancel} />
        </Modal.Actions>
      </Modal>
    )
   }
}


export {Avisos, NuevoAviso}
