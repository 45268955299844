import _ from 'lodash'
import React, { Component } from 'react';
import {Message, List, Confirm, Search, Input, Modal, Form, Dropdown, TextArea, Table, Accordion, Feed, Segment, Divider, Container, Header, Label, Item, Button, Menu, Grid, Popup} from 'semantic-ui-react';
import {IO} from './js/IO'
import {Link } from 'react-router-dom'
import {ListaArchivos,SubirArchivo} from './ListaArchivos'
import {oficios} from './js/constdata'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {Storage} from './js/storage';
import {Validations} from './js/validations'
import NumberFormat from 'react-number-format';
import { YCSFormFieldOficios } from './YCSComponents/YCSFormFieldOficios';

class DetalleIncidencia extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			incidencia : {},
			activeItem: 'detalles',
			archivos: [] ,
			activeIndex: 0,
			open_parte: false,
			pem: 0,
			pec: 0
		};
	};
	createParte = () =>{this.setState({ open_parte: true })}
	handleItemClick = (e, { name }) => this.setState({ activeItem: name })
	handleAccordion = (e, titleProps) => {
		const { index } = titleProps
		const { activeIndex } = this.state
		const newIndex = activeIndex === index ? -1 : index
		this.setState({ activeIndex: newIndex })
		}
	imprimirParte = () => {
		const location = "/utils/printParte.php?"+
							"id="+this.state.incidencia.id+"&"+
							"centro="+this.state.incidencia.centro+"&"+
							"subcentro="+this.state.incidencia.subcentro+"&"+
							"inspector="+this.state.incidencia.inspector+"&"+
							"creador="+this.state.incidencia.creador+"&"+
							"autor="+this.state.incidencia.autor+"&"+
							"prioridad="+this.state.incidencia.prioridad+"&"+
							"material="+this.state.incidencia.material+"&"+
							"fechaCreacion="+this.state.incidencia.fechaCreacion+"&"+
							"descripcion="+this.state.incidencia.descripcion+"&"+
							"direccion="+this.state.incidencia.direccion;
		const name = this.state.incidencia.id+'.pdf'
		IO.axios({url:location, responseType:'arraybuffer'})
		.then(function(response) {
				let blob = new Blob([response.data], { type:   'application/pdf' } )
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = name
				link.click()
			});	
		}
	getData = () => {		
		IO.axios.get('/imageserver/filePartes',{
				params: {incidencia: this.props.match.params['Id']}
			}).then(response => {
				 this.setState({ archivos: response.data.files, loading_files: false });
		 	});
		IO.axios.get('/data/partes.php/'+this.props.match.params['Id'] ).then(response => {
				this.setState({ 
					incidencia: response.data[0], 
					pem: _.reduce(_.map(_.map(response.data[0].operaciones, 'precios'),(a) => _.reduce(a, (x,y) => x+ parseFloat(y.total),0)), (a,b) => a+b, 0)
				});
				if (this.state.empresa){
					this.setState({ 
						pec: this.state.pem * (1-this.state.empresa.baja/100) * (1+this.state.empresa.gg/100+this.state.empresa.bi/100) * (1+this.state.empresa.igic/100)
					});
				}else{
					IO.axios.get('/data/empresa.php/'+response.data[0].empresa_id).then(response => {
						this.setState({ 
							pec: this.state.pem * (1-response.data[0].baja/100) * (1+response.data[0].gg/100+response.data[0].bi/100) * (1+response.data[0].igic/100),
							empresa: response.data[0]
						});
					});
				}
			});
		IO.axios.get('/data/partes_utils').then(response => {
					this.setState({ topfive: response.data });
			});
	 }
  
	componentDidMount() {this.getData();}
	handleCancel = (e, {name}) => this.setState({ [name]: false })
	handleOpen = (e, {name}) => this.setState({ [name]: true })
	handleDeleteParte = (e, {operacion}) => {
		this.setState({ open_confirm_delete_parte: false })
		var allFields = '{ "operacionId":'+operacion+' }';
		IO.axios({
			url:'/data/incidencia', 
			method: 'delete',
			data: allFields,
			withCredentials: true})
			.then(response => {this.getData();	});
		}
	handleDeletePrecio = (e, {name, parte, codigo, precio}) => {
		this.setState({ [name]: false })
		var allFields = '{ "parte":'+parte+', "codigo":"'+codigo+'", "precio":'+precio+'}';
		IO.axios({
			url:'/data/preciario', 
			method: 'delete',
			data: allFields})
			.then(response => { this.getData();	});
		}
	render() {
		const { incidencia, activeItem, archivos, activeIndex, loading_files, pem, pec } = this.state
		const user = Storage.getStorage('user')
		if(!incidencia) return '';
		if (this.props.match.params['Id'] !== incidencia.id){
			this.getData();
			return ''
		}
		return (
			<Container style={{ marginTop: '5em' }}>
				<Grid >
					<Grid.Row>
						<Grid.Column  width={6} textAlign='left'>
							<Header>
								<Label color={incidencia.prioridad==="URGENTE" ? 'red' : incidencia.prioridad==="PRIORITARIA" ? 'yellow' : 'grey'}>{incidencia.id}</Label>
								<Label  color='teal'> 
									<NumberFormat 
										value={Math.round(pem*100)/100} 
										displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>
								<Label  color='olive'> 
									<NumberFormat 
										value={Math.round(pec*100)/100} 
										displayType={'text'} thousandSeparator={' '}  prefix='PEC: '
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>	
							</Header>
						</Grid.Column>
						<Grid.Column width={10} textAlign='center'>
							{incidencia.navegacion && incidencia.navegacion.prev_total && incidencia.navegacion.prev_total!=="0" 
									? (<Button as={Link} to={incidencia.navegacion.prev_total} basic compact content="Previa Gral." icon="angle double left"/>)
									: (<Button disabled basic compact content="Previa Gral." icon="angle double left"/>) }
							{incidencia.navegacion && incidencia.navegacion.prev_centro && incidencia.navegacion.prev_centro!=="0"
									? (<Button as={Link} to={incidencia.navegacion.prev_centro} basic compact content="Previa Centro" icon="angle left"/>)
									: (<Button disabled basic compact content="Previa Centro" icon="angle left"/>) }
							{incidencia.navegacion && incidencia.navegacion.next_centro && incidencia.navegacion.next_centro!=="0"
									? (<Button as={Link} to={incidencia.navegacion.next_centro} basic compact content="Siguiente Centro" icon="angle double right"/>)
									: (<Button disabled basic compact content="Siguiente Centro" icon="angle right"/>) }
							{incidencia.navegacion && incidencia.navegacion.next_total && incidencia.navegacion.next_total!=="0"
									? (<Button as={Link} to={incidencia.navegacion.next_total} basic compact content="Siguiente Gral." icon="angle right"/>)
									: (<Button disabled basic compact content="Siguiente Gral." icon="angle double right"/>) }
						</Grid.Column>
					</Grid.Row>
				</Grid>
				
				<Divider horizontal />

				<Grid>
					<Grid.Row>
				  	<Grid.Column width={4}>
							<Menu pointing vertical>
								{activeItem==='detalles' ?
									<Menu.Item active={activeItem === 'detalles'}>
										<Menu.Header>Detalles Incidencia</Menu.Header>
										<Menu.Menu>
											<Menu.Item name='imprimir' onClick={this.imprimirParte} >Imprimir</Menu.Item>
											<EnviarEmail  incidencia={incidencia.id}/>
										</Menu.Menu>	
									</Menu.Item>
								:
									<Menu.Item name='detalles' active={activeItem === 'detalles'} onClick={this.handleItemClick}>
										Detalles Incidencia
									</Menu.Item>
								}
								{activeItem==='partes' ?
									<Menu.Item  active={activeItem === 'partes'}>
										<Menu.Header>Partes</Menu.Header>
										{user.roles.SERVICIO && <Menu.Menu>
											<CreateParteOperario incidencia={incidencia.id} updatePartes={this.getData}/>
										</Menu.Menu>	
										}
									</Menu.Item>
								:
									<Menu.Item name='partes' active={activeItem === 'partes'} onClick={this.handleItemClick} >
										Partes
										<Label>{incidencia.operaciones ? incidencia.operaciones.length : 0}</Label>
									</Menu.Item>
								}
								{activeItem==='archivos' ?
									<Menu.Item active={activeItem === 'archivos'}>
										<Menu.Header>Archivos</Menu.Header>
										<Menu.Menu>
											<SubirArchivo incidencia={incidencia.id} updateFiles={this.getData} files="filePartes"/>
										</Menu.Menu>	
									</Menu.Item>
								:
									<Menu.Item name='archivos' active={activeItem === 'archivos'} onClick={this.handleItemClick} >
										Archivos
										<Label>{archivos.length}</Label>
									</Menu.Item>
								}
							</Menu>
							<Divider />
							{user.roles.SERVICIO && (incidencia.estado==="NUEVA" || incidencia.estado==="REABIERTA")  &&
								<div>
									<CambioEstado icon='checkmark' color='orange' content='resolver' update={this.getData} incidencia={incidencia.id}/>
									<CambioEstado icon='remove' color='black' content='rechazar' update={this.getData} incidencia={incidencia.id}/>
								</div>
							}	
							{user.roles.SERVICIO && incidencia.estado==="RESUELTA"   &&
								<CambioEstado icon='eur' color='olive' content='valorar' update={this.getData} incidencia={incidencia.id}/>
							}	

							{user.roles.IMD && (incidencia.estado==="VALORADA" || incidencia.estado==="RECHAZADA") &&
								<div>
									<CambioEstado icon='checkmark box' color='green' content='reabrir' update={this.getData} incidencia={incidencia.id}/>
									<CambioEstado icon='window close outline' color='grey' content='cerrar' update={this.getData} incidencia={incidencia.id}/>
								</div>								
							}

							<Feed size='small'>
								{_.map(_.reverse(_.sortBy(incidencia.logs,['fecha'])), ({ fecha, estadoNuevo, estadoViejo, mensaje, creador },index) => (
								<Feed.Event key={index}>
									<Feed.Content>
										<Feed.Date content={fecha} />
										<Feed.User content={creador}/>
										<Feed.Summary> <div dangerouslySetInnerHTML={{ __html: mensaje }}></div></Feed.Summary>
									</Feed.Content>
									<Feed.Meta>
										<Label color={estadoNuevo==="CERRADA"  ? 'grey' : estadoNuevo==="RECHAZADA" ? 'black' : estadoNuevo==="RESUELTA" ? 'orange' : estadoNuevo==="VALORADA" ? 'olive' : 'green'} horizontal basic>{estadoNuevo}</Label>
									</Feed.Meta>
								</Feed.Event>
								))}
							</Feed>
					</Grid.Column>
					<Grid.Column width={12}>
							{ activeItem==='detalles' &&
							<Segment raised>
								<Label attached='bottom right' color='teal'> 
								<NumberFormat 
										value={Math.round(pem*100)/100} 
										displayType={'text'} thousandSeparator={' '}  
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>
								<Label color={incidencia.prioridad==="URGENTE" ? 'red' : incidencia.prioridad==="PRIORITARIA" ? 'yellow' : 'grey'} ribbon>{incidencia.prioridad}</Label>
								<span>
									<Label color={incidencia.estado==="CERRADA"  ? 'grey' : incidencia.estado==="RECHAZADA" ? 'black' : incidencia.estado==="RESUELTA" ? 'orange' : incidencia.estado==="VALORADA" ? 'olive' : 'green'} horizontal basic>{incidencia.estado}</Label>
								</span>
								<span> <Label icon='users' content={incidencia.empresa} /></span>
								<Divider horizontal />
								<Grid >
									<Grid.Row   columns={4}>
										<Grid.Column>
											<Header ><Header.Subheader>Id</Header.Subheader>{incidencia.id}	</Header>
										</Grid.Column>
										<Grid.Column>
											<Header><Header.Subheader>Código imd</Header.Subheader>{incidencia.idimd}	</Header>	
										</Grid.Column>
										<Grid.Column>
											<Header><Header.Subheader>Fecha Creación</Header.Subheader>{incidencia.fechaCreacion}	</Header>	
										</Grid.Column>
										<Grid.Column>
											<Header><Header.Subheader>Fecha Cierre</Header.Subheader>{incidencia.fechaCierre}	</Header>	
										</Grid.Column>
									</Grid.Row>
									<Grid.Row columns={1}>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Descripción</Header.Subheader><div dangerouslySetInnerHTML={{ __html: incidencia.descripcion }} />	</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row   columns={2}>
										<Grid.Column>
											<Header size='small' as={Link} to={'/instalaciones/'+incidencia.centro_id}><Header.Subheader>Centro</Header.Subheader>{incidencia.centro} {incidencia.subcentro && "("+incidencia.subcentro+")"}	</Header>
										</Grid.Column>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Autor</Header.Subheader>{incidencia.autor}	</Header>	
										</Grid.Column>
									</Grid.Row>
									<Grid.Row   columns={2}>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Dirección</Header.Subheader>{incidencia.direccion}	</Header>
										</Grid.Column>
										<Grid.Column>
											<Header size='small' as={Link} to={'/material/'+incidencia.material_id}><Header.Subheader>Material</Header.Subheader>{incidencia.material}	</Header>	
										</Grid.Column>
									</Grid.Row>
									<Grid.Row   columns={2}>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Creador</Header.Subheader>{incidencia.creador}	</Header>
										</Grid.Column>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Inspector</Header.Subheader>{incidencia.inspector}	</Header>	
										</Grid.Column>
									</Grid.Row>
								</Grid>
								
							</Segment>
							}
							{ activeItem==='partes' &&
									
									 _.map(incidencia.operaciones,(operacion) => 
										<Segment raised  key={operacion.id}>
							 			<Accordion fluid>
											<Accordion.Title key={operacion.id} active={activeIndex === parseInt(operacion.id,10)} index={parseInt(operacion.id,10)} onClick={this.handleAccordion}>
												<Item>
													<Item.Content>
														<Item.Header> 

															<List horizontal>
															
																<List.Item><Label icon='dropdown'  color='black' content={incidencia.id+'.'+operacion.codigo} /></List.Item>
																<List.Item>
																	<Label basic color='teal' style={{width:'100px', textAlign:'right'}}> 
																	<NumberFormat 
																		value={Math.round(_.reduce(_.map(operacion.precios,'total'),(a,b) => parseFloat(a) + parseFloat(b), 0)*100)/100}
																		displayType={'text'} thousandSeparator={' '}  
																		decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
																	</Label>
																</List.Item>
																{operacion.numero &&<List.Item><Label basic color='black'>{operacion.estado}<Label.Detail>{operacion.numero}</Label.Detail></Label></List.Item>}
																<List.Item><Label basic icon='calendar' content={operacion.fecha} /></List.Item>
																{operacion.oficio && <List.Item><Label basic icon='info' content={operacion.oficio}/></List.Item>}
																{operacion.ejecutor && <List.Item><Label basic icon='user circle' content={operacion.ejecutor} /></List.Item>}
																
																<List.Item>
																	<Popup trigger={<Label basic icon='user circle' content={_.reduce(_.map(operacion.operarios,'nombre'),(a,b) => a +(a.length>0?", ":"")+b.substring(0,b.indexOf(" ")),"")}/>}
																	 content={_.reduce(_.map(operacion.operarios,'nombre'),(a,b) => a +(a.length>0?", ":"")+b,"")}/>
																</List.Item>

															</List>
															
															
														</Item.Header>
														{operacion.descripcion && <Item.Description><div dangerouslySetInnerHTML={{ __html: operacion.descripcion }} /></Item.Description>}
													</Item.Content>
												</Item>
												
											</Accordion.Title> 
											<Accordion.Content active={activeIndex === parseInt(operacion.id,10)}>
												<Confirm open={this.state['open_confirm_delete_parte_'+operacion.id]}
													name={'open_confirm_delete_parte_'+operacion.id}
													content='¿Seguro que desa eliminar el parte?'
													onCancel={this.handleCancel} operacion={operacion.id}
													onConfirm={this.handleDeleteParte}
												/>
												{user.roles.SERVICIO && (!operacion.numero || operacion.estado==='BORRADOR' ) &&
													<Grid>
														<Grid.Column width={4}>
															<Popup trigger={<span><NuevoPrecio size='mini' parte={operacion.id} updateParte={this.getData}/></span>}
																content='Nuevo elemento'/>
															<Popup trigger={<span><Button icon='delete' size='mini' onClick={this.handleOpen} name={'open_confirm_delete_parte_'+operacion.id} disabled={operacion.precios.length>0}/></span>}
																content='Eliminar parte'/>
															<Popup trigger={<span><EditFecha size='mini' incidencia={incidencia.id} parte={operacion.id} updateParte={this.getData}/></span>}
																content='Editar fecha' style={{marginRight:'20px'}}/>
														</Grid.Column>
														<Grid.Column width={12} textAlign='right'>
															{ this.state.topfive &&
																_.map(this.state.topfive,(precio) => 
																	<Popup key={'nuevo_'+operacion.id+'_'+precio.id} trigger={<span><NuevoPrecio precio={precio} parte={operacion.id} updateParte={this.getData}/></span>}
																	content={precio.resumen}/>
																)
															}
														</Grid.Column>
													</Grid>
												}
												<Table striped>
													<Table.Header>
														<Table.Row>
															{user.roles.SERVICIO && (!operacion.numero || operacion.estado==='BORRADOR' ) &&
															<Table.HeaderCell></Table.HeaderCell>
															}
															<Table.HeaderCell>Codigo</Table.HeaderCell>
															<Table.HeaderCell>Resumen</Table.HeaderCell>
															<Table.HeaderCell>Precio</Table.HeaderCell>
															<Table.HeaderCell>Unidad</Table.HeaderCell>
															<Table.HeaderCell>Cantidad</Table.HeaderCell>
															<Table.HeaderCell>Subtotal</Table.HeaderCell>
														</Table.Row>
													</Table.Header>										
													<Table.Body>
														{_.map(operacion.precios,(precio) =>
															<Table.Row key={precio.codigo}>	
																{user.roles.SERVICIO && (!operacion.numero || operacion.estado==='BORRADOR') &&
																<Confirm open={this.state['open_confirm_delete_precio_'+operacion.id+'_'+precio.codigo]}
																	name={'open_confirm_delete_precio_'+operacion.id+'_'+precio.codigo}
																	content='¿Seguro que desa eliminar el artículo?'
																	onCancel={this.handleCancel} codigo={precio.codigo} precio={precio.id} parte={operacion.id}
																	onConfirm={this.handleDeletePrecio}
																/>
																}
																{user.roles.SERVICIO && (!operacion.numero || operacion.estado==='BORRADOR') &&
																<Table.Cell>
																	<Button.Group size='mini'>
																		<Button icon='delete' onClick={this.handleOpen} name={'open_confirm_delete_precio_'+operacion.id+'_'+precio.codigo}/>
																		<EditPrecio parte={operacion.id} precio={precio} updateParte={this.getData} disabled={precio.unidad==='%'}/>
																	</Button.Group>
																</Table.Cell>
																}
																<Table.Cell>{precio.codigo}</Table.Cell>
																<Table.Cell>{precio.resumen}</Table.Cell>
																<Table.Cell textAlign='right'>{Math.round(precio.precio*100)/100} {precio.unidad!=='%' && '€'}</Table.Cell>
																<Table.Cell>{precio.unidad}</Table.Cell>
																<Table.Cell textAlign='right'>{precio.unidad!=='%' && precio.cantidad}</Table.Cell>
																<Table.Cell textAlign='right'>
																	<NumberFormat 
																		value={Math.round(precio.total*100)/100}
																		displayType={'text'} thousandSeparator={' '}  
																		decimalScale={2} fixedDecimalScale={true} decimalseparator="," />																
																</Table.Cell>
															</Table.Row>
															)}
													</Table.Body>
												</Table>
										</Accordion.Content>								
									</Accordion>
									</Segment>
									 )				
									
							}
							{ activeItem==='archivos' && 
								<ListaArchivos archivos={archivos} files="filePartes"  updateFiles={this.getData} loading={loading_files}/>
							}
				  	</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		);
  }
}

class CreateParteOperario extends Component {
	constructor(props) {
		  super(props);
		  this.state = {
			  open: this.props.open,
			  operarios: [],
			  fecha: moment(),
			  oficio: '',
			  descripcion: '',
			  otros: '',
			  invalidate : null
		  };
		  this.opciones = Storage.getStorage('operarios');
		  this.opciones.push({key:'-1', text:'Otros ...', value:'-1'}); 
	}	  
	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value },() =>{
			if (this.state.operarios.length > 0 && this.state.fecha && this.state.oficio.length > 0) {
				this.setState({invalidate:false});
			}
		});
	 }
	handleDateChange = (date)  => {this.setState({fecha: date});}	
	resetComponent = () => this.setState({
		operarios: [], fecha: moment(), oficio: '', descripcion: '', otros: '', invalidate : null
	  })
	createParte = () => {
		if (this.state.invalidate === false) {
			var allFields = '{ "id":"'+this.props.incidencia+
					'", "operario":"' +this.state.operarios+
					'", "ejecutor":"' +this.state.otros.replace(/\r?\n/g, '<br/>' )+
					'", "oficio":"'+this.state.oficio+
					'", "date":"'+ this.state.fecha.format("YYYY-MM-DD")+
					'", "descripcion":"'+this.state.descripcion.replace(/\r?\n/g, '<br/>' ) +'" }';
			IO.axios({
				url:'/data/incidencia', 
				method: 'post',
				data: allFields})
				.then(response => {
						this.setState({ open: false });
						this.props.updatePartes();	
						this.resetComponent()
			});
		}else{
			this.setState({invalidate:true});
		}
	 }
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	cancel = () => {
		this.resetComponent();
		this.close();
	 }
	render() {
	  const { open, invalidate, operarios, fecha, oficio, descripcion} = this.state
	  return (
		<Modal
		  open={open}
		  onOpen={this.open}
		  onClose={this.close}
		  size='small'
		  trigger={<Menu.Item> Nuevo parte</Menu.Item>}
		>
		  <Modal.Header>Crear Parte de operario</Modal.Header>
		  <Modal.Content>
				<Form  error={invalidate}>
					<Form.Field control={Dropdown} label='Operarios' name='operarios' onChange={this.handleChange} 	
								error={operarios.length === 0 && invalidate}  fluid multiple selection 
								placeholder='Seleccione' options={this.opciones} value={operarios}/> 
					{ _.indexOf(this.state.operarios,"-1")!==-1 && <Form.Field control={Input} placeholder='Describa quién ejecutó' name='otros' onChange={this.handleChange}/>}
					
					<Form.Group widths='equal'>
						<Form.Field control={DatePicker} selected={fecha} label='Fecha' dateFormat="DD/MM/YYYY"
									onChange={this.handleDateChange} placeholder='Fecha' name='fecha' error={!fecha && invalidate}/>
						<YCSFormFieldOficios label='Oficio' value={oficio} onChange={this.handleChange} error={oficio.length === 0 && invalidate}/>
					</Form.Group>							 
					<Form.Field control={TextArea} label='Descripción' name='descripcion' onChange={this.handleChange} value={descripcion}/>	

					<Message
							error
							header='Error en formulario'
							content='Complete los campos obligatorios (en rojo).'
						/>					
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.createParte} />
					  <Button  content='Cancelar' onClick={this.cancel} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}

class EnviarEmail extends Component {
	constructor(props) {
		  super(props);
		  this.state = {
			  open: this.props.open,
			  email: null
		  };
	};	  
	handleChange = (e, { name, value }) => {this.setState({ [name]: value });}
	enviar = () => {
		if (this.state.email){
			var allFields = '{ "id":"'+this.props.incidencia+'", "email":"' +this.state.email+'"}';
			IO.axios({
				url:'/data/sendparte', 
				method: 'post', data: allFields});
			this.setState({ open: false });
		}
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	render() {
	  const { open } = this.state
	  return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
		  size='small' 
		  trigger={<Menu.Item>Enviar por email</Menu.Item>}
		>
		  <Modal.Header>Enviar email</Modal.Header>
		  <Modal.Content>
				<Form>
					<Form.Field control={Input} placeholder='Escriba el email' name='email' onChange={this.handleChange} value={this.state.email} />
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.enviar} />
					  <Button  content='Cancelar' onClick={this.close} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}

class NuevoPrecio extends Component {
	constructor(props) {
		  super(props);

		  var articulo = this.props.precio ? this.props.precio : {};
		  var invalidate = null
		  if(this.props.precio){
				articulo.title='['+articulo.codigo+'] '+articulo.resumen;
				articulo.key= articulo.id;
				articulo.description= articulo.precio+' € / '+articulo.unidad;
				invalidate = articulo.unidad==="%"? false : null;
		  }
		   

		  this.state = {
			articulo: articulo,
			cantidad: '',
			invalidate : invalidate
		  };
	};	  
	cacheParte = {};
	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value }, () => {
		if (this.state.articulo.key && (Validations.isNumber(this.state.cantidad) || this.state.articulo.unidad==="%")) {
			this.setState({invalidate:false});
		}});
	}
	resetComponent = () => this.setState({isLoading: false, results: [], articulo: {},cantidad: '',invalidate : null})
	enviar = () => {
		if(this.state.invalidate === false){
			var allFields = '{ "parte":"'+this.props.parte+
							'", "codigo":"' +this.state.articulo.codigo+
							'", "precio":"' +this.state.articulo.key+							
							'", "cantidad":'+(this.state.articulo.unidad!=="%"?this.state.cantidad:"0")+'}';
			IO.axios({
				url:'/data/preciario', 
				method: 'post',
				data: allFields})
				.then(response => {
						this.setState({ open: false });
						this.props.updateParte();	
						if(!this.props.precio)
							this.resetComponent();
			});
		}else{ this.setState({invalidate: true})}
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	cancel = () => {
		if(!this.props.precio)
			this.resetComponent();
		this.close();
	}
	handleResultSelect = (e, { result }) => { 
		this.setState({ articulo: result, invalidate: (result.unidad==="%")?false:null});
	}
	handleSearchChange = (e, { value }) => {
		this.setState({ isLoading: true, articulo: {} })
		if (value.length < 3) return this.resetComponent();
		if ( value in this.cacheParte ) {
			this.setState({ isLoading: false, results: this.cacheParte[ value ]});
		}else {
			IO.axios.get('/data/search_precio.php', {
				params: {
					term: value,
					precio: true
				}
			}).then(response => {
				var precios = _.map(response.data,(precio) => {return {	title:'['+precio.codigo+'] '+precio.resumen,
																		key:precio.id, unidad:precio.unidad, codigo:precio.codigo,
																		description: precio.precio+' € / '+precio.unidad }})
				this.setState({ isLoading: false, results: precios});
				this.cacheParte[ value ] = precios;
			});
		}
	}
	
	render() {
	  const { open, articulo, isLoading, results, invalidate, cantidad } = this.state	
	  return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
		  size='small'  dimmer={false} className = 'scrolling'
		  trigger={this.props.precio ?<Button size='mini' content={this.props.precio.codigo} />
		  							 :<Button size='mini' icon='plus square outline' />}
		>
		  <Modal.Header>Nuevo precio</Modal.Header>
		  <Modal.Content>
				<Form error={invalidate}>
					<Form.Field
						control={Search}
						label='Artículo'
						loading={isLoading}
						onResultSelect={this.handleResultSelect}
						onSearchChange={this.handleSearchChange}
						results={results}
						value={articulo.title}
						scrolling
						noResultsMessage='No hay resultados'
						input={{ fluid: true }}
						error = {!articulo.key && invalidate}
					/>
					<Form.Input name='cantidad' label='Cantidad' onChange={this.handleChange} 
								disabled={articulo.unidad==="%"} value={cantidad} input={{autoComplete:"off"}} 								
								error={(!cantidad || !Validations.isNumber(cantidad)) && articulo.unidad!=="%" && invalidate}/>
					<Message
							error
							header='Error en formulario'
							content='Complete los campos obligatorios (en rojo).'
						/>
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.enviar} />
					  <Button  content='Cancelar' onClick={this.cancel} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}

class EditFecha extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  fecha: moment()
		};
  };	  	
  handleDateChange = (date)  => {this.setState({fecha: date});}	
  enviar = () => {
	if (this.state.fecha) {
	  var allFields = '{ "id":"'+this.props.incidencia+'","operacionId":"'+this.props.parte+
	  '", "fechaOperacion":"' +this.state.fecha.format("YYYY-MM-DD")+'" }'
	  
	  IO.axios({
		  url:'/data/incidencia.php/fecha', 
		  method: 'put',
		  data: allFields})
		  .then(response => {
				  	  this.setState({ open: false });
					  this.props.updateParte();	
					  this.resetComponent();
	  });
	}else{
		this.setState({invalidate:true});
	}
  }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  resetComponent = () => this.setState({ fecha: moment() })	
  
  render() {
	const { open, fecha } = this.state
	return (
	  <Modal open={open}  onOpen={this.open}  onClose={this.close}
		size='small' 
		trigger={<Button size='mini' icon='calendar' />}
	  >
		<Modal.Header>Modificar fecha</Modal.Header>
		<Modal.Content>
			  <Form  error={!fecha}>
				  <Form.Field control={DatePicker} selected={this.state.fecha} label='Fecha'
								  onChange={this.handleDateChange} placeholder='Fecha' name='fecha'
								  error={!fecha} dateFormat="DD/MM/YYYY"/>
								  <Message
							error
							header='Error en formulario'
							content='Debe especificar una fecha'
						/>
			  </Form>
		</Modal.Content>
		<Modal.Actions>
					<Button  content='Aceptar' onClick={this.enviar} />
					<Button  content='Cancelar' onClick={this.close} />
		</Modal.Actions>
	  </Modal>
	)
  }
}

class EditPrecio extends Component {
	constructor(props) {
		  super(props);
		  this.state = {
			cantidad: this.props.precio.cantidad
		  };
	};	  	
	componentWillReceiveProps(next){
		if(!next.precio || next.precio === this.precio) return	
		this.setState({cantidad: next.precio.cantidad});
	 }
	handleChange = (e, { name, value }) => {this.setState({ [name]: value });}
	enviar = () => {
		if (Validations.isNumber(this.state.cantidad)) {
			// console.log(this.props.precio);			
			var allFields = '{ "parte":"'+this.props.parte +
							'", "precio":"' + this.props.precio.id +
							'", "codigo":"' + this.props.precio.codigo +				
							'", "cantidad":'+ this.state.cantidad+'}';
			IO.axios({
				url:'/data/preciario', 
				method: 'put',
				data: allFields})
				.then(response => {
					this.setState({ open: false, cantidad: this.props.precio.cantidad });
					this.props.updateParte();	
			});
		}
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })

	render() {
	  const { open, cantidad  } = this.state
	  return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
		  size='small' 
		  trigger={<Button icon='edit' disabled={this.props.disabled} />}
		>
		  <Modal.Header>Modificar cantidad</Modal.Header>
		  <Modal.Content>
				<Form  error={!Validations.isNumber(cantidad)}>
					<Form.Input name='cantidad' label='Cantidad' onChange={this.handleChange} value={cantidad} 
					input={{autoComplete:"off"}} 	error={!Validations.isNumber(cantidad)}/>
					<Message
							error
							header='Error en formulario'
							content='Debe introducir una cantidad correcta.'
						/>
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.enviar} />
					  <Button  content='Cancelar' onClick={this.close} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}
  
class CambioEstado extends Component {
	constructor(props) {
			super(props);
			this.state = {
			mensaje: ''
			};
	};	  	
	handleChange = (e, {name,value})  => {this.setState({[name]: value});}	
	enviar = () => {
		var data = '{ "id":"'+this.props.incidencia+'", "mensaje":"' +this.state.mensaje.replace(/\r?\n/g, '<br/>' )+'" }';
		var url = '/data/incidencia.php/'+this.props.content;
		IO.axios({
			url: url, 
			method: 'put',
			data: data})
			.then(response => {
				this.setState({ open: false });
				this.props.update();	
				this.resetComponent();
		});
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	resetComponent = () => this.setState({ mensaje: {} })	
	
	render() {
		const { open } = this.state
		return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
			size='small' 
			trigger={<Button basic icon={this.props.icon} content={this.props.void ? "" : this.props.content} size='mini'
							 color={this.props.color} floated={this.props.floated}
							 onClick={(e) => e.preventDefault()}/>}
		>
			<Modal.Header>{this.props.content} {this.props.incidencia}</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Field control={TextArea} label='Mensaje' onChange={this.handleChange} name='mensaje'/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
						<Button  content='Aceptar' onClick={this.enviar} />
						<Button  content='Cancelar' onClick={this.close} />
			</Modal.Actions>
		</Modal>
		)
	}
  }
  
  export {DetalleIncidencia, CambioEstado};