import React from "react";
import styled from "styled-components";
import {Message, Checkbox, TextArea, Form, Header, Modal, Button, Menu, Dropdown, Input, Grid, Popup,
	Container} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

export class YCSSelectDate extends React.Component{

    render(){
        const {filters_date} = this.props;

        return (
            <MyContainer>
                <ContainerFiltersDate size={filters_date.filter_date_selected}>
                    <ColumnLeft>
                        <FilterSelectedYear className="item-filter-date" name="year" select={filters_date.filter_date_selected} onClick={() => this.props.handleOnChangeFilterDate("filter_date_selected", "year")}>Año</FilterSelectedYear>
                        <FilterSelectedDate className="item-filter-date" name="date" select={filters_date.filter_date_selected} onClick={() => this.props.handleOnChangeFilterDate("filter_date_selected", "date")}>Fecha</FilterSelectedDate>
                        <FilterSelectedRange className="item-filter-date" name="range" select={filters_date.filter_date_selected} onClick={() => this.props.handleOnChangeFilterDate("filter_date_selected", "range")}>Rango</FilterSelectedRange>
                    </ColumnLeft>
                    <ColumnRight>
                        {{
                            "year": 
                                <ContainerItem>
                                    <Input size="small" style={{ width: "100%" }} name="year" value={filters_date.year} onChange={ (e, {value}) => this.props.handleOnChangeFilterDate("year", value)}/>
                                </ContainerItem>,
                            "date":
                                <ContainerItem className="identificator-datepicker-select-date">
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        selected={filters_date.fecha}
                                        onChange={(event) => this.props.handleOnChangeFilterDate("fecha", event)}
                                        customInput={ <Input/> }
                                        isClearable
                                    />
                                </ContainerItem>,
                            "range":
                                <ContainerRange>
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        selectsStart
                                        selected={filters_date.fecha_inicio}
                                        startDate={filters_date.fecha_inicio}
                                        endDate={filters_date.fecha_final}
                                        onChange={(event) => this.props.handleOnChangeFilterDate("fecha_inicio", event)}
                                        customInput={ <Input/> }
                                        isClearable
                                        />
                                    <DatePicker
                                        dateFormat="DD/MM/YYYY"
                                        selectsEnd
                                        selected={filters_date.fecha_final}
                                        startDate={filters_date.fecha_inicio}
                                        endDate={filters_date.fecha_final}
                                        onChange={(event) => this.props.handleOnChangeFilterDate("fecha_final", event)}
                                        customInput={ <Input/> }
                                        isClearable
                                    />
                                </ContainerRange>
                        }[filters_date.filter_date_selected]}
                    </ColumnRight>
                </ContainerFiltersDate>
                <ContianerButtonsFilters>
                    <Button size="small" primary onClick={this.props.applyFiltersDate}>Aplicar</Button>
                    <Button size="small" onClick={this.props.resetFiltersDate}>Cancelar</Button>
                </ContianerButtonsFilters>

                <Diamond>
                </Diamond>
            </MyContainer>
        );
    }
}

YCSSelectDate.propTypes = {
    filters_date: PropTypes.object,                 // Values of filters
    handleOnChangeFilterDate: PropTypes.func,       // Function onChange to handle all components
    applyFiltersDate: PropTypes.func,               // Handle onClick button Aplicar
    resetFiltersDate: PropTypes.func,
}
YCSSelectDate.defaultProps = {
    filters_date: {},
    handleOnChangeFilterDate: () => {},
    applyFiltersDate: () => {},
    resetFiltersDate: () => {},
}

const MyContainer = styled.div`
    background-color: #FFF;
    position: relative;
`;
const Diamond = styled.div`
    background-color: #FFF;
    bottom: -10px;
	box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
    height: 20px;
    left: 30px;
    position: absolute;
    transform: rotate(45deg);
    width: 20px;
    z-index: -1;
`;
const ContainerFiltersDate = styled.div`
    display: grid;
    grid-template-columns: ${props => props.size !== "range"? "80px 265px" : "80px 350px"};
    grid-template-rows: auto auto;
    border-bottom: 1px solid #D9D9D9;
`;
const ColumnLeft = styled.div`
    border-right: 1px solid #D9D9D9;
`;
const ColumnRight = styled.div`
    
`;
const ContainerItem = styled.div`
    margin: 10px;
`;
const ContainerRange = styled(ContainerItem)`
    display: flex;
    gap: 0 10px;
    justify-content: center;
`;
const FilterSelectedYear = styled.button`
	border-left: ${props => 
    	props.select==="year"? "4px solid #7E8183" :""
	};
`;
const FilterSelectedDate = styled.button`
	border-left: ${props => 
    	props.select==="date"? "4px solid #7E8183" :""
	};
`;
const FilterSelectedRange = styled.button`
	border-left: ${props => 
    	props.select==="range"? "4px solid #7E8183" :""
 	};
`;
const ContianerButtonsFilters = styled.div`
    box-sizing: border-box;
	display: flex;
    gap: 0 10px;
	justify-content: flex-end;
    padding: 10px 0;
`;