import _ from 'lodash';
import React, { Component } from 'react';
import {Table,Grid, Header, Segment, Label, List, Image} from 'semantic-ui-react';
import {IO} from './js/IO';
import {Storage} from './js/storage';
import {baseUrl} from './js/constdata'; 

class Empresas extends Component {	
	constructor(props) {
		super(props);
		this.state = { 
			activeRow: null,
			empresas: [], 
			empresa: {},
			user: Storage.getStorage('user'),
		};
	}
	handleRowClick = (k) => {
		this.setState({ activeRow: k });
		IO.axios.get('/data/empresa.php/'+k).then(response => {this.setState({ empresa : response.data[0]});});
	}
	componentDidMount(){
		IO.axios.get('/data/empresa').then(response => {this.setState({ empresas : response.data});});
	}

	render() {
		const { empresas, empresa, activeRow } = this.state;
		return (
				<Grid container style={{ marginTop: '3em' }}>
					<Grid.Row>
						<Grid.Column width={5}>
							<Table selectable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell colSpan='2'>Empresas</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(empresas, ({ id, nombre, servicio, servicio_desc }) => (
										(id !== "0") &&
											<Table.Row key={id} active={id === activeRow } onClick={ () => {this.handleRowClick(id)}} >
												<Table.Cell>{servicio+"."+servicio_desc}</Table.Cell>
												<Table.Cell>{nombre}</Table.Cell>
											</Table.Row>
										
									))}
								</Table.Body>
							</Table>
						</Grid.Column>
						<Grid.Column width={11}>
							{
								activeRow && 
							(<div>
								<Image src={baseUrl+'/images/'+empresa.logo} size='small' /> 
								<Segment raised>
								<Label ribbon>{empresa.nombre}</Label>								
								<Grid >
									<Grid.Row>
										<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Servicio</Header.Subheader>{empresa.servicio}</Grid.Column>
										<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Descripción servicio</Header.Subheader>{empresa.servicio_desc}</Grid.Column>
										<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Dirección</Header.Subheader>{empresa.direccion}</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Certificación</Header.Subheader>{empresa.certificacion>0 ?empresa.certificacion : ""}</Grid.Column>
										<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Baja</Header.Subheader>{empresa.baja>0 ? empresa.baja : ""}</Grid.Column>
										<Grid.Column width={5} as={Header} size='small'><Header.Subheader>CIF</Header.Subheader>{empresa.cif}</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Estado</Header.Subheader>{empresa.activa==="1" ? "Activa" : "Desactivada"}</Grid.Column>
										<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Incidencias</Header.Subheader>{empresa.incidencias==="1" ? "Activa" : "Desactivada"}</Grid.Column>
									</Grid.Row>
								</Grid>								
							</Segment>
							{empresa.id === this.state.user.empresa && <Segment raised>
								<Label attached='top left'>Usuarios</Label>								
								<List >
								{_.map(empresa.usuarios, ({ id, nombre, apellidos }) => (
									<List.Item key={id}>
										<List.Icon name='user' />
										<List.Content>{nombre + ' ' + apellidos}</List.Content>
									</List.Item>
								))}
								</List>								
							</Segment>}
							</div>)
						}
						</Grid.Column>
					</Grid.Row>
				</Grid>
		);
  }
}

export default Empresas;