import _ from 'lodash'
import React, { Component } from 'react';
import {Message, Checkbox, TextArea, Form, Modal, Button, Menu, Dropdown, Input, Grid, Popup} from 'semantic-ui-react';
import {Storage} from './js/storage';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ListaPedidos from './ListaPedidos'
import {prioridades, estado_pedidos} from './js/constdata';
import {IO} from './js/IO';
import styled from 'styled-components';
import { YCSSelectDate } from './YCSComponents/YCSSelectDate';
import { filterQuery, getQueries, objToQueryString } from './js/utils';

class Pedidos extends Component {
	user = Storage.getStorage('user');
	tableData = [];
	offset = 0;
	filters_date = {
		filter_date_selected: "year",		// It can be "year" | "date" | "range"
		year: "",
		fecha: null,
		fecha_inicio: null,
		fecha_final: null,
		openFiltersDate: false
	}

	filtersDatename = {
		year: "Año",
		date: "Fecha",
		range: "Rango"
	}

	constructor(props) {
		super(props);
		this.state = {
			data: this.tableData,
			prioridad: this.updateFiltro('prioridad',true),
			estado: this.assignFiltros(),
			centro: this.updateFiltro('centro',false),
			empresa: this.assignEmpresa(),
			text:  this.updateFiltro('text',false,""),
			ejecucion: false,
			filters_date: this.filters_date
		};
		this.updateList = this.updateList.bind(this);
		this.paginate = this.paginate.bind(this);
	};
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresa: (next.empresageneral!==-1?next.empresageneral:null)}, () => this.getData());
	}
	borrarFiltros = () => {
		this.props.history.replace({...this.props.history.location, search: ""})
		this.setState({ filters_date: this.filters_date, prioridad:[],centro:null,estado:this.assignFiltros(),empresa:this.assignEmpresa(),text:"",
			ejecucion: false
		}, () => this.getData());
		Storage.removeStorage("filtersOfIncidents");
	 } 
	assignEmpresa = () => {
		if(this.props.empresageneral && this.props.empresageneral!==-1){
			return this.props.empresageneral;
		}
		return this.updateFiltro('empresa',false);
	 }
	assignFiltros = () => {
		const query = new URLSearchParams(this.props.history.location.search);
		var estado = query.get('estado');
		if (!estado || estado===""){
			var user = this.user;
			var estados = _.concat(
					user.ferreteria ? ['nuevo'] : 
					user.administracion ? ['confirmado','rechazado'] : []
				)
			return estados;
		}else{
			return  estado.split(',');
		}		
	 }
	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value }, () => this.getData());
		this.updateFiltersInSessionStorage(name, value);
		this.updateURLFiltros(name,value);
	 }
	handleEjecucion = (e, { name, checked }) => {
		this.setState({ [name]: checked }, this.getData);
		this.updateURLFiltros(name,checked);
		this.updateFiltersInSessionStorage(name, checked);
	}
	updateFiltro = (name,array,defaul) =>{
		const query = new URLSearchParams(this.props.history.location.search);
		var filtro = query.get(name);
		if (!filtro || filtro===""){
			return defaul == null ? (array ? [] : null) :  defaul ;
		}else{
			return  array ? filtro.split(',') : filtro;
		}
	 } 
	updateURLFiltros = (name,value) =>{
		const query = new URLSearchParams(this.props.history.location.search);
		query.set(name, value);
		this.props.history.replace({...this.props.history.location, search: query.toString()})
	 }
	handleFilter = (e, { value }) => {
		this.updateURLFiltros('text',value);
		this.updateFiltersInSessionStorage("text", value);
		this.setState({ text: value }, () => this.getData());
	}

	/**
	 * Update filters in sessionStorage one by one
	 * @param {string} property 
	 * @param {string} value 
	 */
	updateFiltersInSessionStorage = (property, value) => {
		let filters = {}
		// Get sessionStorage
		const filtersInSessionStorage = Storage.getStorage("filtersOfIncidents");
		if(filtersInSessionStorage){
			filters = {
				...filtersInSessionStorage,
				[property]: value
			}
		}else{
			filters[property] =  value;
		}
		Storage.setStorage("filtersOfIncidents", filters);
	}

	/**
	 * Update all filters on sessionStorage
	 */
	updateAllFilters = () => {
		const {text, prioridad, estado, centro, empresa, filters_date} = this.state;
		let queryFilter = {
			text,
			prioridad,
			estado,
			centro,
			empresa,
			filters_date
		}
		Storage.setStorage("filtersOfIncidents", queryFilter);
	}

	/**
	 * Get filters date of query of FE, save in state of component
	 * and update all filters on sessionStorage
	 * @returns {Object} object with two parameters:
	 * {
	 * 	filter_date_selected: "year" | "date" | "range"
	 * 	year or fecha or (fecha_inicio && fecha_final)
	 * }
	 */
	getFiltersDateURl = () => {
		// Get queryParams of URL of FE, this is a peculiar case!
		const aux = new URLSearchParams(this.props.history.location.search);
		// Save all filters on sessionStorage
		this.updateAllFilters();

		let queryFiltersDate = {}
		if(aux.has("tipo-fecha")){
			const filter_date_selected = aux.get("tipo-fecha");
			switch (filter_date_selected) {
				case "year":
					queryFiltersDate.year = aux.get("year");
					queryFiltersDate.filter_date_selected = filter_date_selected;
				break;
				case "date":
					queryFiltersDate.fecha = moment(aux.get("fecha"));
					queryFiltersDate.filter_date_selected = filter_date_selected;
					break;
				case "range":
					queryFiltersDate.fecha_inicio = moment(aux.get("fecha_inicio"));
					queryFiltersDate.fecha_final = moment(aux.get("fecha_final"));
					queryFiltersDate.filter_date_selected = filter_date_selected;
				break;
				default:
					break;
			}
		}
		return queryFiltersDate;
	}

	/**
	 * @params {Object} query
	 */
	updateUrlWithQueryParams = (query = {}) => {
		const newQuery = filterQuery(query, ["", null, []]);
		let aux1 = {}, aux2 = {}
		if(newQuery.filters_date){
			switch (newQuery.filters_date.filter_date_selected) {
				case "year":
					aux2.year = newQuery.filters_date.year;
					break;
				case "date":
					aux2.fecha = newQuery.filters_date.fecha;
					break;
				case "range":
					aux2.fecha_inicio = newQuery.filters_date.fecha_inicio;
					aux2.fecha_final = newQuery.filters_date.fecha_final;
					break;
				default:
					break;
			}
		}
		aux1 = {
			...newQuery,
			...aux2
		}
		delete aux1.filters_date;
		let queryParamToString = objToQueryString(aux1) + "&tipo-fecha="+newQuery.filters_date.filter_date_selected;
		this.props.history.replace({ search: queryParamToString})
	}

	updateList = (nuevo) => { 
		this.getData();
	 }
	paginate = () => this.getData(true);

	getData = (paginate) => {	
		// Handle filters date
		const queryFiltersDate = {}
		const {filter_date_selected, year, fecha, fecha_inicio, fecha_final} = this.state.filters_date;
		switch (filter_date_selected) {
			case "year":
				queryFiltersDate.year = year;
				break;
			case "date":
				queryFiltersDate.fecha = fecha?moment(fecha).format("YYYY-MM-DD"): null;
				break;
			case "range":
				queryFiltersDate.fecha_inicio = fecha_inicio?moment(fecha_inicio).format("YYYY-MM-DD"): null;
				queryFiltersDate.fecha_final = fecha_final?moment(fecha_final).format("YYYY-MM-DD"): null;
				break;
			default:
				break;
		}
		// Clean queryFiltersDate
		const newQueryFiltersDate = filterQuery(queryFiltersDate, ["", null]);
		if(!this.state.loading){
			if(paginate){
				if(this.offset===-1) return;
				this.offset = this.offset+1; 
				this.setState({loading: true});
			}else{
				this.offset = this.offset=0; 
				this.tableData = [];
				this.setState({data: [],  loading: true});
			}
			
			IO.axios.get('/data/pedidos', {
						params: {
							...newQueryFiltersDate,
							estado: this.state.estado,
							prioridad: this.state.prioridad,
							centro: this.state.centro,
							empresa: this.state.empresa,
							offset : this.offset,
							ejecucion : this.state.ejecucion ? this.state.ejecucion : null,
							texto : this.state.text ? this.state.text : null
						}	
			}).then(response => {
				this.tableData = paginate ? _.concat(this.tableData,response.data) : response.data;
				this.setState({ data: this.tableData, loading: false});
				if(response.data.length===0) this.offset=-1
			});
		}
	 }
	componentDidMount() {
		const aux_query = new URLSearchParams(this.props.history.location.search);
		// QueryParams since sessionStorage
		// QueryParams dashboard-tab is used to know only the info is issued of Home.jsx
		if(!aux_query.has("dashboard-tab")){
			const filtersOfIncidents = Storage.getStorage("filtersOfIncidents");
			let queryFiltersDate = {};
			// Set up filters of sessionStorage
			if(filtersOfIncidents){
				//Update URL with queryParams
				this.updateUrlWithQueryParams(filtersOfIncidents);
				// Convert to object moment
				if(filtersOfIncidents.filters_date){
					const {fecha, fecha_inicio, fecha_final} = filtersOfIncidents.filters_date;
					queryFiltersDate.fecha = fecha? moment(fecha):null;
					queryFiltersDate.fecha_inicio = fecha_inicio? moment(fecha_inicio):null;
					queryFiltersDate.fecha_final = fecha_inicio? moment(fecha_final):null;
				}
				this.setState(state => ({
					...filtersOfIncidents,
					filters_date: {
						...state.filters_date,
						...filtersOfIncidents.filters_date,
						...queryFiltersDate
					}
				 }), this.getData);
			}else{
				this.setState(state => ({ 
					filters_date: {
						...state.filters_date,
						...this.getFiltersDateURl()
					}
				}), this.getData);
			}
		}else{
			// QueryParams since Home.jsx
			this.setState(state => ({ 
				filters_date: {
					...state.filters_date,
					...this.getFiltersDateURl()
				}
			}), this.getData);
		}
	 } 
	 
	 /**
	  * Update state in filters_date
	  * @param {string} name 
	  * @param {*} value 
	  */
	handleOnChangeFilterDate = (name, value) => {
		this.setState(state => ({ filters_date: {...state.filters_date, [name]: value } }));
	}

	/**
	 * Reset filters, update queryParams and get data
	 */
	resetFiltersDate = () => {
		// Delete queryParams of filters date
		const query = filterQuery(getQueries(this.props.history.location.search), [], ["tipo-fecha", "fecha", "year", "fecha_inicio", "fecha_final"]);
		// Update url
		this.props.history.replace({ search: objToQueryString(query) });

		this.setState({ filters_date: this.filters_date }, this.getData);
		// Delete filters date
		const filtersOfIncidents = Storage.getStorage("filtersOfIncidents");
		if(filtersOfIncidents){
			delete filtersOfIncidents.filters_date;
			Storage.setStorage("filtersOfIncidents", filtersOfIncidents);
		}
	}

	/**
	 * Close popup and get data
	 */
	applyFiltersDate = () => {
		this.setState(state => ({ filters_date: {...state.filters_date, openFiltersDate: false } }), this.getData);
		const {filter_date_selected, year, fecha, fecha_inicio, fecha_final} = this.state.filters_date;

		// Clean queryParams of URL
		const aux = new URLSearchParams(this.props.history.location.search);
		aux.delete("tipo-fecha");
		aux.delete("year");
		aux.delete("fecha");
		aux.delete("fecha_inicio");
		aux.delete("fecha_final");
		this.props.history.replace({search: aux.toString()})

		// Update filters date in url of FE
		let queryFiltersDate = {
			filter_date_selected
		}
		this.updateURLFiltros("tipo-fecha",filter_date_selected);
		switch (filter_date_selected) {
			case "year":
				queryFiltersDate.year = year;
				this.updateURLFiltros(filter_date_selected, year);
				break;
			case "date":
				queryFiltersDate.fecha = fecha?moment(fecha).format("YYYY-MM-DD"): null;
				this.updateURLFiltros("fecha", queryFiltersDate.fecha );
				break;
			case "range":
				queryFiltersDate.fecha_inicio = fecha_inicio?moment(fecha_inicio).format("YYYY-MM-DD"): null;
				queryFiltersDate.fecha_final = fecha_final?moment(fecha_final).format("YYYY-MM-DD"): null;
				this.updateURLFiltros("fecha_inicio", queryFiltersDate.fecha_inicio );
				this.updateURLFiltros("fecha_final", queryFiltersDate.fecha_final );
				break;
			default:
				break;
		}
		this.updateFiltersInSessionStorage("filters_date", queryFiltersDate);
	}

	/**
	 * Show value of selecter filter date
	 * @returns {string} selected date
	 */
	showSeletectFilterDate = () => {
		const {filter_date_selected, year, fecha, fecha_inicio, fecha_final} = this.state.filters_date;
		switch (filter_date_selected) {
			case "year":
				if(year){
					return year;
				}else{
					return this.filtersDatename[filter_date_selected];
				}
			case "date":
				if(fecha){
					return moment(fecha).format("DD/MM/YYYY");
				}else{
					return this.filtersDatename[filter_date_selected];
				}
			case "range":
				let stringInit = "", stringFinish = "";
				if(!fecha_inicio && !fecha_final){
					return this.filtersDatename[filter_date_selected];
				}
				if(fecha_inicio){
					stringInit = moment(fecha_inicio).format("DD/MM/YYYY");
				}
				if(fecha_final){
					stringFinish = moment(fecha_final).format("DD/MM/YYYY");
				}
				return stringInit + " - " + stringFinish;
			default:
				break;
		}
	}

	render() {
		const { data, estado, prioridad, centro, loading, ejecucion,
			filters_date} = this.state
		// console.log("this.state Incidencias.jsx: ", this.state);
		return (
			<Grid  style={{ margin: '1em' }}>
				<Grid.Row>
				  <Grid.Column width={4}>
						<Menu vertical>
							{this.user.roles.ADMINISTRACION && 
							<Menu.Item>
								Acciones
								 <Menu.Menu>								
								 
								 	<div>
										<Menu.Item>  <NuevoPedido fluid updateList={this.updateList} empresageneral={this.props.empresageneral}/></Menu.Item> 
									</div>
			
								</Menu.Menu>
							</Menu.Item>
							}
							<Menu.Item>
								Filtros
								<Menu.Menu>
									<Menu.Item>
										Texto
										<Input placeholder='...' value={this.state.text} onChange={this.handleFilter}/>
									</Menu.Item>
									<Menu.Item>
										Prioridad
										<Dropdown placeholder='Seleccione' fluid multiple selection options={prioridades} name="prioridad" value={prioridad} onChange={this.handleChange}/>
									</Menu.Item>
									<Menu.Item>
										Estado
										<Dropdown placeholder='Seleccione' fluid multiple selection options={estado_pedidos} name="estado" value={estado} onChange={this.handleChange}/>
									</Menu.Item>
									<Menu.Item>
										Centro
										<Dropdown placeholder='Seleccione' fluid search selection options={Storage.getStorage('centros_padres')} name="centro" value={centro} onChange={this.handleChange}/>
									</Menu.Item>
									<Menu.Item>
										Fecha
											<div style={{ position: "relative" }}>
												<Dropdown placeholder={this.showSeletectFilterDate()} fluid
													open={false} className="container-filter-date"
													onClick={() => this.setState( state => ({ filters_date: {...state.filters_date, openFiltersDate: !state.filters_date.openFiltersDate } }))}
												/>
											{filters_date.openFiltersDate && (
												<ContainerFiltersDate>
													<YCSSelectDate
														filters_date={filters_date}
														handleOnChangeFilterDate={this.handleOnChangeFilterDate}
														applyFiltersDate={this.applyFiltersDate}
														resetFiltersDate={this.resetFiltersDate}
													/>
												</ContainerFiltersDate>
											)}
											</div>
									</Menu.Item>
									<Menu.Item>
										<Checkbox toggle label='En proceso' name="ejecucion" value={ejecucion} onChange={this.handleEjecucion}/> 
									</Menu.Item>
									<Menu.Item>
										<Button content='Borrar Filtros' floated='right' size='mini' onClick={this.borrarFiltros} style={{marginBottom:'5px'}}/>
									</Menu.Item>
								</Menu.Menu>
							</Menu.Item>
						</Menu>
					</Grid.Column>
					<Grid.Column width={12}> <ListaPedidos data={data} update={this.getData} loading={loading} paginate={this.paginate} empresageneral={this.props.empresageneral}/> </Grid.Column>
				</Grid.Row>
			</Grid>
		);
  }
 }

class NuevoPedido extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			centro: this.props.centro, 
			descripcion: '', prioridad: 'NORMAL',
			invalidate : null, diablebutton: false, subcentros : [], subcentro : null
		};
		if(this.props.centro){
			this.setState({subcentros:  _.filter(Storage.getStorage('centros_todos'),{centro_id:this.props.centro})}); 
		}
	};
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresa: (next.empresageneral!==-1?next.empresageneral:null)});
	}
	handleChange = (e, { name, value }) => {
		if(name === 'email'){
			this.setState({ [name]: !this.state.email });
		}else
			this.setState({ [name]: value });
		if (this.state.centro && (this.state.subcentro || this.state.subcentros.length===0) && this.state.descripcion && this.state.prioridad) {
			this.setState({invalidate:false});
		}
	}
	handleCentroChange = (e, { name, value }) => {
		this.setState({ [name]: value });		
		this.setState({subcentros:  _.filter(Storage.getStorage('centros_todos'),{centro_id:value})}); 
	}

	createPedido = () => {
		if (this.state.centro  && this.state.descripcion && this.state.prioridad) {
			this.setState({invalidate:false, diablebutton:true});
			var allFields = '{ "centro":"'+this.state.centro+
			'", "subcentro":"' +this.state.subcentro+
			'", "prioridad":"'+this.state.prioridad+
			'", "descripcion":"'+this.state.descripcion.replace(/\r?\n/g, '<br/>')+
			'", "email":"'+ this.state.email +'"}';
			IO.axios({
				url:'/data/pedidos', 
				method: 'post',
				data: allFields})
				.then(response => {
							this.setState({ open: false });
							this.props.updateList(response.data.result);	
							this.resetComponent();
				});
		}else{
			this.setState({invalidate:true});
		}
	}
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
	cancel = () => {
		this.resetComponent();
		this.close();
	}
	resetComponent = () => this.setState({
		centro: this.props.centro, descripcion: '', prioridad: 'NORMAL',
		email : false, invalidate : null, diablebutton:false,  subcentros : [], subcentro : null
	  })
  render() {
		const { open, invalidate, centro, descripcion, prioridad, email, subcentros, subcentro} = this.state
    return (
      <Modal
        open={open} onOpen={this.open} onClose={this.close} size='small'
		trigger={<Button primary fluid={this.props.fluid}>Nuevo Pedido</Button>}
      >
        <Modal.Header>Nuevo Pedido</Modal.Header>
        <Modal.Content>
					<Form error={invalidate}>
						<Form.Group widths='equal'>
							<Form.Field control={Dropdown} label='Prioridad' placeholder='Seleccione' fluid selection search 
													name='prioridad' options={prioridades} value={prioridad}
													error={!prioridad && invalidate} onChange={this.handleChange}/>
							<Form.Field control={Dropdown} label='Centro Deportivo' placeholder='Seleccione' fluid selection search 
													name='centro' options={Storage.getStorage('centros_padres')} value={centro} 
													error={!centro && invalidate} onChange={this.handleCentroChange}/>
						</Form.Group>
						{subcentros.length >0 && <Form.Field control={Dropdown} label='Subcentro' placeholder='Seleccione' fluid selection search 
													name='subcentro' options={subcentros} value={subcentro} 
													error={subcentros.length>0 && !subcentro && invalidate} onChange={this.handleChange}/>}
											
						<Form.Field control={TextArea} label='Descripción' name='descripcion' error={!descripcion && invalidate} onChange={this.handleChange} value={descripcion}/>
						<Form.Field control={Checkbox} label='Marque para enviarse el correo a usted mismo' name='email' onChange={this.handleChange} checked={email}/>
					
						<Message
							error
							header='Error en formulario'
							content='Complete los campos obligatorios (en rojo).'
						/>
					</Form>
        </Modal.Content>
        <Modal.Actions>
        	<Button  content='Aceptar' onClick={this.createPedido} disabled={this.state.diablebutton}/>	
			<Button  content='Cancelar' onClick={this.cancel}  />
        </Modal.Actions>
      </Modal>
    )
  }
 }

const ContainerFiltersDate = styled.div`
	box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
	bottom: 55px;
	position: absolute;
	z-index: 99;
`;
   export {Pedidos, NuevoPedido}
