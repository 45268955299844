export const version = "2.2.0";
export const baseUrl = "https://imd.gmanti.com/api/";

export const tipoInstalaciones = [
    {key: "Complejos y Parques Deportivos", text: "Complejos y Parques Deportivos",value: "Complejos y Parques Deportivos", icono:"complejos.png"},
    {key: "Pabellones y pistas polideportivas",text: "Pabellones y pistas polideportivas",value: "Pabellones y pistas polideportivas", icono:"pabellon.png"},
    {key: "Campos de fútbol",text: "Campos de fútbol",value: "Campos de fútbol", icono:"futbol.png"},
    {key: "Pistas deportivas",text: "Pistas deportivas",value: "Pistas deportivas", icono:"pistas.png"},
    {key: "Piscinas",text: "Piscinas",value: "Piscinas", icono:"piscina.png"},
    {key: "Gimnasios",text: "Gimnasios",value: "Gimnasios", icono:"gimnasio.png"},
    {key: "Pistas de Petanca",text: "Pistas de Petanca",value: "Pistas de Petanca", icono:"petanca.png"},
    {key: "Rocódromo",text: "Rocódromo",value: "Rocódromo", icono:"rocodromo.png"}
];

export const oficios = [
    {text: "Albañilería",value: "Albañilería"},
    {text: "Fontanería",value: "Fontanería"},
    {text: "Pintura",value: "Pintura"},
    {text: "Cerrajería",value: "Cerrajería"},
    {text: "Soldadura",value: "Soldadura"},
    {text: "Utilería",value: "Utilería"},
    {text: "Mantenimiento General",value: "Mantenimiento General"},
    {text: "Reparaciones césped",value: "Reparaciones césped"},
    {text: "Mantenimiento césped",value: "Mantenimiento césped"},
    {text: "Equipamiento deportivo",value: "Equipamiento deportivo"},
    {text: "Otros",value: "Otros"},
    {text: "Electricidad",value: "Electricidad"},
    {text: "Contraincendios",value: "Contraincendios"}
];

export const distritos = [
    { key: 'Vegueta - Cono Sur - Tafira', text: 'Vegueta - Cono Sur - Tafira', value: 'Vegueta - Cono Sur - Tafira' },
    { key: 'Centro', text: 'Centro', value: 'Centro' },
    { key: 'Puerto - Canteras', text: 'Puerto - Canteras', value: 'Puerto - Canteras' },
    { key: 'Ciudad Alta', text: 'Ciudad Alta', value: 'Ciudad Alta' },
    { key: 'Tamaraceite - San Lorenzo', text: 'Tamaraceite - San Lorenzo', value: 'Tamaraceite - San Lorenzo' },
]

export const prioridades = [
    { key: 'URGENTE', text: 'URGENTE', value: 'URGENTE', label:{ color: 'red', empty: true, circular: true } },
    { key: 'PRIORITARIA', text: 'PRIORITARIA', value: 'PRIORITARIA', label:{ color: 'yellow', empty: true, circular: true } },
    { key: 'NORMAL', text: 'NORMAL', value: 'NORMAL', label:{ color: 'grey', empty: true, circular: true } },
]
export const estados = [
    { key: 'nueva', text: 'Nueva', value: 'nueva' , label:{ color: 'green', empty: true, circular: true }},
    { key: 'resuelta', text: 'Resuelta', value: 'resuelta', label:{ color: 'orange', empty: true, circular: true } },
    { key: 'rechazada', text: 'Rechazada', value: 'rechazada', label:{ color: 'black', empty: true, circular: true } },
    { key: 'reabierta', text: 'Reabierta', value: 'reabierta', label:{ color: 'green', empty: true, circular: true } },
    { key: 'cerrada', text: 'Cerrada', value: 'cerrada', label:{ color: 'grey', empty: true, circular: true } },
    { key: 'valorada', text: 'Valorada', value: 'valorada', label:{ color: 'olive', empty: true, circular: true } },
   ]
export const estado_pedidos = [
    { key: 'nuevo', text: 'Nuevo', value: 'nuevo' , label:{ color: 'green', empty: true, circular: true }},
    { key: 'confirmado', text: 'Confirmado', value: 'confirmado', label:{ color: 'orange', empty: true, circular: true } },
    { key: 'rechazado', text: 'Rechazado', value: 'rechazado', label:{ color: 'black', empty: true, circular: true } },    
    { key: 'entregado', text: 'Entregado', value: 'entregado', label:{ color: 'grey', empty: true, circular: true } },
    
   ]
export const periodos = [
    { key: 'DIAS', text: 'DIAS', value: 'DIAS' },
    { key: 'SEMANAS', text: 'SEMANAS', value: 'SEMANAS' },
    { key: 'MESES', text: 'MESES', value: 'MESES' },
    { key: 'AÑOS', text: 'AÑOS', value: 'AÑOS' },
]

export const COLORS = [ '#0088FE', '#00C49F', '#FFBB28', '#FF8042','#8088FE', '#80C49F', '#AABB28', '#AA8042',
                        '#00ooFE', '#00FF9F', '#FF3328', '#FF1242','#80FEFE', '#80569F', '#AA2428', '#AA0942',
                        '#00880E', '#00C41F', '#FFBBAB', '#FF80A2','#80884E', '#80C40F', '#AABBD8', '#AA80DD'];
