import _ from 'lodash'
import React, { Component } from 'react';
import { Dimmer, Loader, Message, Menu, Modal, Button, Form, Card, Image, TextArea , Icon} from 'semantic-ui-react';
import {IO} from './js/IO'
import {baseUrl} from './js/constdata'


class ListaArchivos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	};
	deletefile = (e, {name}) =>{
		this.setState({loading: true});
		IO.axios({
			url:'/imageserver/'+this.props.files+'?id='+name, 
			method: 'DELETE'})
			.then(response => {
				this.setState({loading: true});
				this.props.updateFiles();			
				this.setState({loading: false});
			});		
	}
	donwloadFile = (file,name) =>{
		IO.axios({url:file, responseType:'arraybuffer'}).then(function(response) {
			let blob = new Blob([response.data], { type:   'application/'+name } )
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = name
			link.click()
		});	
	}
	render() {
		const { archivos, loading} = this.props
		return (
			<Card.Group itemsPerRow={2}>
				<Dimmer inverted active={this.state.loading || loading}>
						<Loader inverted >Cargando archivos ...</Loader>
				</Dimmer>
				{_.map(archivos, ({ id, name, type, description, fecha }) => (
					<Card key={id}>
						{type.startsWith("image")
							 ? <Image src={baseUrl+'/imageserver/'+this.props.files+'?id='+id+'&download'}/>
							 : <Icon name="file alternate" />
						}
						<Card.Content>
							<Card.Header>
								<a href="#" onClick={() => this.donwloadFile(baseUrl+'/imageserver/'+this.props.files+'?id='+id+'&download',name)}>{name}</a>
								<Button className='derecha' icon='trash' color='red' onClick={this.deletefile} name={id} />
							</Card.Header>
							<Card.Meta>{fecha}</Card.Meta>
							<Card.Description>{description}</Card.Description>
						</Card.Content>
					</Card>
				))}								
			</Card.Group>
		);
  }
}

class SubirArchivo extends Component {
	textInput = null;
	constructor(props) {
		  super(props);
		  this.state = {
			  open: this.props.open,
			  archivo: '',
			  descripcion: '',
			  file: null,
			  invalidate: null
		  };
	};	  
	handleChange = (e) => {this.setState({ arhivo: this.state.file ? this.state.file.name : '' });}
	handleDescription = (e,{value}) => {this.setState({ descripcion: value });}
	handleFile = (event) => {
		event.preventDefault();

		let reader = new FileReader();
		let file = event.target.files[0];
		
		reader.onloadend = () => {
			this.setState({
			  file: file,
			  imagePreviewUrl: reader.result,
			  archivo : file.name
			});
		}
	  	reader.readAsDataURL(file)
	 }
	enviar = (event) => {
		if(this.state.archivo){
			const data = new FormData();
			data.append('files', this.state.file);
			data.append('incidencia', this.props.incidencia);
			data.append('pedido', this.props.pedido);			
			data.append('centro', this.props.centro);
			data.append('inventario', this.props.inventario);			
			data.append('descripcion', this.state.descripcion);			
			IO.axios({
				url:'/imageserver/'+this.props.files, 
				method: 'post',
				data: data,
				headers: {'Content-Type': 'multipart/form-data'}})
				.then(response => {
					this.props.updateFiles();
					this.resetComponent();
				});
		}else{
			this.setState({ invalidate: true})
		}
	 }
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	resetComponent = () => this.setState({ archivo: '', descripcion: '', file: null, invalidate: null, imagePreviewUrl:null, open: false })
	handleInputClick = () => this.textInput.click()
	render() {
		const { open, archivo, imagePreviewUrl, invalidate} = this.state
		let $imagePreview = null;
		if (imagePreviewUrl) {
		  $imagePreview = (<img src={imagePreviewUrl} height='100px' alt=""/>);
		} else {
		  $imagePreview = '';
		}
		return (
			<Modal open={open}  onOpen={this.open}  onClose={this.close}
			size='small' trigger={<Menu.Item name='enviar' >Nuevo archivo</Menu.Item>}
			>
				<Modal.Header>Subir archivo</Modal.Header>
				<Modal.Content>						
						<Form error={invalidate}>
							<input type= "file" onChange={this.handleFile} ref={(input) => { this.textInput = input; }}/>
							<Form.Input label='Archivo' value={archivo} icon='upload' onClick={this.handleInputClick} onChange={this.handleChange}/>	
							<Form.Group>								
								<Form.Field width={11} control={TextArea} label='Descripción' name='descripcion' onChange={this.handleDescription} value={this.state.descripcion}/>
								<Form.Field width={5}><div>{$imagePreview}</div></Form.Field>
							</Form.Group>	
							<Message
								error
								header='Error en formulario'
								content='Debe seleccionar un archivo.'
							/>						 													
						</Form>
				</Modal.Content>
				<Modal.Actions>
							<Button  content='Aceptar' onClick={this.enviar} />
							<Button  content='Cancelar' onClick={this.resetComponent} />
				</Modal.Actions>
			</Modal>
	  	)
	}
   }

  export {ListaArchivos,SubirArchivo};