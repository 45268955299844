import React, { Component } from 'react';
import './App.css';
import {Storage} from './js/storage';
import GeneralLayout from './GeneralLayout';
import {Login} from './Login';
import {IO} from './js/IO'
import axios from 'axios'
import {baseUrl} from './js/constdata'
import { BrowserRouter as Router } from 'react-router-dom';
import { Button, Modal, Icon } from 'semantic-ui-react';

class App extends Component {

  constructor(props) {
		super(props);
    this.state = { 
      login: false,
      user: Storage.getStorage('user'),
      showModal: false,
      textModal: "",
      sessionExpried: false    // true = session expired, false = not session expired
    };

    Storage.removeStorage('user');
    axios.get(baseUrl+'/loginREST').then(response => {
          if(response.data.error === 0){
            //IO.get(baseUrl+'/loginREST');
            Storage.setStorage('user',response.data);
            this.setState({ login: true , user : response.data});
          }else{
            Storage.removeStorage('user');
            this.setState({ login: false , user : null});
          }
            
        });
    
    this.setLogin = this.setLogin.bind(this);
  }

  setLogin = (data) => {
    this.setState({ user: data , login: true });
  }

  setLogout = (data) => {
    IO.axios.get('/logoutREST');
    this.setState({ login: false ,user:{}, showModal: false});
    Storage.removeAll();
  }

  /**
   * 
   * @param {*} mensaje Puede ser un objeto o un string
   */
   showError = (mensaje) => {
      this.setState({ textModal: mensaje, showModal: true });
      if(Object.keys(mensaje).length === 0 ){
          this.setState({ login: false , user : null});
      }else if(mensaje?.includes("Debe logarse para acceder a la información")){
        Storage.removeAll();
        this.setState({ sessionExpried: true });
      }
  }
  
  componentWillMount (){
      this.startErrorLog();
  }
  startErrorLog(){
    var mensaje = this.showError;
    window.addEventListener("unhandledrejection", function(err, promise) {  
      if(!err.reason.stack || (err.reason.stack && err.reason.stack.indexOf('bundle.js') ===-1)){
        mensaje(err.reason);
      }
    });
    window.onerror = (message,file,line,column,errorObject) =>
    {
      if(!errorObject.stack || (errorObject.stack && errorObject.stack.indexOf('bundle.js') ===-1)){
        mensaje(errorObject);
    }
      return false;
    }
  }

  handleOpenModal = () => this.setState( state => ({showModal: !state.showModal}) );

  render() {
    // console.log("this.state App: ", this.state);
    const {showModal, textModal, sessionExpried} = this.state;
    return (
      <Router>
        <React.Fragment> 
          {this.state.login 
              ? <GeneralLayout onLogout={this.setLogout} user={this.state.user}/>
              : <Login onLogin={this.setLogin}/>}
              
            {/* Modal */}
            <Modal
              onClose={this.handleOpenModal}
              open={showModal}
              closeIcon={!sessionExpried? true : false}   
              // Solo si la sesión ha caducado podemos hacer click en el button de Login
              closeOnDimmerClick={!sessionExpried? true : false}   
            >
                <Modal.Header>Alerta</Modal.Header>
                <Modal.Content>
                    <p>{textModal}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" onClick={!sessionExpried? this.handleOpenModal : this.setLogout}>
                      <Icon name="checkmark"/>{!sessionExpried? "Aceptar" : "Login"}
                    </Button>
                </Modal.Actions>
            </Modal>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
