import _ from 'lodash'
import React, { Component } from 'react';
import {Confirm, Form,TextArea, Input, List, Segment, Header, Label, Button, Menu, Grid, Modal, Dropdown, Message} from 'semantic-ui-react';
import {IO} from './js/IO'
import ListaIncidencias from './ListaIncidencias';
import {ListaArchivos, SubirArchivo} from './ListaArchivos';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {periodos} from './js/constdata'
import {Validations} from './js/validations'
import {NuevaIncidencia} from './Incidencias'
import {Link } from 'react-router-dom';
import {Storage} from './js/storage';

class DetalleMaterial extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			material : {},
			materialS : {},
			partes: [],
			preventivo: [],
			activeItem: 'detalles',
			archivos: [] ,
			edit: false,
			open_confirm_delete: false,
			open_delete_preventivo: false,
			user: Storage.getStorage('user')
		};
	};
	handleItemClick = (e, { name }) => this.setState({ activeItem: name })
	changeToEdit = () => this.setState({ edit: true })
	handleCancelDelete = () => this.setState({ open_confirm_delete: false })
	handleOpenDelete = () => this.setState({ open_confirm_delete: true })
	handleCancelPreventivo = () => this.setState({ open_delete_preventivo: false})  
	handleDeletePreventivo  = (e,{id}) => this.setState({ open_delete_preventivo: true, delete_id:id})
	handleDeleteParte = (e,{id}) =>{
		IO.axios({
			url:'/data/inventario.php/'+this.state.material.id, 
			method: 'DELETE'})
		.then(response => {
					this.setState({ open_confirm_delete: false});
					this.props.history.push('/instalaciones/'+this.state.material.centro_id);
			});
	 }
	cancel = () => {
		var temp = _.clone(this.state.material);
		if (temp.fechaCompra) temp.fechaCompra= moment(temp.fechaCompra,'YYYY-MM-DD');
		if (temp.fechaFabricacion) temp.fechaFabricacion= moment(temp.fechaFabricacion,'YYYY-MM-DD');
		this.setState({ edit: false, materialS : temp });
	 }
	save = () => {
		var allFields = _.clone(this.state.materialS);
		allFields.fechaCompra = (allFields.fechaCompra) ? allFields.fechaCompra.format('YYYY-MM-DD') : null;
		allFields.fechaFabricacion = (allFields.fechaFabricacion) ? allFields.fechaFabricacion.format('YYYY-MM-DD') : null;
		IO.axios({
			url:'/data/inventario.php/'+this.state.materialS.id, 
			method: 'PUT',
			data: JSON.stringify(allFields)})
		.then(response => {
					this.getData();	
					this.setState({ edit: false});
			});
	 }
	handleFechaCompra = (date) => {
		var mat = this.state.materialS;
		mat.fechaCompra = date;
		this.setState({materialS: mat});
	 }
	handleFechaFabricacion = (date) => {
		var mat = this.state.materialS;
		mat.fechaFabricacion = date;
		this.setState({materialS: mat});
	 }
	handleEdit = (e, {name,value}) => {
		var mat = this.state.materialS;
		mat[name] = value;
		this.setState({materialS: mat});
	 }
	delete = () => {
		IO.axios({
			url:'/data/preventivo.php/'+this.state.delete_id, 
			method: 'DELETE'})
		.then(response => {
			this.getData();	
			this.setState({ open_delete_preventivo: false });
		});
	 }
	getData = () => {
		IO.axios.get('/imageserver/fileInventario?inventario='+this.props.match.params['Id']
			).then(response => {
				this.setState({ archivos: response.data.files });
			});
		IO.axios.get('/data/inventario.php/'+this.props.match.params['Id']
			).then(response => {
				this.setState({ material: response.data[0]});
				this.cancel();
			});
		IO.axios.get('/data/preventivo?inventario='+this.props.match.params['Id']
			).then(response => {
				this.setState({ preventivo: response.data });
			});
		IO.axios.get('/data/partes?inventario='+this.props.match.params['Id']
		).then(response => {
			this.setState({ partes: response.data });
		});
	 }
	componentDidMount() {
		this.getData();
	 }
	render() {
		const { material, materialS, activeItem, archivos, partes, preventivo, edit, user } = this.state
		if (this.props.match.params['Id'] !== material.id){
			this.getData();
			return ''
		}

		return (
			<Grid container style={{ marginTop: '1em' }}>
				<Confirm open={this.state.open_confirm_delete} 
					name='open_confirm_delete'
					content='¿Seguro que desa eliminar el inventario?'
					onCancel={this.handleCancelDelete} onConfirm={this.handleDeleteParte}
				/>
				<Confirm open={this.state.open_delete_preventivo} 
					name='open_delete_preventivo'
					content='¿Seguro que desa eliminar el preventivo?'
					onCancel={this.handleCancelPreventivo} onConfirm={this.delete}
				/>
				<Grid.Row>
					<Grid.Column width={4}>
						<Menu pointing vertical>
							{activeItem==='detalles'
							?	<Menu.Item active={activeItem === 'detalles'}>
									<Menu.Header>Detalles Material</Menu.Header>
									<Menu.Menu>
										<Menu.Item name='editar' onClick={this.changeToEdit} >Editar</Menu.Item>
										{(!preventivo || preventivo.length ===0) &&
											(!partes || partes.length === 0) && archivos.length===0 &&
										<Menu.Item name='borrar' onClick={this.handleOpenDelete}>Borrar</Menu.Item>}
									</Menu.Menu>
							</Menu.Item>
							:	<Menu.Item name='detalles' active={activeItem === 'detalles'} onClick={this.handleItemClick} >
									Detalles Material
								</Menu.Item>	
							}
							{activeItem==='preventivo'
							?	<Menu.Item active={activeItem === 'preventivo'} >
									<Menu.Header>Preventivo</Menu.Header>
									<Menu.Menu>
										<CrearPreventivo material={material.id} update={this.getData} empresageneral={this.props.empresageneral}/>
									</Menu.Menu>	
								</Menu.Item>
							:	<Menu.Item name='preventivo' active={activeItem === 'preventivo'} onClick={this.handleItemClick} >
									Preventivo
									<Label>{preventivo ? preventivo.length : 0}</Label>
								</Menu.Item>
							}
								<Menu.Item name='partes' active={activeItem === 'partes'} onClick={this.handleItemClick} >
									Incidencias
									<Label>{partes ? partes.length : 0}</Label>
									{activeItem==='partes' && <Menu.Menu>	
										<Menu.Item>  {user.roles.IMD && <NuevaIncidencia fluid updateList={this.getData} centro={material.centro_id} equipamiento={material.id} empresageneral={this.props.empresageneral}/>}</Menu.Item>
									</Menu.Menu>
									}
								</Menu.Item>
							{activeItem==='archivos'
							? 	<Menu.Item active={activeItem === 'archivos'}>
									<Menu.Header>Archivos</Menu.Header>
									<Menu.Menu>
										<SubirArchivo inventario={material.id} updateFiles={this.getData} files="fileInventario"/>
									</Menu.Menu>
								</Menu.Item>
							:	<Menu.Item name='archivos' active={activeItem === 'archivos'} onClick={this.handleItemClick} >
									Archivos <Label>{archivos.length}</Label>
								</Menu.Item>
							}
						</Menu>
					</Grid.Column>	
					<Grid.Column width={12}>
						<Header>
							<span><Link to={'/instalaciones/'+material.centro_id}>{material.centro}</Link>{' => '+material.codigo + ' - '}</span>
							{ edit ? (<Input fluid defaultValue={materialS.nombre} onChange={this.handleEdit} name='nombre' />) : material.nombre}
						</Header>
						{ activeItem==='detalles' &&
						<div>
						<Form>
						<Segment raised>
							<Grid >
								<Grid.Row>
									<Grid.Column width={8} as={Header} size='small'><Header.Subheader>Tipo</Header.Subheader>{material.tipo}</Grid.Column>
									<Grid.Column width={8} as={Header} size='small'><Header.Subheader>Ubicación</Header.Subheader>
									{edit	? <Input fluid defaultValue={materialS.ubicacion} onChange={this.handleEdit} name='ubicacion' /> 
											: material.ubicacion}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16} as={Header} size='small'><Header.Subheader>Descripción</Header.Subheader>
									{edit	? <Input fluid defaultValue={materialS.descripcion} onChange={this.handleEdit} name='descripcion' /> 
											: <div dangerouslySetInnerHTML={{ __html: material.descripcion }}  />  }
									</Grid.Column>									
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16} as={Header} size='small'><Header.Subheader>Observaciones</Header.Subheader>
									{edit 	? <TextArea autoHeight onChange={this.handleEdit} name='observaciones' style={{ width: '100%' }} defaultValue={materialS.observaciones} /> 
											: <div dangerouslySetInnerHTML={{ __html: material.observaciones }} />}
									</Grid.Column>
								</Grid.Row>
							</Grid>								
						</Segment>
						<Segment raised>
							<Label attached='top left'>Características del producto</Label>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Alto (cm)</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.alto} onChange={this.handleEdit} name='alto' /> : material.alto}
									</Grid.Column>
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Ancho (cm)</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.ancho} onChange={this.handleEdit} name='ancho' /> : material.ancho}
									</Grid.Column>	
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Fondo (cm)</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.fondo} onChange={this.handleEdit} name='fondo' /> :material.fondo}
									</Grid.Column>
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Peso (gr)</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.peso} onChange={this.handleEdit} name='peso' /> :material.peso}
									</Grid.Column>	
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Material</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.material} onChange={this.handleEdit} name='material' /> :material.material}
									</Grid.Column>
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Color</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.color} onChange={this.handleEdit} name='color' /> :material.color}
									</Grid.Column>	
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Superficie (m2)</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.superficie} onChange={this.handleEdit} name='superficie' /> :material.superficie}
									</Grid.Column>
									<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Volumen (m3)</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.volumen} onChange={this.handleEdit} name='volumen' /> :material.volumen}
									</Grid.Column>	
								</Grid.Row>
							</Grid>
						</Segment>
						<Segment raised>
							<Label attached='top left'>Datos comerciales</Label>
							<Grid>
								<Grid.Row>
									<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Marca</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.marca} onChange={this.handleEdit} name='marca' /> :material.marca}
									</Grid.Column>
									<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Modelo</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.modelo} onChange={this.handleEdit} name='modelo' /> :material.modelo}
									</Grid.Column>	
									<Grid.Column width={6} as={Header} size='small'><Header.Subheader>N. Serie</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.serie} onChange={this.handleEdit} name='serie' /> : material.serie}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Fecha Fabricación</Header.Subheader>
										{edit ? <DatePicker dateFormat="DD/MM/YYYY" selected={materialS.fechaFabricacion} onChange={this.handleFechaFabricacion}/> :material.fechaFabricacion}
									</Grid.Column>
									<Grid.Column width={5} as={Header} size='small'><Header.Subheader>Fecha Compra</Header.Subheader>
										{edit ? <DatePicker dateFormat="DD/MM/YYYY" selected={materialS.fechaCompra} onChange={this.handleFechaCompra}/> :material.fechaCompra}
									</Grid.Column>	
									<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Precio</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.precio} onChange={this.handleEdit} name='precio' /> : material.precio}
									</Grid.Column>	
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16} as={Header} size='small'><Header.Subheader>Proveedor</Header.Subheader>
										{edit	? <Input fluid defaultValue={materialS.proveedor} onChange={this.handleEdit} name='proveedor' /> : material.proveedor}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>
						{edit && 
							<div  style={{ marginTop: '1em', maginBottom: '1em' }}>																				
								<Button  onClick={this.save}>Guardar</Button>
								<Button  color='red' onClick={this.cancel}>Cancelar</Button>
							</div>
						}	
						</Form>
						</div>
						}
						{ activeItem==='partes' &&
							<ListaIncidencias data={partes} />
						}
						{ activeItem==='archivos' &&
							<ListaArchivos archivos={archivos} files="fileInventario" updateFiles={this.getData} />
						}
						{ activeItem==="preventivo" &&
							<List divided>
								{_.map(preventivo, ({ id, periodo, unidad, inicio, siguiente, descripcion }) => (
								<List.Item key={id}>
								<List.Content>
									<List.Header>Cada {periodo +' '+ unidad} con inicio {inicio}</List.Header>
									<List.Description>
										<Label>Próximo parte: {siguiente}</Label>
										<div>{descripcion}</div>
										<Button color='red' icon='delete' size='mini' floated='right' content='Borrar' onClick={this.handleDeletePreventivo	} id={id} />
									</List.Description>
								</List.Content>
								</List.Item>
								))}
							</List>							}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
  }
  }

class CrearPreventivo extends Component {	
	const
	constructor(props) {
		  super(props);
		  this.state = {
			  open: this.props.open, 
			  material: this.props.material, periodo:'', unidad: null, inicio: null, descripcion: null,
			  empresa: this.props.empresageneral !== -1 ? this.props.empresageneral : null
		  };
	};	  
	handleChange = (e, {name, value}) => this.setState({ [name]:value })
	handleFecha = (date) => this.setState({ inicio:date })	
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresa: (next.empresageneral!==-1?next.empresageneral:null)});
	}
	enviar = (event) => {
		if(this.state.periodo && Validations.isNumber(this.state.periodo) && this.state.unidad 
			&& this.state.inicio && this.state.descripcion && this.state.empresa ){
			var allFields = _.clone(this.state)
			allFields.inicio = allFields.inicio.format('YYYY-MM-DD');
			IO.axios({
				url:'/data/preventivo', 
				method: 'POST',
				data: JSON.stringify(allFields)})
			.then(response => {
						this.resetComponent();
						this.props.update();	
				});
		}else{
			this.setState({ invalidate: true})
		}
	 }
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	resetComponent = () => this.setState({open: this.props.open, periodo:'', unidad: null, inicio: null, descripcion: null, invalidate:null,
							empresa: this.props.empresageneral !== -1 ? this.props.empresageneral : null})
	render() {
		const estado = this.state
		return (
			<Modal open={estado.open}  onOpen={this.open}  onClose={this.close}
			size='small' trigger={<Menu.Item name='enviar' >Nuevo preventivo</Menu.Item>}
			>
				<Modal.Header>Nuevo inventario</Modal.Header>
				<Modal.Content>						
						<Form error={estado.invalidate}>
							<Form.Group widths='equal'>
								<Form.Input label='Periodo' value={estado.periodo} name='periodo' onChange={this.handleChange} 
									error={(!estado.periodo || !Validations.isNumber(estado.periodo)) && estado.invalidate}/>
								<Form.Field control={Dropdown} selection options={periodos} name="unidad" label="Unidad" value={estado.unidad} onChange={this.handleChange}
									error={!estado.unidad && estado.invalidate}/>
								<Form.Field control={DatePicker} selected={estado.inicio} onChange={this.handleFecha} name='inicio' label='Inicio'
									error={!estado.inicio && estado.invalidate} dateFormat='DD/MM/YYYY'/>																						
							</Form.Group>
							<Form.Field control={Dropdown} label='[Servicio] Empresa' placeholder='Seleccione' fluid selection search 
									name='empresa' options={Storage.getStorage('empresas')} value={estado.empresa}
									error={!estado.empresa && estado.invalidate} onChange={this.handleChange}/>							
							<Form.Field control={TextArea} autoHeight onChange={this.handleChange} name='descripcion' style={{ width: '100%' }} label='Descripción'
									defaultValue={estado.descripcion} error={!estado.descripcion && estado.invalidate}/>									
							<Message error header='Error en formulario' content='Complete los campos en rojo.'/>						 													
						</Form>
				</Modal.Content>
				<Modal.Actions>
							<Button  content='Aceptar' onClick={this.enviar} />
							<Button  content='Cancelar' onClick={this.resetComponent} />
				</Modal.Actions>
			</Modal>
	  	)
	}
 }
export default DetalleMaterial;