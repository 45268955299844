import _ from 'lodash'
import React, { Component } from 'react';
import {Visibility, Dimmer, Loader, Label, Popup, List, Button, Modal, TextArea, Grid,
Form} from 'semantic-ui-react';
import {Link } from 'react-router-dom';
import {Storage} from './js/storage';
import {IO} from './js/IO';

class ListaAvisos extends Component {
	handleUpdate = (e, { calculations }) => {
		if (calculations.percentagePassed > 0.8){
			this.props.paginate();
		}
	 }
	render() {
		const { data } = this.props
		const user = Storage.getStorage('user')
		return (
			<Visibility onUpdate={this.handleUpdate}> 
				<List divided>
					<Dimmer inverted active={this.props.loading}>
						<Loader inverted >Cargando avisos ...</Loader>
					</Dimmer>					 					
					{_.map(_.orderBy(data, ['id'], ['desc']), (row) => (
						<List.Item key={row.id}>
						<List.Content as={Link} to={'#'}>
							<List.Header onClick={() => this.props.onSelect(row)}>
								
								<List horizontal>
									<List.Item>{"  ["+row.fechaCreacion+"] "} </List.Item>
									<List.Item>{row.subcentro 
															? (<div><Popup trigger={<div>{row.centro}</div>}  content={row.subcentro}/></div>)
															: <div>{row.centro}</div>
														}
									</List.Item>
									{user.roles.IMD && this.props.empresageneral===-1 &&
									<List.Item> 
										<Popup trigger={<Label basic icon='users' content={row.servicio_desc.toUpperCase()} />}  content={row.empresa}/>
									</List.Item>
									}
									<List.Item>
										<Label basic icon='user circle' content={row.autor}/>
									</List.Item>
								</List>					
							</List.Header>
							<List.Description >
								<EliminarAviso id={row.id} update={() => this.props.update()}></EliminarAviso>
								<List horizontal>
									<List.Item>
									<Popup wide='very'
										trigger={row.descripcion.length > 120 ? <span>{row.descripcion.replace("<br/>"," ").replace("<br/>"," ").substring(0,120)}</span>:<span>{row.descripcion.replace("<br/>"," ") }</span>}  
										content={<div dangerouslySetInnerHTML={{ __html: row.descripcion }} />}
									/>
									</List.Item>
								</List>
							</List.Description>							
						</List.Content>
						</List.Item>
					))}					 
				</List>
				</Visibility> 
		);
  }
}

class EliminarAviso extends Component {
	constructor(props) {
		super(props);
		this.state = {open: false, mensaje: ""};
	};
	deleteAviso = () => {
		IO.axios({
			url:'/data/avisos.php/'+this.props.id, 
			method: 'DELETE',
			params:{
				mensaje: this.state.mensaje
			}
		})
			.then(response => {
				this.setState({ open: false });
				this.props.update();
			});
	}
	mopen = () => this.setState({ open: true })
	mclose = () => this.setState({ open: false, mensaje: "" })
	cancel = () => {
		this.mclose();
	}
	render() {
		const { open, mensaje} = this.state
		return (
		<Modal
			open={open}
			onOpen={this.mopen}
			onClose={this.mclose}
			size='small'
			trigger={<Button compact basic color='red' floated='right'>Eliminar Aviso</Button>}
		>
			<Modal.Header>Eliminar Aviso</Modal.Header>
			<Modal.Content>
				¿Desea eliminar el aviso?
				<Grid style={{ marginTop: '5px'}}>
					<Grid.Row>
						<Grid.Column>
							<Form>
								<TextArea placeholder="Motivo de la eliminación" rows={3} value={mensaje} onChange={({target}) => this.setState({ mensaje: target.value })}/>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Modal.Content>
			<Modal.Actions>
				<Button  content='Aceptar' onClick={this.deleteAviso}/>	
				<Button  content='Cancelar' onClick={this.cancel}  />
			</Modal.Actions>
		</Modal>
		)
	}
}

export default ListaAvisos;
