import React, { Component } from 'react';
import {Grid, Menu} from 'semantic-ui-react';
import InformeActuacion from './reports/InformeActuacion'
import InformeCentro from './reports/InformeCentro'
import InformeDistrito from './reports/InformeDistrito'
import InformeMaterial from './reports/InformeMaterial'
import InformeOficio from './reports/InformeOficio'
import InformeTipo from './reports/InformeTipo'

class Informes extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			activeItem: 'economicos',
		};
	};
	handleItemClick = (e, { name }) => this.setState({ activeItem: name })
	handleSubItemClick = (e, { name }) => this.setState({ activeSubItem: name })
	
	render() {
		const {activeItem, activeSubItem} = this.state;
		return (
				<Grid style={{ margin: '1em' }}>
					<Grid.Row>
				  	<Grid.Column width={3}>
							<Menu pointing vertical>
							{activeItem==='economicos'
								?	<Menu.Item active={activeItem === 'economicos'} >
										<Menu.Header>Informes económicos</Menu.Header>
										<Menu.Menu>
											<Menu.Item name='ReportCentro' onClick={this.handleSubItemClick}>Por Centro</Menu.Item>
											<Menu.Item name='ReportTipo' onClick={this.handleSubItemClick}>Por Tipo</Menu.Item>
											<Menu.Item name='ReportDistrito' onClick={this.handleSubItemClick}>Por Distrito</Menu.Item>
											<Menu.Item name='ReportOficio' onClick={this.handleSubItemClick} >Por Oficio</Menu.Item>
											<Menu.Item name='ReportMaterial' onClick={this.handleSubItemClick} >Por Material</Menu.Item>
										</Menu.Menu>	
								</Menu.Item>
								:	<Menu.Item name='economicos' active={activeItem === 'economicos'} onClick={this.handleItemClick} >
										Informes económicos
									</Menu.Item>
							}
								<Menu.Item name='actuacion' active={activeItem === 'actuacion'} onClick={this.handleItemClick} >
									Informe de actuación
								</Menu.Item>
							</Menu>
						</Grid.Column>
						<Grid.Column width={13}>
							{activeItem==='economicos' && activeSubItem==='ReportCentro' && <InformeCentro empresageneral={this.props.empresageneral} />}
							{activeItem==='economicos' && activeSubItem==='ReportDistrito' && <InformeDistrito empresageneral={this.props.empresageneral}/>}
							{activeItem==='economicos' && activeSubItem==='ReportMaterial' && <InformeMaterial empresageneral={this.props.empresageneral}/>}
							{activeItem==='economicos' && activeSubItem==='ReportOficio' && <InformeOficio empresageneral={this.props.empresageneral}/>}
							{activeItem==='economicos' && activeSubItem==='ReportTipo' && <InformeTipo empresageneral={this.props.empresageneral}/>}
							{activeItem==='actuacion' && <InformeActuacion empresageneral={this.props.empresageneral}/>}
				  	</Grid.Column>
					</Grid.Row>
				</Grid>
		);
  }
}

export default Informes;