import _ from 'lodash'
import React, { Component } from 'react';
import {Visibility, Dimmer, Loader, Label, Popup, List} from 'semantic-ui-react';
import {Link } from 'react-router-dom';
import {Storage} from './js/storage';
import {CambioEstado} from './DetalleIncidencia'

class ListaIncidencias extends Component {
	handleUpdate = (e, { calculations }) => {
		if (calculations.percentagePassed > 0.8){
			this.props.paginate();
		}
	 }
	render() {
		const { data } = this.props
		const user = Storage.getStorage('user')
		return (
			<Visibility onUpdate={this.handleUpdate}> 
				<List divided>
					<Dimmer inverted active={this.props.loading}>
						<Loader inverted >Cargando incidencias ...</Loader>
					</Dimmer>					 					
					{_.map(_.orderBy(data, ['id'], ['desc']), ({ id, idimd, prioridad, estado, centro, descripcion, inspector, empresa, fechaCreacion, servicio_desc, subcentro, ejecucion, nombre, operario1, operario2, operario3, operario4}) => (
						<List.Item key={id}  style={{backgroundColor: ejecucion==="1" ?'#e4ff0366' : ''}} >
						<List.Content as={Link} to={'/incidencias/'+id}>

							<List.Header>
								
								<List horizontal>
									<List.Item>
										<Label color={	prioridad==="URGENTE" ? 'red' : 
														prioridad==="PRIORITARIA" ? 'yellow' : 'grey'} ribbon>
											{id}{idimd && " ("+idimd+")"}</Label>
									</List.Item>
									<List.Item><Label color={estado==="CERRADA"  ? 'grey' : estado==="RECHAZADA" ? 'black' : estado==="RESUELTA" ? 'orange' : estado==="VALORADA" ? 'olive' : 'green'} horizontal basic>{estado}</Label></List.Item>
									<List.Item>{"  ["+fechaCreacion+"] "} </List.Item>
									<List.Item>{subcentro 
															? (<div><Popup trigger={<div>{centro}</div>}  content={subcentro}/></div>)
															: <div>{centro}</div>
														}
									</List.Item>
									{user.roles.IMD && this.props.empresageneral===-1 &&
									<List.Item> 
										<Popup trigger={<Label basic icon='users' content={servicio_desc.toUpperCase()} />}  content={empresa}/>
									</List.Item>
									}
									<List.Item>
										<Popup trigger={<Label basic icon='user circle' content={inspector.substring(0,inspector.indexOf(" "))}/>}  content={inspector}/>
									</List.Item>
									{ejecucion==="1" &&
									<List.Item> 
										<Popup trigger={<Label basic icon='bullseye' content="Ejecución"/>} content={<List>{nombre && <List.Item>{nombre}</List.Item>}{operario1 && <List.Item>{operario1}</List.Item>}{operario2 && <List.Item>{operario2}</List.Item>}{operario3 && <List.Item>{operario3}</List.Item>}{operario4 && <List.Item>{operario4}</List.Item>}</List>} />
									</List.Item>
									}
								</List>					
							</List.Header>
							<List.Description >
								<List horizontal>
									{user.roles.SERVICIO && (estado==="NUEVA" || estado==="REABIERTA")  && 
									<List.Item>
										<CambioEstado icon='checkmark' color='orange' content='resolver' 
												update={this.props.update} incidencia={id}/>
									</List.Item>
									}
									<List.Item>
									<Popup wide='very'
										trigger={descripcion.length > 120 ? <span>{descripcion.replace("<br/>"," ").replace("<br/>"," ").substring(0,120)}</span>:<span>{descripcion.replace("<br/>"," ") }</span>}  
										content={<div dangerouslySetInnerHTML={{ __html: descripcion }} />}
									/>
									</List.Item>
									
								</List>
							</List.Description>							
						</List.Content>
						</List.Item>
					))}					 
				</List>
				</Visibility> 
		);
  }
}
export default ListaIncidencias;
