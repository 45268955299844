import React, { Component } from 'react';
import { Label, Dropdown, Segment, Header, Button, Step, Checkbox, Image, Form, Icon} from 'semantic-ui-react';
import {Storage} from '../js/storage';
import _ from 'lodash';
import {IO} from '../js/IO';
import {baseUrl} from '../js/constdata';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from 'styled-components';


class InformeActuacion extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			centro: null, partes: [], previas: [], finales:[], preSelected:[],
			empresageneral: this.props.empresageneral,
			centros: Storage.getStorage('centros_padres'),
			fecha_inicio: moment(),
			fecha_fin: moment(),
			incidencias: [],
			incidenciasBackup: [],			// Almacenamos la copia de las incidencias
			previousState3Inside: false, 	// true = pasamos al 4º State, false  = estamos en el 3º State,
											// permite controlar el paso del 3º SState al 4º State
			previousState3Completed: false,	// Si se completa el paso 3 se marcca como true en caso contrario false

			finalState4Inside: false, 		// true = pasamos al último estado, false  = estamos en el 4º State,
											// permite controlar el paso del 4º SState al último State
			finalState4Completed: false		// Si se completa el paso 4 se marcca como true en caso contrario false
		};
	};
	cancelar = () => this.setState({
		centro: null,
		partes: [],
		previas: [],
		finales: [],
		preSelected: [],
		allincidencias: false, 

		previousState3Inside: false,
		previousState3Completed: false,

		finalState4Inside: false,
		finalState4Completed: false
	});

	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.cancelar());
		this.setState({centros: Storage.getStorage('centros_padres')});
	}

	/*
	* El primer elemento de la response es el más reciente
	*/
	handleCentroChange = (e, {value }) => {
		this.setState({ centro: value });
		IO.axios.get('/data/partes', {
			params: {
				empresa: (this.state.empresageneral!== -1 ? this.state.empresageneral : null),				
				centro: value
			}
		}).then(response => this.setState({
			incidencias: response.data,
			incidenciasBackup: response.data,
			fecha_fin: response.data.length > 0 ? moment(response.data[ 0 ].fechaCreacion, "YYYY-MM-DD") : moment(),
			fecha_inicio: response.data.length > 0 ? moment(response.data[ response.data.length - 1 ].fechaCreacion, "YYYY-MM-DD") : moment(),
		}));
	 }
	selectAllIncidencias = () => {
		if(this.state.preSelected.length ===0 ){
			this.setState({preSelected: _.map(this.state.incidencias,(i) => i.id), allincidencias:true});
		}else{
			this.setState({preSelected: [], allincidencias:false});
		}
	 }
	select = (e,{name}) => {
		var partes = this.state.preSelected;
		if(partes.indexOf(""+name) > -1){
			partes.splice(partes.indexOf(""+name), 1);
		}else{
			partes.push(""+name);
		}
		this.setState({preSelected: partes})
	 }

	selectPartes = () => {
		this.setState({	partes: this.state.preSelected, 
						preSelected:[], fotos: []},
			() => {_.map(this.state.partes,(parte) => {
				IO.axios.get('/imageserver/filePartes.php?incidencia='+parte)
				.then(response => this.setState({fotos: _.concat(this.state.fotos,response.data.files)}));
			})}
		);
	 }
	selectFotosPre = () => this.setState({	previas: this.state.preSelected, preSelected:[], previousState3Inside: true, previousState3Completed: true });
	
	/**
	 * Controlamos el paso al último estado
	 */
	selectFotosPost = () => this.setState({	finales: this.state.preSelected, preSelected:[], finalState4Inside: true, finalState4Completed: true })
	
	generar = () => {
		var partesurl = _.map(this.state.partes,(p) =>"&partes[]="+p);
		var imagesPre = _.map(this.state.previas, (p) =>"&imagesPre[]="+p);
		var imagesPost= _.map(this.state.finales, (p) =>"&imagesPost[]="+p);
		var location = "/utils/printActuacion.php?centro="+this.state.centro+
						partesurl.join("")+imagesPre.join("")+imagesPost.join("");
		const name = 'Actuacion_'+this.state.centro+'.docx';
		IO.axios({url:location, responseType:'arraybuffer'})
		.then(function(response) {
				let blob = new Blob([response.data], { type:   'application/pdf' } )
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = name
				link.click()
			});	
	}

	handleInicio = (date)  => this.setState({fecha_inicio: date},() => this.filterIncidents());	
	handleFin = (date) => this.setState({fecha_fin: date},() => this.filterIncidents());

	/**
	 * Filtramos las fechas entre el incio y el fin de los Datepickers
	 */
	filterIncidents = () => {
		const fecha_inicio = this.state.fecha_inicio.format("YYYY-MM-DD");
		const fecha_fin = this.state.fecha_fin.format("YYYY-MM-DD");
		const {incidenciasBackup} = this.state;		
		let newBufferFiltered = _.filter(incidenciasBackup, incident => {
			if(moment(fecha_inicio).isSameOrBefore(incident.fechaCreacion) && moment(fecha_fin).isSameOrAfter(incident.fechaCreacion)){
				return incident
			}
		});
		this.setState({ incidencias: newBufferFiltered });
	}

	/**
	 * Reteamos los filtros al estado original
	 */
	resetFilterIncident = () => this.setState( state => ({
		incidencias: state.incidenciasBackup,
		allincidencias: false, 
		preSelected: [], 
		fecha_fin: state.incidenciasBackup.length > 0? moment(state.incidenciasBackup[ 0 ].fechaCreacion, "YYYY-MM-DD") : moment(),
		fecha_inicio: state.incidenciasBackup.length > 0? moment(state.incidenciasBackup[ state.incidenciasBackup.length - 1 ].fechaCreacion, "YYYY-MM-DD") : moment(),
	}) );

	render() {
		const {centro, partes, previas, finales, incidencias, preSelected, fotos, centros,
			 fecha_inicio, fecha_fin, previousState3Inside, previousState3Completed,
			 finalState4Inside, finalState4Completed} = this.state;
		// console.log("this.state InformeActuacion.js: ", this.state);

		return (
			<Segment raised>
				<Label ribbon content='Informe de actuación'/>
				<Step.Group ordered fluid>
					<Step completed={centro!=null} active={!centro}>
						<Step.Content>
							<Step.Title>Centro</Step.Title>
						</Step.Content>
					</Step>

					<Step completed={partes.length > 0} active={partes.length === 0}>
					<Step.Content>
						<Step.Title>Incidencias</Step.Title>
					</Step.Content>
					</Step>

					<Step completed={ previousState3Completed } active={ !previousState3Inside }>
						<Step.Content>
							<Step.Title>Estado previo</Step.Title>
						</Step.Content>
					</Step>

					<Step completed={ finalState4Completed } active={ !finalState4Inside }>
						<Step.Content>
							<Step.Title>Estado final</Step.Title>
						</Step.Content>
					</Step>
				</Step.Group>
				{!centro && <div> 
					<Header size='medium'>Seleccione el Centro<Header.Subheader> Se usará para filtrar las incidencias de la actuación</Header.Subheader> </Header>
					<Dropdown  selection search 
						name='centro' options={centros} value={centro} 
						onChange={this.handleCentroChange}/>
				</div>}
				{centro && partes.length === 0 && <div> 
					<Header size='medium'>
						Seleccione las incidencias
						<Header.Subheader> De las incidencias seleccionadas podrá elegir las fotos</Header.Subheader> 
						<MyDivContainerButtons>
								<MyButtonCancelAndNext cancel onClick={this.cancelar}>
									<Icon name="cancel"/>
									Cancelar
								</MyButtonCancelAndNext>
								<MyButtonCancelAndNext onClick={this.selectPartes}>
									<Icon name="arrow right"/>
									Siguiente
								</MyButtonCancelAndNext>
						</MyDivContainerButtons>

					</Header>

					<Form>
						<Form.Group width='equal'>
							<Form.Field
								control={DatePicker}
								dateFormat="DD/MM/YYYY"
								selectsStart
								selected={fecha_inicio}
								startDate={fecha_inicio}
								endDate={fecha_fin}
								// minDate={ moment(incidenciasBackup[ 0 ]?.fechaCreacion, "YYYY-MM-DD") } // TODO: analyze this case
								label='Fecha Inicial'
								onChange={this.handleInicio}
							/>
							<Form.Field
								control={DatePicker}
								dateFormat="DD/MM/YYYY"
								selectsEnd
								selected={fecha_fin}
								startDate={fecha_inicio}
								endDate={fecha_fin}
								// maxDate={ moment(incidenciasBackup[ incidenciasBackup.length - 1 ]?.fechaCreacion, "YYYY-MM-DD") }	// TODO: analyze this case
								label='Fecha Final'
								onChange={this.handleFin}
							 />
								<MyButtonCancel onClick={this.resetFilterIncident}>
									<Icon name='cancel' style={{ margin: 0 }}/>
								</MyButtonCancel>
						</Form.Group>
					</Form>

					{incidencias.length > 0 && <Checkbox label='Seleccionar todas' onChange={this.selectAllIncidencias} key='-1' checked={this.state.allincidencias} />}
					{_.map(incidencias,({id,descripcion}) => 
						<div key={id}><Checkbox label={'['+id+'] - '+descripcion.replace(/<br\/>/g,'\r\n')} onChange={this.select} 
						key={id} name={id} checked={preSelected.indexOf(""+id)!==-1} /></div>
					)}
				</div>}
				{centro && partes.length !== 0 && !previousState3Inside && previas.length ===0 &&<div> 
					<Header size='medium'>
						Seleccione las imagenes 
						<Header.Subheader> Estas imágenes se incluirán en la sección de estado inicial</Header.Subheader> 
						<MyDivContainerButtons>
								<MyButtonCancelAndNext cancel onClick={this.cancelar}>
									<Icon name="cancel"/>
									Cancelar
								</MyButtonCancelAndNext>
								<MyButtonCancelAndNext onClick={this.selectFotosPre}>
									<Icon name="arrow right"/>
									Siguiente
								</MyButtonCancelAndNext>
						</MyDivContainerButtons>
					</Header>
					{_.map(fotos,({id,name}) => 
						<div key={id} style={{margin:'10px'}}>
							<Checkbox label={name} onChange={this.select} 
								key={id} name={id} checked={preSelected.indexOf(""+id)!==-1}>
							</Checkbox>
							<Image size='medium' src={baseUrl+'/imageserver/filePartes?id='+id+'&download'} />
						</div>
					)}
				</div>}
				{centro && partes.length !== 0 && previousState3Inside && !finalState4Inside && finales.length ===0 &&<div> 
					<Header size='medium'>
						Seleccione las imagenes 
						<Header.Subheader> Estas imágenes se incluirán en la sección de estado final</Header.Subheader> 
						<MyDivContainerButtons>
								<MyButtonCancelAndNext cancel onClick={this.cancelar}>
									<Icon name="cancel"/>
									Cancelar
								</MyButtonCancelAndNext>
								<MyButtonCancelAndNext onClick={this.selectFotosPost}>
									<Icon name="arrow right"/>
									Siguiente
								</MyButtonCancelAndNext>
						</MyDivContainerButtons>
					</Header>
					{_.map(fotos,({id,name}) => 
						previas.indexOf(""+id) === -1 &&
							<div key={id} style={{margin:'10px'}}>
								<Checkbox label={name} onChange={this.select} 
									key={id} name={id} checked={preSelected.indexOf(""+id)!==-1}>
								</Checkbox>
								<Image size='medium' src={baseUrl+'/imageserver/filePartes?id='+id+'&download'} />
							</div>
					)}
				</div>}
				{centro && partes.length !== 0 && finalState4Inside && 
					<Header size='medium'>
						Todo seleccionado
						<Header.Subheader> Puse para generar el informe de actuación</Header.Subheader> 
						<MyDivContainerButtons>
								<MyButtonCancelAndNext cancel onClick={this.cancelar}>
									<Icon name="cancel"/>
									Cancelar
								</MyButtonCancelAndNext>
								<MyButtonCancelAndNext generate onClick={this.generar}>
									<Icon name="download"/>
									Generar
								</MyButtonCancelAndNext>
						</MyDivContainerButtons>
					</Header>
				}
			</Segment>
		);
  }
}

const MyButtonCancel = styled.button`
	border: 0 solid transparent;
	cursor: pointer;
	height: 25px;
	border-radius: 50%;
`;

const MyButtonCancelAndNext = styled.button`
	color: white;
	background-color: ${props => {
		if(props.generate){
			return "#2185d0";
		}
		return props.cancel? "#db2828": "#21ba45"} 
	};
	border: none;
	font-size: 14px;
	cursor: pointer;
	border-radius: 4px;
	padding: 5px 10px;
	font-family: Lato;
`;

const MyDivContainerButtons = styled.div`
	margin-top: 10px;
	display: flex;
	gap: 0px 10px;
`;

export default InformeActuacion;