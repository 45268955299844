import React, { Component } from 'react'
import {Button, Header, List, Modal, Icon, Form, Segment, Message } from 'semantic-ui-react'
import _ from 'lodash';
import {Storage} from './js/storage';
import axios from 'axios';
import {baseUrl, version} from './js/constdata';


class Legal extends Component {
    constructor(props) {
      super(props);
      this.state={modalOpen: false};
    }
    
    handleOpen = () => this.setState({ modalOpen: true })
    handleClose = () => this.setState({ modalOpen: false })
  
    render() {
      return (
        <Modal
          trigger={<List.Item as='a'>Legal</List.Item>}
          open={this.state.modalOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          textAlign='justified'
        >
          <Header icon='legal' content='AVISO LEGAL' />
          <Modal.Content textAlign='justified'>
            <p>Bienvenido a la página web <strong>gmanti.com</strong>.</p> 
            <p>Esta página web tiene por objeto facilitar al público en general la información relativa al producto
            Gmanti para gestión del mantenimiento de instalaciones. Las presentes disposiciones tienen por objeto la regulación del uso de esta página 
            web que se pone a disposición de los usuarios de Internet. La utilización de la página web atribuye la condición de usuario de la página
            web e implica la aceptación de todas las condiciones incluidas en este Aviso Legal.</p>
            <p> La prestación del servicio de la página web tiene una duración limitada al momento en el que el usuario se encuentre conectado a la página web
            o a alguno de los servicios que a través de la misma se facilitan. Por tanto, el usuario debe leer atentamente el presente Aviso Legal en cada una
            de las ocasiones en que se proponga utilizar la página web, ya que ésta y sus condiciones de uso recogidas en el presente Aviso Legal pueden sufrir 
            modificaciones. El contenido del presente Aviso Legal se rige por la ley común española.</p>
            
            <h4>1. GMANTI</h4>
            <p>En cumplimiento del deber de información estipulado en el artículo 10 de la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información
               y de Comercio Electrónico, Yeray Cabrera Santana en calidad de titular del web site <strong>gmanti.com</strong>, procede a comunicarles los datos 
               identificativos exigidos por la referida norma:</p>
            <p>Denominación social: Yeray Cabrera Santana  ||  Domicilio social: Calle León y Castillo 303 - 5º F. Las Palmas de Gran Canaria. CP. 35006.  ||  CIF: 
              78483529P  ||  Correo electrónico: info@gmanti.com</p>
            <p>La presente información conforma y regula las condiciones de uso, las limitaciones de responsabilidad y las obligaciones que, los usuarios de la 
              página Web que se publica bajo el nombre de dominio gmanti.com, asumen y se comprometen a respetar.</p>
  
            
            <h4>2. DERECHOS DE PROPIEDAD INDUSTRIAL E INTELECTUAL</h4>
            <p>Todos los derechos sobre el software de la página web así como sobre los derechos de propiedad industrial e intelectual referidos a los productos, 
            servicios y contenidos que en ella se incluyan son propiedad de Yeray Cabrera Santana. Esta página web puede contener referencias a marcas comerciales
            o marcas registradas de Yeray Cabrera Santana. Queda prohibido su uso sin el consentimiento previo y expreso de Yeray Cabrera Santana. Todas las demás
            referencias a otras marcas comerciales o registradas pertenecen a sus respectivos propietarios, sin que pueda entenderse que el acceso a la página web
            atribuya derecho alguno sobre las mismas.</p>
            <p>Cualquier persona está autorizada para ver, copiar, imprimir y distribuir cualquier documento publicado por 
            Gmanti en esta página web en tanto que: (i) dicho documento sea utilizado exclusivamente con fines informativos; (ii) dicho documento no sea utilizado 
            con fines comerciales; y (iii) dicho documento incluya siempre una referencia a que es propiedad de Gmanti. Los materiales de esta página web están 
            protegidos por derechos de autor y cualquier uso no autorizado de los mismos puede violar la normativa sobre derechos de autor, marcas y otras regulaciones.
            La autorización concedida para utilizar esta página web se entenderá automáticamente terminada en caso de infringir cualquiera de estas condiciones, 
            estando obligado a destruir inmediatamente cualquier material obtenido o impreso de la página web. </p>
            <p>Yeray Cabrera Santana no concede ninguna licencia o 
            autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual, secretos empresariales o sobre cualquier otra propiedad 
            o derecho relacionado con la página web y sus productos, servicios y contenidos.</p>
  
  
  
            <h4>3. POLÍTICA DE  DATOS</h4>
            <p>De conformidad con lo establecido en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016,le informamos 
              que sus datos serán incorporados al sistema de tratamiento titularidad de la aplicación web gmanti.com es operada por Yeray Cabrera Santana (en lo sucesivo, "YCS").</p>
            <p>La confianza es la base de nuestra empresa. YCS cree que la confianza de nuestros clientes en la protección de datos 
              corporativos y personales (protección de datos) es invaluable. Por ello, YCS cumple con todas las leyes y normativa de 
              protección de datos relevantes y constantemente se esfuerza por mejorar la protección de datos.</p>
            <p>Esta política de protección de datos le informa sobre el procesamiento de sus datos personales en relación con el sitio web gmanti.com.</p>
            <p>Tabla de contenidos</p>
            <ol>
              <li>Responsable del tratamiento</li>
              <li>¿Qué son datos personales?</li>
              <li>¿Qué datos personales se tratan?</li>
              <li>¿Cuándo tratamos sus datos?</li>
            </ol>
            <p><strong>RESPONSABLE DEL TRATAMIENTO</strong></p>
            <p>La persona responsable del tratamiento de sus datos personales es Yeray Cabrera Santana. (en adelante, "YCS"), con domicilio social en 
              León y Castillo 303, 35006 de Las Palmas de Gran Canaria y NIF: 78483529P.YCS se preocupa por el cumplimiento de la normativa de protección
              de datos, y al que puede contactar en la dirección: info@gmanti.com.</p>
            <p><strong>¿QUÉ SON LOS DATOS PERSONALES?</strong></p>
            <p>La información personal es información que se relaciona con una persona física identificada o identificable, como su nombre, dirección de 
              correo electrónico, número de teléfono, dirección postal o la dirección IP de su ordenador.</p>
            <p><strong>¿QUÉ DATOS PERSONALES SE TRATAN?</strong></p>
            <p>gmanti solo almacena los datos personales que usted proporciona y se utilizan para el propio servicio de mantenimiento. Concretamente 
              se guardan el nombre, apellidos y correo electrónico de los usuarios</p>
            <p><strong>¿CUÁNDO TRATAMOS SUS DATOS?</strong></p>
            <p>gmanti solo almacena los datos personales que usted proporciona y se utilizan para el propio servicio de mantenimiento. Concretamente 
              se guardan el nombre, apellidos y correo electrónico de los usuarios</p>
            <p>Tratamos sus datos personales cuando usted crea su cuenta de usuario y/o proporciona los datos de su cuenta para empresa.</p>
            <p>A menos que se indique lo contrario en las siguientes disposiciones, la base legal para este procesamiento de datos es el art. 6.1 b) del RGPD (ejecución de un contrato).</p>

            <h4>4. EMPLEO DE COOKIES</h4>
            <p>gmanti.com puede utilizar cookies para lograr una mayor efectividad y eficacia en la prestación de sus servicios. Las ventajas que conlleva la aceptación 
            de tales cookies se traduce en un ahorro de tiempo. Mediante el uso de las cookies el usuario no tendrá que verificar el proceso de registro con carácter 
            reiterativo, con lo que se personalizarán los servicios facilitando su navegación por nuestro sitio web. Aún cuando el usuario configurase su navegador 
            al objeto de rechazar la descarga de todos los ficheros de cookies o rechazase expresamente las cookies de gmanti.com, el usuario podría navegar en nuestro 
            sitio web, si bien no podrá participar en todos y cada uno de los servicios ofrecidos que requieran la instalación de alguna de ellas. Concretamente, el 
            usuario no podría utilizar los servicios para los que sean precisos el registro y la autentificación. Las cookies que gmanti.com descargue podrán ser empleadas 
            para (i) conservar los resultados de la última búsqueda; (ii) guardar los permisos de los usuarios que han sido autentificados; y (iii) medir algunos 
            parámetros de tráfico de red, las áreas de la web de Gmanti más visitadas.</p>
  
  
            <h4>5. CONDICIONES DE USO</h4>
            <p>La simple y mera utilización de la Página otorga la condición de usuario de la Página, bien sea persona física o jurídica, y obligatoriamente implica 
              la aceptación completa, plena y sin reservas de todas y cada una de las cláusulas y condiciones generales incluidas en el Aviso Legal. Si el Usuario 
              no estuviera conforme con las cláusulas y condiciones de uso de este Aviso Legal, se abstendrá de utilizar la Página.</p>
            <p>Este Aviso Legal está sujeto a cambios y actualizaciones por lo que la versión publicada por Yeray cabrera Santana puede ser diferente en cada momento 
              en que el Usuario acceda al Portal. Por tanto, el Usuario debe leer el Aviso Legal en todas y cada una de las ocasiones en que acceda a la Página.</p>
            <p>A través de la Página, Yeray Cabrera Santana facilita a los Usuarios el acceso y la utilización de diversos Contenidos publicados por medio de Internet
              por Yeray Cabrera Santana o por terceros autorizados. El Usuario esta obligado y se compromete a utilizar la Página y los Contenidos de conformidad con 
              la legislación vigente, el Aviso Legal, y cualquier otro aviso o instrucciones puestos en su conocimiento, bien sea por medio de este aviso legal o en 
              cualquier otro lugar dentro de los Contenidos que conforman la Página, así como con las normas de convivencia, la moral y buenas costumbres generalmente 
              aceptadas.</p>
            <p>A tal efecto, el Usuario se obliga y compromete a NO utilizar cualquiera de los Contenidos con fines o efectos ilícitos, prohibidos en el Aviso Legal o 
              por la legislación vigente, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o 
              impedir la normal utilización de los Contenidos, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en cualquier 
              equipo informático propios o contratados por Yeray Cabrera Santana, de otros Usuarios o de cualquier usuario de Internet (hardware y software).</p>
            <p>El Usuario se obliga y se compromete a no transmitir, difundir o poner a disposición de terceros cualquier clase de material contenido en la Página, 
              tales como informaciones, textos, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software, 
              logotipos, marcas, iconos, tecnología, fotografías, software, enlaces, diseño gráfico y códigos fuente, o cualquier otro material al que tuviera acceso 
              en su condición de Usuario de la Página, sin que esta enumeración tenga carácter limitativo. Asimismo, de conformidad con todo ello, el Usuario no podrá:</p>
              <ul> 
                  <li>Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los Contenidos, 
                    a menos que se cuente con la autorización escrita y explícita de tu denominación social, que es titular de los correspondientes derechos, o bien 
                    que ello resulte legalmente permitido.</li>
                  <li>Suprimir, manipular o de cualquier forma alterar el “copyright” y demás datos identificativos de la reserva de derechos de tu denominación social
                    o de sus titulares, de las huellas y/o identificadores digitales, o de cualesquiera otros medios técnicos establecidos para su reconocimiento.</li>
              </ul>
            <p>El Usuario deberá abstenerse de obtener e incluso de intentar obtener los Contenidos empleando para ello medios o procedimientos distintos de los que, 
              según los casos, se hayan puesto a su disposición a este efecto o se hayan indicado a este efecto en las páginas Web donde se encuentren los Contenidos 
              o, en general, de los que se empleen habitualmente en Internet a este efecto siempre que no entrañen un riesgo de daño o inutilización de la Página, y/o 
              de los Contenidos.</p>
            <p><strong>HIPERENLACES</strong></p>
            <p>Aquellas personas que se propongan establecer hiperenlaces entre su página Web y la Página deberán observar y cumplir las condiciones siguientes:</p>
              <ul>
                  <li>No será necesaria autorización previa cuando el Hiperenlace permita únicamente el acceso a la página de inicio, pero no podrá reproducirla de 
                    ninguna forma. Cualquier otra forma de Hiperenlace requerirá la autorización expresa e inequívoca por escrito por parte de Yeray Cabrera Santana</li>
                  <li>No se crearán “marcos” (“frames”) con las páginas Web ni sobre las páginas Web de gmanti.com</li>
                  <li>No se realizarán manifestaciones o indicaciones falsas, inexactas, u ofensivas sobre Yeray Cabrera Santana, sus empleados o colaboradores, o 
                    de las personas que se relacionen en la Página por cualquier motivo, o de los Usuarios de las Página, o de los Contenidos suministrados.</li>
                  <li>No se declarará ni se dará a entender que Yeray Cabrera Santana ha autorizado el Hiperenlace o que ha supervisado o asumido de cualquier forma 
                    los Contenidos ofrecidos o puestos a disposición de la página Web en la que se establece el Hiperenlace.</li>
                  <li>La página Web en la que se establezca el Hiperenlace solo podrá contener lo estrictamente necesario para identificar el destino del Hiperenlace.</li>
                  <li>La página Web en la que se establezca el Hiperenlace no contendrá informaciones o contenidos ilícitos, contrarios a la moral y a las buenas 
                    costumbres generalmente aceptadas y al orden público, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros.</li>
              </ul>
            <p><strong>DISPONIBILIDAD DE LA PÁGINA</strong></p>
            <p>Yeray Cabrera Santana no garantiza la inexistencia de interrupciones o errores en el acceso a la Página, a sus Contenidos, ni que éste se encuentren 
              actualizados, aunque desarrollará sus mejores esfuerzos para, en su caso, evitarlos, subsanarlos o actualizarlos. Por consiguiente, Yeray Cabrera Santana no 
              se responsabiliza de los daños o perjuicios de cualquier tipo producidos en el Usuario que traigan causa de fallos o desconexiones en las redes de 
              telecomunicaciones que produzcan la suspensión, cancelación o interrupción del servicio del Portal durante la prestación del mismo o con carácter previo.</p>
            <p>Yeray Cabrera Santana excluye, con las excepciones contempladas en la legislación vigente, cualquier responsabilidad por los daños y perjuicios de toda 
              naturaleza que puedan deberse a la falta de disponibilidad, continuidad o calidad del funcionamiento de la Página y de los Contenidos, al no cumplimiento 
              de la expectativa de utilidad que los usuarios hubieren podido atribuir a la Página y a los Contenidos.</p>
            <p>La función de los Hiperenlaces que aparecen en esta Web es exclusivamente la de informar al usuario acerca de la existencia de otras Web que contienen 
              información sobre la materia. Dichos Hiperenlaces no constituyen sugerencia ni recomendación alguna. Yeray Cabrera Santana no se hace responsable de los 
              contenidos de dichas páginas enlazadas, del funcionamiento o utilidad de los Hiperenlaces ni del resultado de dichos enlaces, ni garantiza la ausencia 
              de virus u otros elementos en los mismos que puedan producir alteraciones en el sistema informático (hardware y software), los documentos o los ficheros 
              del usuario, excluyendo cualquier responsabilidad por los daños de cualquier clase causados al usuario por este motivo.</p>
            <p>El acceso a la Página no implica la obligación por parte de Yeray Cabrera Santana de controlar la ausencia de virus, gusanos o cualquier otro elemento 
              informático dañino. Corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y desinfección de programas 
              informáticos dañinos, por lo tanto, Yeray Cabrera Santana no se hace responsable de los posibles errores de seguridad que se puedan producir durante la 
              prestación del servicio de la Página, ni de los posibles daños que puedan causarse al sistema informático del usuario o de terceros (hardware y software), 
              los ficheros o documentos almacenados en el mismo, como consecuencia de la presencia de virus en el ordenador del usuario utilizado para la conexión a los 
              servicios y contenidos de la Web, de un mal funcionamiento del navegador o del uso de versiones no actualizadas del mismo.</p>
            <p><strong>CALIDAD DE LA PÁGINA</strong></p>
            <p>Dado el entorno dinámico y cambiante de la información y servicios que se suministran por medio de la Página, Yeray Cabrera Santana realiza su mejor 
              esfuerzo, pero no garantiza la completa veracidad, exactitud, fiabilidad, utilidad y/o actualidad de los Contenidos. La información contenida en las 
              páginas que componen este Portal sólo tiene carácter informativo, consultivo, divulgativo y publicitario. En ningún caso ofrecen ni tienen carácter de 
              compromiso vinculante o contractual.</p>
            <p><strong>LIMITACIÓN DE RESPONSABILIDAD</strong></p>
            <p>Yeray Cabrera Santana excluye toda responsabilidad por las decisiones que el Usuario pueda tomar basado en esta información, así como por los posibles 
              errores tipográficos que puedan contener los documentos y gráficos de la Página. La información está sometida a posibles cambios periódicos sin previo 
              aviso de su contenido por ampliación, mejora, corrección o actualización de los Contenidos.</p>
            <p><strong>NOTIFICACIONES</strong></p>
            <p>Todas las notificaciones y comunicaciones por parte de Yeray Cabrera Santana al Usuario realizados por cualquier medio se considerarán eficaces a 
              todos los efectos.</p>
            <p><strong>DISPONIBILIDAD DE LOS CONTENIDOS</strong></p>
            <p>La prestación del servicio de la Página y de los Contenidos tiene, en principio, duración indefinida. Yeray Cabrera Santana, no obstante, queda 
              autorizada para dar por terminada o suspender la prestación del servicio de la Página y/o de cualquiera de los Contenidos en cualquier momento. Cuando 
              ello sea razonablemente posible, Yeray Cabrera Santana advertirá previamente la terminación o suspensión de la Página.</p>
            <p><strong>JURISDICCIÓN</strong></p>
            <p>Para cuantas cuestiones se susciten sobre la interpretación, aplicación y cumplimiento de este Aviso Legal, así como de las reclamaciones que puedan 
              derivarse de su uso, todos las partes intervinientes se someten a los Jueces y Tribunales de Las Palmas de Gran Canaria renunciando de forma expresa a 
              cualquier otro fuero que pudiera corresponderles.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' onClick={this.handleClose} inverted>
              <Icon name='checkmark' /> OK
            </Button>
          </Modal.Actions>
        </Modal>
      )
    }
}
class Cookies extends Component {
    constructor(props) {
      super(props);
      this.state={modalOpen: false};
    }
    
    handleOpen = () => this.setState({ modalOpen: true })
    handleClose = () => this.setState({ modalOpen: false })
  
    render() {
      return (
        <Modal
          trigger={<List.Item as='a'>Cookies</List.Item>}
          open={this.state.modalOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
        >
          <Header icon='legal' content='POLÍTICA DE COOKIES' />
          <Modal.Content>
          <p>Cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web.</p>
          <p>Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o 
            de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
          <p>El navegador del usuario memoriza cookies en el disco duro solamente durante la sesión actual ocupando un espacio de memoria mínimo y no perjudicando 
            al ordenador. Las cookies no contienen ninguna clase de información personal específica, y la mayoría de las mismas se borran del disco duro al finalizar 
            la sesión de navegador (las denominadas cookies de sesión)</p>
          <p>Sin su expreso consentimiento –mediante la activación de las cookies en su navegador– gmanti.com no enlazará en las cookies los datos memorizados 
            con sus datos personales proporcionados en el momento del registro o la compra.</p>
          
          <h4>¿Qué tipos de cookies utiliza este sitio web?</h4>
          <p><strong>Cookies técnicas</strong>: Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de 
            las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, 
            acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la 
            solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión 
            de videos o sonido o compartir contenidos a través de redes sociales.</p>
          <p><strong>Cookies de terceros</strong>: La Web de gmanti.com puede utilizar servicios de terceros que, por cuenta de gmanti.com, recopilaran información con fines 
            estadísticos, de uso del sitio web por parte del usuario y para la prestacion de otros servicios relacionados con la actividad del Website y 
            otros servicios de Internet.</p>
          
          <p>El Usuario acepta expresamente, por la utilización de este sitio web, el tratamiento de la información recabada en la forma y con los fines anteriormente 
            mencionados. Y asimismo reconoce conocer la posibilidad de rechazar el tratamiento de tales datos o información rechazando el uso de Cookies mediante la selección
            de la configuración apropiada a tal fin en su navegador. Si bien esta opción de bloqueo de Cookies en su navegador puede no permitirle el uso pleno de todas 
            las funcionalidades del Website.</p>
          <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
            <ul>
              <li><a href="http://www.google.es/chrome/browser" target='blank'>Chrome</a></li>
              <li><a href="https://www.microsoft.com/es-es/download/internet-explorer.aspx" target='blank'>Internet Explorer</a></li>
              <li><a href="https://www.mozilla.org/es-ES/firefox/" target='blank'>Firefox</a></li>
              <li><a href="https://www.apple.com/es/safari/" target='blank'>Safari</a></li>
            </ul>  
          <p>Si tiene dudas sobre esta política de cookies, puede contactar con info@gmanti.com</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' onClick={this.handleClose} inverted>
              <Icon name='checkmark' /> OK
            </Button>
          </Modal.Actions>
        </Modal>
      )
    }
}  
class Contacto extends Component {
    constructor(props) {
      super(props);
      var user = Storage.getStorage('user');
      this.state={
            nombre:user.nombre, telefono:"", email:"", mensaje:"", modalOpen: false, enviado:false
        };
    }
    
    handleOpen = () => this.setState({ modalOpen: true })
    handleClose = () => this.setState({ modalOpen: false })
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    sendContacto = () =>{
        var datos = _.clone(this.state);
        datos.empresa = JSON.stringify(Storage.getStorage('user'));
        if (!datos.nombre) datos.nombre='vacio';
        if (!datos.email) datos.email='vacio';        
        axios({
            method: 'POST',
            url: baseUrl+'/utils/sendmail.php',
            data: JSON.stringify(datos),
            withCredentials: true
          }).then(response => {
            this.setState({nombre:"", telefono:"", email:"", enviado:true, mensaje:""});
            this.handleClose();
          });
    }
    render() {
        const { nombre, telefono, email, mensaje, modalOpen, enviado } = this.state
        return (
        <Modal
          trigger={<List.Item as='a'>Contacto</List.Item>}
          open={modalOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
        >
          <Header icon='mail outline' content='Envío de mensaje' />
          <Modal.Content>
          <Segment vertical style={{paddingBottom: "30px"}}>
                        <p style={{ fontSize: '1.2em' }}>
                        Escribanos un mensaje y nos pondremos en contacto con usted.
                        </p>
                        <Form onSubmit={this.sendContacto}>
                          <Form.Input label='Nombre' name="nombre" value={nombre} onChange={this.handleChange}/>
                          <Form.Input label='Teléfono' name="telefono" value={telefono} onChange={this.handleChange}/>
                          <Form.Input label='E-mail' name="email" value={email} onChange={this.handleChange}/>
                          <Form.TextArea label='Mensaje' name="mensaje" value={mensaje} onChange={this.handleChange}/>                          
                          {enviado && <Message positive        
                            header='Mensaje enviado'
                            content='En breve nos pondremos en contacto con usted.'
                          />}  
                          <Form.Button floated='right'>Enviar mensaje</Form.Button>
                      </Form>
              </Segment>
          </Modal.Content>
        </Modal>
      )
    }
}

class Versiones extends Component {
  constructor(props) {
    super(props);
    this.state={modalOpen: false};
  }
  
  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })

  render() {
    return (
      <Modal
        trigger={<List.Item as='a'>v.{version}</List.Item>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        textAlign='justified'
      >
        <Header icon='tags' content='Resumen de versiones' />
        <Modal.Content textAlign='justified'>
        <h3>2.0.12</h3>
            <ul>
              <li>[Corrección] Confirmar certificciones</li>
              <li>[Corrección] Borrar partes vacíos</li>
            </ul>
          <h3>2.0.11</h3>
            <ul>
              <li>[Corrección] Búsqueda en servidor con filtro de texto</li>
              <li>Cierre de empresa y sus partes</li>
            </ul>
          <h3>2.0.10</h3>
            <ul>
              <li>[Corrección] Repetición de centros al borrar filtros</li>
              <li>[Corrección] Recuento erróneo de incidencias en Home</li>
              <li>Tooltip en informes con PEC y PEM</li>
            </ul>
          <h3>2.0.9</h3>
            <ul>
              <li>Generación de carátulas por empresa de servicio</li>
            </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.handleClose} inverted>
            <Icon name='checkmark' /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
export {Legal, Cookies, Contacto, Versiones}