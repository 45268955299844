import _ from 'lodash';
import React, { Component } from 'react';
import {Confirm, Item, Grid, Header,Segment, Label, Popup, Modal, Button, Form, Dropdown, Statistic, Checkbox} from 'semantic-ui-react';
import {Storage, StorageUtils} from './js/storage';
import {Link } from 'react-router-dom';
import {IO} from './js/IO'
import {CambioEstado} from './DetalleIncidencia';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';

const getItemStyle = (draggableStyle, isDragging) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: 10,
	margin: `4px 4px 4px 4px`,
	border: '1px solid',
	// change background colour if dragging
	background: isDragging ? 'lightgreen' : 'none',
	width: 200,
	// styles we need to apply on draggables
	...draggableStyle,
});
const getVerticalListStyle = isDraggingOver => ({
	background: isDraggingOver ? 'lightblue' : 'none',
	width: '100%',
	minHeight: '300px'
});
const getHorizontalListStyle = isDraggingOver => ({
	background: isDraggingOver ? 'lightblue' : 'none',
	padding: 8,
	display: 'flex',
	minHeight: '80px',
	minWidth: '300px'
});

class HomeServicio extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			usuario: Storage.getStorage('user'),
			todas:0, resueltas:0, partes:0,
			agrupadas: {null:[]},  grupos:[],
			open_confirm: false
		};
	 };
	openDeleteGroup = (e,{name}) => this.setState({open_confirm:true, group2delete:name})
	goUpGroup = (e,{name}) => {
		IO.axios({url:'/data/homeservicio.php/moveup',method:'POST',data: JSON.stringify({grupo:name})})
		.then(response => { 
			if (response.data.error==0){ this.getData();}	
		});
	};
	goDownGroup = (e,{name}) => {
		IO.axios({url:'/data/homeservicio.php/movedown',method:'POST',data: JSON.stringify({grupo:name})})
		.then(response => { 
			if (response.data.error==0){ this.getData();}	
		});
	};
	
	closeDeleteGroup = () => this.setState({open_confirm:false, group2delete:null})
	deleteGroup = (e,{name}) => {
		IO.axios.delete('/data/homeservicio.php/'+this.state.group2delete )
		.then(response => {
				this.getData();
				this.closeDeleteGroup();			
		});
	 }
	getData = () =>{
		IO.axios.get('/data/homeservicio' )
		.then(response => {
			var todas =  response.data.todas;
			var grupos = response.data.grupos;
			var agrupadas = _.groupBy(todas,(a) => a.grupo_id);
			_.map(grupos,(g) => {
				if(!agrupadas[g.id]){
					agrupadas[g.id] = [];
				}
			})
			this.setState({grupos, agrupadas, todas:todas.length, partes:response.data.partes, resueltas:response.data.resueltas});
		});
	 }
	componentDidMount() {
		this.getData();
	 }
	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
		  return;
		}
	
		IO.axios({
			url:'/data/homeservicio.php/incidencia', 
			method: 'POST',
			data: JSON.stringify({incidencia:result.draggableId, grupo:result.destination.droppableId,
								orden:result.destination.index+1})})
			.then(response => {
				if (response.data.error==0){
					const agrupadas = this.state.agrupadas;
					const source = agrupadas[result.source.droppableId]
					const [removed] = source.splice(result.source.index, 1);
					const destination = agrupadas[result.destination.droppableId]
					destination.splice(result.destination.index, 0, removed);
					this.setState({agrupadas});	
					return;
				}
		});
	  }
	checkEjecucion = (e,{name, group}) => {
		IO.axios({
			url:'/data/homeservicio.php/ejecucion', 
			method: 'POST',
			data: JSON.stringify({incidencia:name})})
			.then(response => {
					const agrupadas = this.state.agrupadas;
					const source = agrupadas[group];
					var elem = _.find(source,{id:name});
					elem.ejecucion= elem.ejecucion==="0" ? "1" : "0";
					this.setState({agrupadas});	
					return;
		});
		e.preventDefault();
	}
	printOrganizacion = () => {
		IO.axios.get('/data/exportacion', {
			params: {tipo: 'organizacion'},
			responseType:'arraybuffer'
		}).then(response => {
			let blob = new Blob([response.data], { type:   'application/csv' } )
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'Organizacion'+moment().format('YYYYMMDD')+'.csv'
			link.click()
		});
	}
	render() {
		return (
				<div>
					<Statistic.Group widths='four'>
						<Statistic>
							<Statistic.Value>{this.state.todas}</Statistic.Value>
							<Statistic.Label>Incidencias sin Resolver</Statistic.Label>
						</Statistic>
						<Statistic>
							<Statistic.Value>{this.state.agrupadas['null'] ? this.state.agrupadas['null'].length : 0}</Statistic.Value>
							<Statistic.Label>Incidencias sin Asignar</Statistic.Label>
						</Statistic>
						<Statistic>
							<Statistic.Value>{this.state.resueltas}</Statistic.Value>
							<Statistic.Label>Incidencias sin valorar</Statistic.Label>
						</Statistic>
						<Statistic>
							<Statistic.Value>{this.state.partes}</Statistic.Value>
							<Statistic.Label>Partes sin certificar</Statistic.Label>
						</Statistic>
					</Statistic.Group>
					<Segment raised>
						<Confirm open={this.state.open_confirm} name='open_confirm'
							content='¿Seguro que desa eliminar el grupo de trabajo?'
							onCancel={this.closeDeleteGroup} onConfirm={this.deleteGroup}/>

						<Label attached='top'>Organización de incidencias 
							{this.state.usuario.roles.SERVICIO && 
								<span>
									<CreateGrupoTrabajo update={this.getData}/>
									<Button circular icon='download' size='mini' floated='right' color='grey' onClick={this.printOrganizacion}/>
								</span>} 
						</Label>
						<DragDropContext onDragEnd={this.state.usuario.roles.SERVICIO ? this.onDragEnd : () => {}} >
							<Grid>						
								<Grid.Row>
									<Grid.Column width={3}>
										<Droppable droppableId="null">
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												style={getVerticalListStyle(snapshot.isDraggingOver)}
												>
												{_.map(this.state.agrupadas['null'], ({ id, prioridad, centro, descripcion}) => (
													<Draggable key={id} draggableId={id}>
													{(provided, snapshot) => (
														<div>
															<div ref={provided.innerRef} style={getItemStyle(provided.draggableStyle,snapshot.isDragging)}
																{...provided.dragHandleProps}
															>	
															<Item key={id}>
															<Item.Content as={Link} to={'/incidencias/'+id}>
																<Item.Header size='mini'>
																	<Popup 	trigger={
																				<Header color={prioridad==="URGENTE" ? 'red' : prioridad==="PRIORITARIA" ? 'yellow' : 'grey'}>
																					{id} {this.state.usuario.roles.SERVICIO && 
																								<CambioEstado  style={{marginTop:'5px'}} void content='resolver' floated='right' icon='checkmark' color='orange' update={this.getData} incidencia={id}/>	
																							}
																					<Header.Subheader>{centro.replace("Complejo deportivo ","").replace("Parque deportivo ","").replace("Polideportivo ","").replace("Pabellón ","").replace("Campo de fútbol ","")
																										.replace("Pista deportiva ","")}</Header.Subheader>
																				</Header>}
																			content={<div dangerouslySetInnerHTML={{ __html: descripcion }} />} />		
																</Item.Header>
															</Item.Content>
															</Item>
															</div>
															{provided.placeholder}
														</div>
													)}
													</Draggable>
												))}					 
											</div>
										)}
										</Droppable>
									</Grid.Column>
									<Grid.Column width={13}>
									{_.map(this.state.grupos,(value) => 
										<div key={value.id}> 
										<Segment raised style={{marginBottom:'15px', overflow:'auto'}}>
											<Label attached='top'>
												{this.state.usuario.roles.SERVICIO && <Label  icon='arrow up' content='Subir' color='blue' size='mini' as={Button} name={value.id} onClick={this.goUpGroup}/> }
												{this.state.usuario.roles.SERVICIO && <Label  icon='arrow down' content='Bajar' color='blue' size='mini' as={Button} name={value.id} onClick={this.goDownGroup}/> }
												{_.filter(this.state.grupos,{id:value.id})[0].nombre && _.filter(this.state.grupos,{id:value.id})[0].nombre + ' - ' }
												{_.filter(this.state.grupos,{id:value.id})[0].uno && _.filter(this.state.grupos,{id:value.id})[0].uno + ' - '}
												{_.filter(this.state.grupos,{id:value.id})[0].dos && _.filter(this.state.grupos,{id:value.id})[0].dos + ' - '}
												{_.filter(this.state.grupos,{id:value.id})[0].tres && _.filter(this.state.grupos,{id:value.id})[0].tres + ' - '}
												{_.filter(this.state.grupos,{id:value.id})[0].cuatro && _.filter(this.state.grupos,{id:value.id})[0].cuatro + ' - '}
												{this.state.usuario.roles.SERVICIO && <Label  icon='trash' content='Eliminar' size='mini' color='red' as={Button} floated='right' name={value.id} onClick={this.openDeleteGroup}/> }
											</Label>
										<Droppable droppableId={value.id} direction="horizontal"  >
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												style={getHorizontalListStyle(snapshot.isDraggingOver)}>
												
												{_.map(this.state.agrupadas[value.id], ({ id, prioridad, centro, descripcion, ejecucion }) => (
													<Draggable key={id} draggableId={id}>
													{(provided, snapshot) => (
															<div ref={provided.innerRef}
																 style={getItemStyle(provided.draggableStyle,snapshot.isDragging)}
																 {...provided.dragHandleProps}>	
															<Item key={id}>
															<Item.Content as={Link} to={'/incidencias/'+id}>
																<Item.Header size='mini'>
																	<Popup 	trigger={
																				<Header color={prioridad==="URGENTE" ? 'red' : prioridad==="PRIORITARIA" ? 'yellow' : 'grey'}>
																					{id} {this.state.usuario.roles.SERVICIO && 
																								<CambioEstado  style={{marginTop:'5px'}} void content='resolver' floated='right' icon='checkmark' color='orange' update={this.getData} incidencia={id}/>	
																							}
																					<Header.Subheader>{centro.replace("Complejo deportivo ","").replace("Parque deportivo ","").replace("Polideportivo ","").replace("Pabellón ","").replace("Campo de fútbol ","")
																										.replace("Pista deportiva ","")}</Header.Subheader>
																				</Header>}
																			content={<div dangerouslySetInnerHTML={{ __html: descripcion }} />} />		
																</Item.Header>
																<Item.Extra> <Checkbox onChange={this.checkEjecucion} name={id} group={value.id} toggle label="Ejecución" style={{marginTop:'5px'}} checked={ejecucion==="1"}/></Item.Extra>
															</Item.Content>
															</Item>
															</div>
													)}
													</Draggable>
												))}					 
											</div>
										)}
										</Droppable>
										</Segment>
										</div>
									)}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</DragDropContext>
					</Segment>
				</div>
		);
  }
}

export default HomeServicio;

class CreateGrupoTrabajo extends Component {
	constructor(props) {
		  super(props);
		  StorageUtils.loadUsers().then((val) =>{
			this.opciones = Storage.getStorage('operarios');
			this.opciones = _.concat([{key:null, text:'', value:null}], this.opciones);  
		  });
		  this.state = {
			  operario1: null, operario2: null, operario3: null, operario4: null, nombre: null
		  };
		  
	}	  
	handleChange = (e, { name, value }) => this.setState({ [name]: value })
	handleDateChange = (date)  => this.setState({fecha: date})	
	resetComponent = () => this.setState({operario1: null, operario2: null, operario3: null, operario4: null, nombre: null, open:false})
	createGroup = () => {
		var allFields = JSON.stringify(this.state);
		IO.axios({
			url:'/data/homeservicio.php/grupo', 
			method: 'POST',
			data: allFields})
			.then(response => {
					this.props.update();	
					this.resetComponent();
		});
	 }
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	render() {
	  const { open, operario1, operario2, operario3, operario4,} = this.state
	  return (
		<Modal
		  open={open} onOpen={this.open} onClose={this.close} size='small'
		  trigger={<Button circular icon='plus' size='mini' color='green' floated='right' />}>
		  <Modal.Header>Nuevo grupo de trabajo</Modal.Header>
		  <Modal.Content>
				<Form >
					<Form.Input label='Nombre' name='nombre' onChange={this.handleChange}/>
					<Form.Field control={Dropdown} label='Operario 1' name='operario1' onChange={this.handleChange} 	
								fluid selection options={this.opciones} value={operario1}/> 
					<Form.Field control={Dropdown} label='Operario 2' name='operario2' onChange={this.handleChange} 	
								fluid selection options={this.opciones} value={operario2}/> 
					<Form.Field control={Dropdown} label='Operario 3' name='operario3' onChange={this.handleChange} 	
								fluid selection options={this.opciones} value={operario3}/> 
					<Form.Field control={Dropdown} label='Operario 4' name='operario4' onChange={this.handleChange} 	
								fluid selection options={this.opciones} value={operario4}/> 
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.createGroup} />
					  <Button  content='Cancelar' onClick={this.resetComponent} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}