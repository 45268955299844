import _ from 'lodash'
import React, { Component } from 'react';
import {Message, List, Confirm, Search, Input, Modal, Form, Dropdown, TextArea, Table, Accordion, Feed, Segment, Divider, Container, Header, Label, Item, Button, Menu, Grid, Popup} from 'semantic-ui-react';
import {IO} from './js/IO'
import {Link } from 'react-router-dom'
import {ListaArchivos,SubirArchivo} from './ListaArchivos'
import {oficios} from './js/constdata'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {Storage} from './js/storage';
import {Validations} from './js/validations'
import NumberFormat from 'react-number-format';
import { YCSFormFieldOficios } from './YCSComponents/YCSFormFieldOficios';

class DetallePedido extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			pedido : {},
			activeItem: 'detalles',
			archivos: [] ,
			activeIndex: 0,
			open_parte: false,
			pem: 0,
			pec: 0
		};
	};
	handleItemClick = (e, { name }) => this.setState({ activeItem: name })
	imprimirPedido = () => {
		//TODO BE
		const location = "/utils/printPedido.php?id="+this.state.pedido.id;
		const name = this.state.pedido.id+'.pdf'
		IO.axios({url:location, responseType:'arraybuffer'})
		.then(function(response) {
				let blob = new Blob([response.data], { type:   'application/pdf' } )
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = name
				link.click()
			});	
	}
	getData = () => {		
		IO.axios.get('/imageserver/filePedidos',{
				params: {pedido: this.props.match.params['Id']}
			}).then(response => {
				 this.setState({ archivos: response.data.files, loading_files: false });
		 	});
		IO.axios.get('/data/pedidos.php/'+this.props.match.params['Id'] ).then(response => {
				let pec= _.reduce(response.data[0].operaciones[0].precios, (x,y) => x+ (parseFloat(y.total)*(1-parseFloat(y.factor)/100)),0);
				const empresas = Storage.getStorage("empresas_menu");
				const igic = parseFloat(empresas.find(item => item.id === Storage.getStorage("user").empresa).igic);
				pec = pec + (pec * igic / 100)

				this.setState({ 
					pedido: response.data[0], 
					pem: _.reduce(response.data[0].operaciones[0].precios, (x,y) => x+ parseFloat(y.total),0),
					pec
				});
				if (!this.state.empresa){
					IO.axios.get('/data/empresa.php/'+Storage.getStorage('user').empresa).then(response => {
						this.setState({ 
							empresa: response.data[0]
						});
					});
				}
			});
		IO.axios.get('/data/partes_utils').then(response => {
					this.setState({ topfive: response.data });
			});
	}  
	componentDidMount() {this.getData();}
	handleCancel = (e, {name}) => this.setState({ [name]: false })
	handleOpen = (e, {name}) => this.setState({ [name]: true })

	handleDeletePrecio = (e, {name, parte, codigo, precio}) => {
		this.setState({ [name]: false })
		var allFields = '{ "parte":'+parte+', "codigo":"'+codigo+'", "precio":'+precio+'}';
		IO.axios({
			url:'/data/preciario', 
			method: 'delete',
			data: allFields})
			.then(response => { this.getData();	});
		}
	render() {
		const { pedido, activeItem, archivos, activeIndex, loading_files, pem, pec } = this.state
		const operacion = pedido.operaciones ? pedido.operaciones[0] : null;
		const user = Storage.getStorage('user')
		if(!pedido) return '';
		if (this.props.match.params['Id'] !== pedido.id){
			this.getData();
			return ''
		}
		return (
			<Container style={{ marginTop: '1em' }}>
				<Grid >
					<Grid.Row>
						<Grid.Column  width={6} textAlign='left'>
							<Header>
								<Label color={pedido.prioridad==="URGENTE" ? 'red' : pedido.prioridad==="PRIORITARIA" ? 'yellow' : 'grey'}>{pedido.id}</Label>
								<Label  color='teal'> 
									<NumberFormat 
										value={Math.round(pem*100)/100} 
										displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>
								<Label  color='olive'> 
									<NumberFormat 
										value={Math.round(pec*100)/100} 
										displayType={'text'} thousandSeparator={' '}  prefix='PEC: '
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>	
							</Header>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				
				<Divider horizontal />

				<Grid>
					<Grid.Row>
				  	<Grid.Column width={4}>
							<Menu pointing vertical>
								{activeItem==='detalles' ?
									<Menu.Item active={activeItem === 'detalles'}>
										<Menu.Header>Detalles Pedido</Menu.Header>
										<Menu.Menu>
											<Menu.Item name='imprimir' onClick={this.imprimirPedido} >Imprimir</Menu.Item>
											<EnviarEmail  pedido={pedido.id}/>
										</Menu.Menu>	
									</Menu.Item>
								:
									<Menu.Item name='detalles' active={activeItem === 'detalles'} onClick={this.handleItemClick}>
										Detalles Pedido
									</Menu.Item>
								}
								{activeItem==='archivos' ?
									<Menu.Item active={activeItem === 'archivos'}>
										<Menu.Header>Archivos</Menu.Header>
										<Menu.Menu>
											<SubirArchivo pedido={pedido.id} updateFiles={this.getData} files="filePedidos"/>
										</Menu.Menu>	
									</Menu.Item>
								:
									<Menu.Item name='archivos' active={activeItem === 'archivos'} onClick={this.handleItemClick} >
										Archivos
										<Label>{archivos.length}</Label>
									</Menu.Item>
								}
							</Menu>
							<Divider />
							{user.roles.SERVICIO && (pedido.estado==="NUEVO")  &&
								<div>
									<CambioEstado icon='checkmark' color='orange' content='confirmar' update={this.getData} pedido={pedido.id}/>
									<CambioEstado icon='remove' color='black' content='rechazar' update={this.getData} pedido={pedido.id}/>
								</div>
							}	
							{user.roles.IMD && pedido.estado==="RECHAZADO" &&
								<div>
									<CambioEstado icon='checkmark box' color='green' content='reabrir' update={this.getData} pedido={pedido.id}/>									
								</div>								
							}

							{user.roles.IMD && pedido.estado==="CONFIRMADO" &&
								<div>									
									<CambioEstado icon='window close outline' color='grey' content='cerrar' update={this.getData} pedido={pedido.id}/>
								</div>								
							}

							<Feed size='small'>
								{_.map(_.reverse(_.sortBy(pedido.logs,['fecha'])), ({ fecha, estadoNuevo, mensaje, creador },index) => (
								<Feed.Event key={index}>
									<Feed.Content>
										<Feed.Date content={fecha} />
										<Feed.User content={creador}/>
										<Feed.Summary> <div dangerouslySetInnerHTML={{ __html: mensaje }}></div></Feed.Summary>
									</Feed.Content>
									<Feed.Meta>
										<Label color={estadoNuevo==="ENTREGADO"  ? 'grey' : estadoNuevo==="RECHAZADO" ? 'black' : estadoNuevo==="CONFIRMADO" ? 'orange' : 'green'} horizontal basic>{estadoNuevo}</Label>
									</Feed.Meta>
								</Feed.Event>
								))}
							</Feed>
					</Grid.Column>
					<Grid.Column width={12}>
							{ activeItem==='detalles' &&
							<Segment raised>
								<Label color={pedido.prioridad==="URGENTE" ? 'red' : pedido.prioridad==="PRIORITARIA" ? 'yellow' : 'grey'} ribbon>{pedido.prioridad}</Label>
								<span>
									<Label color={pedido.estado==="ENTREGADO"  ? 'grey' : pedido.estado==="RECHAZADO" ? 'black' : pedido.estado==="CONFIRMADO" ? 'orange' : 'green'} horizontal basic>{pedido.estado}</Label>
								</span>
								<Divider horizontal />
								<Grid >
									<Grid.Row   columns={4}>
										<Grid.Column>
											<Header ><Header.Subheader>Pedido</Header.Subheader>{pedido.id}	</Header>
										</Grid.Column>
										<Grid.Column>
											<Header><Header.Subheader>Fecha Creación</Header.Subheader>{pedido.fechaCreacion}	</Header>	
										</Grid.Column>
										<Grid.Column>
											<Header><Header.Subheader>Fecha Cierre</Header.Subheader>{pedido.fechaCierre}	</Header>	
										</Grid.Column>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Creador</Header.Subheader>{pedido.creador}	</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row   columns={2}>
										<Grid.Column>
											<Header size='small' as={Link} to={'/instalaciones/'+pedido.centro_id}><Header.Subheader>Centro</Header.Subheader>{pedido.centro} {pedido.subcentro && "("+pedido.subcentro+")"}	</Header>
										</Grid.Column>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Dirección</Header.Subheader>{pedido.direccion}	</Header>
										</Grid.Column>									
										
									</Grid.Row>
									<Grid.Row  columns={1}>
										<Grid.Column>
											<Header size='small'><Header.Subheader>Descripción</Header.Subheader>{pedido.descripcion}	</Header>
										</Grid.Column>									
										
									</Grid.Row>
								</Grid>								
							</Segment>}
							{ activeItem==='detalles' && operacion && 
								<Segment raised>	
										<Label attached='bottom right' color='teal'> 
											<NumberFormat 
												value={Math.round(pem*100)/100} 
												displayType={'text'} thousandSeparator={' '}  
												decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										</Label>									
										{user.roles.SERVICIO && (pedido.estado==='NUEVO' || pedido.estado==='CONFIRMADO' ) &&
											<Grid>
												<Grid.Column width={4}>
													<Popup trigger={<span><NuevoPrecio size='mini' parte={operacion.id} updateParte={this.getData}/></span>}
														content='Nuevo elemento'/>
												</Grid.Column>
												<Grid.Column width={12} textAlign='right'>
													{ this.state.topfive &&
														_.map(this.state.topfive,(precio) => 
															<Popup key={'nuevo_'+operacion.id+'_'+precio.id} trigger={<span><NuevoPrecio precio={precio} parte={operacion.id} updateParte={this.getData}/></span>}
															content={precio.resumen}/>
														)
													}
												</Grid.Column>
											</Grid>
										}
										<Table striped>
											<Table.Header>
												<Table.Row>
													{user.roles.SERVICIO && (pedido.estado==='NUEVO' || pedido.estado==='CONFIRMADO' ) &&
													<Table.HeaderCell></Table.HeaderCell>
													}
													<Table.HeaderCell>Codigo</Table.HeaderCell>
													<Table.HeaderCell>Artículo</Table.HeaderCell>
													<Table.HeaderCell>Precio</Table.HeaderCell>
													<Table.HeaderCell>Frecuencia</Table.HeaderCell>
													<Table.HeaderCell>Cantidad</Table.HeaderCell>
													<Table.HeaderCell>Subtotal</Table.HeaderCell>
												</Table.Row>
											</Table.Header>										
											<Table.Body>
												{_.map(operacion.precios,(precio) =>
													<Table.Row key={precio.codigo}>	
														{user.roles.SERVICIO && (pedido.estado==='NUEVO' || pedido.estado==='CONFIRMADO') &&
														<Confirm open={this.state['open_confirm_delete_precio_'+operacion.id+'_'+precio.codigo]}
															name={'open_confirm_delete_precio_'+operacion.id+'_'+precio.codigo}
															content='¿Seguro que desa eliminar el artículo?'
															onCancel={this.handleCancel} codigo={precio.codigo} precio={precio.id} parte={operacion.id}
															onConfirm={this.handleDeletePrecio}
														/>
														}
														{user.roles.SERVICIO && (pedido.estado==='NUEVO' || pedido.estado==='CONFIRMADO') &&
														<Table.Cell>
															<Button.Group size='mini'>
																<Button icon='delete' onClick={this.handleOpen} name={'open_confirm_delete_precio_'+operacion.id+'_'+precio.codigo}/>
																<EditPrecio parte={operacion.id} precio={precio} updateParte={this.getData} disabled={precio.unidad==='%'}/>
															</Button.Group>
														</Table.Cell>
														}
														<Table.Cell>{precio.codigo}</Table.Cell>
														<Table.Cell>{precio.resumen}</Table.Cell>
														<Table.Cell textAlign='right'>{Math.round(precio.precio*100)/100}</Table.Cell>														
														<Table.Cell textAlign='right'>{precio.rendimiento + " ("+ precio.factor+"%)"}</Table.Cell>
														<Table.Cell textAlign='right'>{precio.unidad!=='%' && precio.cantidad}</Table.Cell>
														<Table.Cell textAlign='right'>
															<NumberFormat 
																value={Math.round(precio.total*100)/100}
																displayType={'text'} thousandSeparator={' '}  
																decimalScale={2} fixedDecimalScale={true} decimalseparator="," />																
														</Table.Cell>
													</Table.Row>
													)}
											</Table.Body>
										</Table>
								</Segment>
							}
							
							{ activeItem==='archivos' && 
								<ListaArchivos archivos={archivos} files="filePedidos"  updateFiles={this.getData} loading={loading_files}/>
							}
				  	</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		);
  }
}

class EnviarEmail extends Component {
	constructor(props) {
		  super(props);
		  this.state = {
			  open: this.props.open,
			  email: null
		  };
	};	  
	handleChange = (e, { name, value }) => {this.setState({ [name]: value });}
	enviar = () => {
		if (this.state.email){
			var allFields = '{ "id":"'+this.props.pedido+'", "email":"' +this.state.email+'"}';
			IO.axios({
				url:'/data/sendpedido', 
				method: 'post', data: allFields});
			this.setState({ open: false });
		}
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	render() {
	  const { open } = this.state
	  return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
		  size='small' 
		  trigger={<Menu.Item>Enviar por email</Menu.Item>}
		>
		  <Modal.Header>Enviar email</Modal.Header>
		  <Modal.Content>
				<Form>
					<Form.Field control={Input} placeholder='Escriba el email' name='email' onChange={this.handleChange} value={this.state.email} />
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.enviar} />
					  <Button  content='Cancelar' onClick={this.close} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}

class NuevoPrecio extends Component {
	constructor(props) {
		  super(props);

		  var articulo = this.props.precio ? this.props.precio : {};
		  var invalidate = null
		  if(this.props.precio){
				articulo.title='['+articulo.codigo+'] '+articulo.resumen;
				articulo.key= articulo.id;
				articulo.description= articulo.precio+' € / '+articulo.unidad;
				invalidate = articulo.unidad==="%"? false : null;
		  }
		   

		  this.state = {
			articulo: articulo,
			cantidad: '',
			invalidate : invalidate
		  };
	};	  
	cacheParte = {};
	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value }, () => {
		if (this.state.articulo.key && (Validations.isNumber(this.state.cantidad) || this.state.articulo.unidad==="%")) {
			this.setState({invalidate:false});
		}});
	}
	resetComponent = () => this.setState({isLoading: false, results: [], articulo: {},cantidad: '',invalidate : null})
	enviar = () => {
		if(this.state.invalidate === false){
			var allFields = '{ "parte":"'+this.props.parte+
							'", "codigo":"' +this.state.articulo.codigo+
							'", "precio":"' +this.state.articulo.key+							
							'", "cantidad":'+(this.state.articulo.unidad!=="%"?this.state.cantidad:"0")+'}';
			IO.axios({
				url:'/data/preciario', 
				method: 'post',
				data: allFields})
				.then(response => {
						this.setState({ open: false });
						this.props.updateParte();	
						if(!this.props.precio)
							this.resetComponent();
			});
		}else{ this.setState({invalidate: true})}
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	cancel = () => {
		if(!this.props.precio)
			this.resetComponent();
		this.close();
	}
	handleResultSelect = (e, { result }) => { 
		this.setState({ articulo: result, invalidate: (result.unidad==="%")?false:null});
	}
	handleSearchChange = (e, { value }) => {
		this.setState({ isLoading: true, articulo: {} })
		if (value.length < 3) return this.resetComponent();
		if ( value in this.cacheParte ) {
			this.setState({ isLoading: false, results: this.cacheParte[ value ]});
		}else {
			IO.axios.get('/data/search_precio.php', {
				params: {
					term: value,
					precio: true
				}
			}).then(response => {
				var precios = _.map(response.data,(precio) => {return {	title:'['+precio.codigo+'] '+precio.resumen,
																		key:precio.id, unidad:precio.unidad, codigo:precio.codigo,
																		description: precio.precio+' € / '+precio.unidad }})
				this.setState({ isLoading: false, results: precios});
				this.cacheParte[ value ] = precios;
			});
		}
	}
	
	render() {
	  const { open, articulo, isLoading, results, invalidate, cantidad } = this.state	
	  return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
		  size='small'  dimmer={false} className = 'scrolling'
		  trigger={this.props.precio ?<Button size='mini' content={this.props.precio.codigo} />
		  							 :<Button size='mini' icon='plus square outline' />}
		>
		  <Modal.Header>Nuevo precio</Modal.Header>
		  <Modal.Content>
				<Form error={invalidate}>
					<Form.Field
						control={Search}
						label='Artículo'
						loading={isLoading}
						onResultSelect={this.handleResultSelect}
						onSearchChange={this.handleSearchChange}
						results={results}
						value={articulo.title}
						scrolling
						noResultsMessage='No hay resultados'
						input={{ fluid: true }}
						error = {!articulo.key && invalidate}
					/>
					<Form.Input name='cantidad' label='Cantidad' onChange={this.handleChange} 
								disabled={articulo.unidad==="%"} value={cantidad} input={{autoComplete:"off"}} 								
								error={(!cantidad || !Validations.isNumber(cantidad)) && articulo.unidad!=="%" && invalidate}/>
					<Message
							error
							header='Error en formulario'
							content='Complete los campos obligatorios (en rojo).'
						/>
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.enviar} />
					  <Button  content='Cancelar' onClick={this.cancel} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}

class EditPrecio extends Component {
	constructor(props) {
		  super(props);
		  this.state = {
			cantidad: this.props.precio.cantidad
		  };
	};	  	
	componentWillReceiveProps(next){
		if(!next.precio || next.precio === this.precio) return	
		this.setState({cantidad: next.precio.cantidad});
	 }
	handleChange = (e, { name, value }) => {this.setState({ [name]: value });}
	enviar = () => {
		if (Validations.isNumber(this.state.cantidad)) {
			// console.log(this.props.precio);			
			var allFields = '{ "parte":"'+this.props.parte +
							'", "precio":"' + this.props.precio.id +
							'", "codigo":"' + this.props.precio.codigo +				
							'", "cantidad":'+ this.state.cantidad+'}';
			IO.axios({
				url:'/data/preciario', 
				method: 'put',
				data: allFields})
				.then(response => {
					this.setState({ open: false, cantidad: this.props.precio.cantidad });
					this.props.updateParte();	
			});
		}
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })

	render() {
	  const { open, cantidad  } = this.state
	  return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
		  size='small' 
		  trigger={<Button icon='edit' disabled={this.props.disabled} />}
		>
		  <Modal.Header>Modificar cantidad</Modal.Header>
		  <Modal.Content>
				<Form  error={!Validations.isNumber(cantidad)}>
					<Form.Input name='cantidad' label='Cantidad' onChange={this.handleChange} value={cantidad} 
					input={{autoComplete:"off"}} 	error={!Validations.isNumber(cantidad)}/>
					<Message
							error
							header='Error en formulario'
							content='Debe introducir una cantidad correcta.'
						/>
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.enviar} />
					  <Button  content='Cancelar' onClick={this.close} />
		  </Modal.Actions>
		</Modal>
	  )
	}
}
  
class CambioEstado extends Component {
	constructor(props) {
			super(props);
			this.state = {
			mensaje: ''
			};
	};	  	
	handleChange = (e, {name,value})  => {this.setState({[name]: value});}	
	enviar = () => {
		var data = '{ "id":"'+this.props.pedido+'", "mensaje":"' +this.state.mensaje.replace(/\r?\n/g, '<br/>' )+'" }';
		var url = '/data/pedidos.php/'+this.props.content;
		IO.axios({
			url: url, 
			method: 'put',
			data: data})
			.then(response => {
				this.setState({ open: false });
				this.props.update();	
				this.resetComponent();
		});
	}
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	resetComponent = () => this.setState({ mensaje: {} })	
	
	render() {
		const { open } = this.state
		return (
		<Modal open={open}  onOpen={this.open}  onClose={this.close}
			size='small' 
			trigger={<Button basic icon={this.props.icon} content={this.props.void ? "" : this.props.content} size='mini'
							 color={this.props.color} floated={this.props.floated}
							 onClick={(e) => e.preventDefault()}/>}
		>
			<Modal.Header>{this.props.content} {this.props.pedido}</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Field control={TextArea} label='Mensaje' onChange={this.handleChange} name='mensaje'/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
						<Button  content='Aceptar' onClick={this.enviar} />
						<Button  content='Cancelar' onClick={this.close} />
			</Modal.Actions>
		</Modal>
		)
	}
  }
  
  export {DetallePedido, CambioEstado};