import _ from 'lodash';
import React, { Component } from 'react';
import {Table,Grid, Header, Segment, Label, Button, Input, Dropdown, Message, 
		Confirm, Sticky, Form, Checkbox} from 'semantic-ui-react';
import {IO} from './js/IO'
import {Storage} from "./js/storage";
import {SHA1} from './js/sha1'

class Usuarios extends Component {	
	constructor(props) {
		super(props);
		this.state = { 
			usuarios: [], 
			usuario: null,
			id: null,
			user: Storage.getStorage('user'),
			edit : false,
			roles:[],
			open_delete: false,
			message_delete: false
		};
	}
	handleContextRef = contextRef => this.setState({ contextRef })
	handleOpenDelete = () => this.setState({ open_delete : true, message_delete:false})
	handleCancelDelete = () => this.setState({ open_delete : false, message_delete:false})
	handleRowClick = (k) => this.setState({ message_delete:false, usuario : _.clone(_.find(this.state.usuarios,{id:k})), id:k})
	handleEdit = () => {
		var usuario = this.state.usuario;
		usuario.roles = _.map(usuario.roles,(value,key) => value.id);
		this.setState({ edit : true, usuario, message_delete:false});
	}
	handleNew = () => this.setState({ 	edit : true, message_delete:false,
										usuario:{id:-1, nombre:'', apellidos:'', roles:[], empresa_id:this.state.user.id, email:'', password:''},
										k:-1});
	cancel = () => this.setState({ edit : false, usuario:(this.state.id===-1) ? null : _.clone(_.find(this.state.usuarios,{id:this.state.id}))});
	save = () => {
		var u = this.state.usuario;
		var allFields = {nombre:u.nombre, apellidos:u.apellidos, email:u.email, envio_correo:u.envio_correo ? 1 : 0, activo:u.activo ? 1 : 0,
						password:(this.state.id === -1 || u.password === _.find(this.state.usuarios,{id:this.state.id}).password) ? u.password : SHA1(u.password),
						roles: _.join(u.roles,',') }
		if(this.state.edit && this.state.usuario.id!==-1){
			IO.axios({
				url:'/data/users.php/'+u.id, 
				method: 'PUT',
				data: JSON.stringify(allFields)})
				.then(response => {
						this.setState({ edit: false, usuario:null },this.getUsers);
				});
		}else if (this.state.usuario.id === -1){
			IO.axios({
				url:'/data/users.php/', 
				method: 'POST',
				data: JSON.stringify(allFields)})
				.then(response => {
						this.setState({ id:""+response.data.id, edit: false, usuario:null },this.getUsers);
				});
		}
	}
	delete = () => {
		IO.axios({
			url:'/data/users.php/'+this.state.id, 
			method: 'DELETE'})
			.then(response => {
				if (response.data.error === 0){
					this.setState({ edit: false, usuario:null, id:null, message_delete:false, open_delete:false },this.getUsers);
				} else{
					this.setState({message_delete:true, open_delete:false}, this.getUsers);
				}
			});
	}
	handleChange = (e, {name,value,checked}) =>{
		var usuario = this.state.usuario;
		if(name === 'activo' || name =='envio_correo'){
			usuario[name] = checked;
		}else{
			usuario[name] = value;
		}
		this.setState({usuario});
	}
	getUsers = () =>{
		IO.axios.get('/data/users')
		.then(response => {
			this.setState({ usuarios : response.data, usuario : _.cloneDeep(_.find(response.data,{id:this.state.id}))});
		});
	}
	componentDidMount(){
		this.getUsers();
		IO.axios.get('/data/roles')
		.then(response => {
			var roles = _.map(response.data, o => _.extend({key: o.id, text:o.nombre, value:o.id}, o));
			roles = (this.state.user.roles.ADMINISTRACION) ? roles : _.filter(roles, (r) => r.nombre!=='ADMINISTRACION'); 
			roles = (this.state.user.roles.SERVICIO) ? roles : _.filter(roles, (r) => r.nombre!=='SERVICIO'); 
			roles = (this.state.user.roles.IMD) ? roles : _.filter(roles, (r) => r.nombre!=='IMD'); 
			this.setState({roles});
		});
	}

	render() {
		const { usuarios, usuario, user, edit, contextRef } = this.state;
		return (
			<div ref={this.handleContextRef}>
				<Grid container>
					<Grid.Row>
						<Grid.Column width={5} style={{ marginTop: '5em' }}>
							<Table selectable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>Usuarios</Table.HeaderCell>
										<Table.HeaderCell>
											<Button.Group className='derecha'>
												<Button icon='file' primary onClick={this.handleNew}/>
												<Button icon='edit' primary disabled={!usuario} onClick={this.handleEdit}/>
												<Button icon='trash' primary disabled={!usuario} onClick={this.handleOpenDelete}/>
											</Button.Group>
											<Confirm
												open={this.state.open_delete}
												content='Seguro que desa elminar el usuario?'
												onCancel={this.handleCancelDelete}
												onConfirm={this.delete}
												/>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(_.filter(usuarios,{empresa_id:user.empresa}), ({ id, nombre, apellidos, empresa_id }) => 
										<Table.Row key={id} active={usuario && id === usuario.id } onClick={ () => {this.handleRowClick(id)}} >
											<Table.Cell colSpan={2}>{nombre + ' '  + apellidos}</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							</Table>
						</Grid.Column>
						<Grid.Column width={11}>
							{
								usuario &&
								<Sticky context={contextRef}> 
								<Segment raised style={{ marginTop: '5em' }}>
									<Grid >
										<Grid.Row>
											<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Nombre</Header.Subheader>
												{!edit ? usuario.nombre
												:<Input name='nombre' input={{autoComplete:"off"}} fluid value={usuario.nombre} onChange={this.handleChange}/>}
											</Grid.Column>
											<Grid.Column width={10} as={Header} size='small'><Header.Subheader>Apellidos</Header.Subheader>
												{!edit ? usuario.apellidos
												:<Input name='apellidos' input={{autoComplete:"off"}} fluid value={usuario.apellidos} onChange={this.handleChange}/>}
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Password</Header.Subheader>
												{!edit ? '******'
												:<Input name='password' type='password' input={{autoComplete:"off"}} fluid value={usuario.password} onChange={this.handleChange}/>}
											</Grid.Column>
											<Grid.Column width={10} as={Header} size='small'><Header.Subheader>Email</Header.Subheader>
												{!edit ? usuario.email
												:<Input name='email' input={{autoComplete:"off"}} fluid value={usuario.email} onChange={this.handleChange}/>}
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column width={6} as={Header} size='small'>
												<Form.Field disabled={!edit} control={Checkbox} 
													label='Enviar email de notificaciones' name='envio_correo' 
													onChange={this.handleChange} checked={usuario.envio_correo=="1"}/>
											</Grid.Column>
											<Grid.Column width={10} as={Header} size='small'>
												<Form.Field disabled={!edit} control={Checkbox} 
													label='Usuario Activo' name='activo' 
													onChange={this.handleChange} checked={usuario.activo=="1"}/>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column as={Header} size='small'><Header.Subheader>Perfiles</Header.Subheader>
												{!edit ?_.map(usuario.roles,(a) => <Label key={a.id} style={{margin:'5px'}} content={a.nombre} />)
												:<Dropdown fluid multiple selection options={this.state.roles} name="roles" value={usuario.roles} onChange={this.handleChange}/>}
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column>
												{edit && <Button content='Cancelar' onClick={this.cancel} color='red' floated='right' />}
												{edit && <Button content='Guardar' onClick={this.save} primary floated='right'/>}
											</Grid.Column>
										</Grid.Row>

										<Grid.Row>
											{this.state.message_delete && <Message fluid warning
											header='No se ha podido eliminar el usuario.'
											content='No es posible eliminar usuarios con incidencias asignadas. Revise los perfiles'
											/>}
										</Grid.Row>
									</Grid>								
									
								</Segment>
								</Sticky>
						}
						</Grid.Column>
						</Grid.Row>
					
				</Grid>
			</div>
		);
  }
}

export default Usuarios;