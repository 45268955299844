import React, { Component } from 'react'
import { Menu, Button, Form, Grid, Header, Image, Message} from 'semantic-ui-react'
import {IO} from './js/IO'
import {Storage} from './js/storage';
import {SHA1} from './js/sha1'
import moment from 'moment';

class Login extends Component {	

    render() {
        return (
            <div className='login-form'>
              <style>{`
                body > div,
                body > div > div,
                body > div > div > div.login-form {
                  height: 100%;
                }
              `}</style>
              <Menu fixed='top' inverted>
                <Menu.Item header name='home'>
                  <Image size="tiny" spaced="right"  src="../gmanti.png"/>
                </Menu.Item>
              </Menu>
            <Register onLogin={this.props.onLogin}/>
            </div>
        )}
}

class Register extends Component {	
  data = {}
  constructor(props) {
    super(props);
    this.state={error:false};
  }

  login = () =>{
    this.setState({error:false});
      Storage.removeAll();
      if(!this.data.username || !this.data.password){
          this.setState({ error: true });
          return;
      }
      
      IO.axios({
          url:'/loginREST', 
          method: 'post',
          data: "{\"username\":\""+this.data.username+"\",\"object\":\""+SHA1(this.data.password)+SHA1((moment().add(1,'hours').date()).toString())+"\"}"})
          .then(response => {
              if (response.data.error === 0 ){
                Storage.setStorage('user', response.data);
                this.props.onLogin(response.data);                    
              }else{
                  Storage.removeStorage('user');
                  this.setState({error:true});
              }
          })
          .catch(response => {
            this.setState({error:true});
        });
  }
  
  handleChange = (e, { name, value }) => {this.data[name] = value }
  render() {
      return (
          <Grid
              textAlign='center'
              style={{ height: '100%' }}
              verticalAlign='middle'
            >
              <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' color='black' textAlign='center'>
                  Introduce tus datos de acceso
                </Header>
                <Form size='large'>
                    <Form.Input
                      fluid
                      icon='user'
                      iconPosition='left'
                      name='username'
                      placeholder='E-mail'
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      fluid
                      icon='lock'
                      iconPosition='left'
                      placeholder='Password'
                      type='password'
                      name='password'
                      onChange={this.handleChange}
                      />
        
                    <Button color='black' fluid size='large' onClick={this.login}>Login</Button>
                </Form>
                {this.state.error &&<Message warning >
                  <Message.Header>Error</Message.Header>
                  Debe especificar un usuario y password correcto
                </Message>}
              </Grid.Column>
          </Grid>
      )}
}

export {Login,Register}