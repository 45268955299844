import React, { Component } from 'react';
import { Icon, Table, Button, Modal, Form, Grid, Menu, Message, Popup} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';
import {IO} from '../js/IO'
import NumberFormat from 'react-number-format'
import {Storage} from '../js/storage'

class Facturacion extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			certificaciones : [],
			user: Storage.getStorage('user'),
			empresageneral: this.props.empresageneral
		};
	};
	getData = () => {
		IO.axios.get('/data/certificacion',{
			params: {
				empresa: (this.state.empresageneral!== -1 ? this.state.empresageneral : null)
			}
		}).then(response => {this.setState({ certificaciones: response.data });});
	}
	componentDidMount() {
		this.getData();
	}
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.getData());
	}
	render() {
		return (			
			<Grid  style={{ margin: '0.5em' }}>
				<Grid.Row>
				  <Grid.Column width={this.state.user.roles.SERVICIO ? 3 : 0}>
						{this.state.user.roles.SERVICIO &&
						<Menu vertical>
							<Menu.Item> <NuevaCertificacion history={this.props.history}/> </Menu.Item>
						</Menu>
						}
					</Grid.Column>
					<Grid.Column width={this.state.user.roles.SERVICIO ? 13 : 16}> 
						<Table striped selectable compact>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Número</Table.HeaderCell>
									<Table.HeaderCell>Tipo</Table.HeaderCell>
									<Table.HeaderCell>Empresa</Table.HeaderCell>
									<Table.HeaderCell textAlign="center">Fecha </Table.HeaderCell>
									<Table.HeaderCell textAlign="center">Inicio</Table.HeaderCell>
									<Table.HeaderCell textAlign="center">Fin</Table.HeaderCell>
									<Table.HeaderCell textAlign="center">Estado</Table.HeaderCell>
									<Table.HeaderCell textAlign="right"><Popup trigger={<div>PEM</div>} content="Presupuesto de Ejución Material" /> </Table.HeaderCell>
									<Table.HeaderCell textAlign="right"><Popup trigger={<div>PEC</div>} content="Presupuesto de Ejución por Contrato" /> </Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(this.state.certificaciones, (col) => (
									col.id!==-1 &&
								<Table.Row key={col.id} onClick={() => this.props.history.push('/facturacion/'+col.id)}>
									<Table.Cell>{col.numero}</Table.Cell>
									<Table.Cell>{col.tipo}</Table.Cell>
									<Table.Cell>{col.empresa}</Table.Cell>
									<Table.Cell>{col.fecha}</Table.Cell>
									<Table.Cell>{col.inicio}</Table.Cell>
									<Table.Cell>{col.fin}</Table.Cell>
									<Table.Cell>
									<Icon name={col.estado === 'CONFIRMADA'? 'checkmark' : col.estado === 'BLOQUEADA' ? 'lock' : 'unlock'} />{col.estado}
									</Table.Cell>
									<Table.Cell textAlign="right">
										{col.tipo === "VARIABLE"? <NumberFormat 
											value={col.total ? col.total : 0} 
											displayType={'text'} thousandSeparator={' '}  
											decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										: "--"
										}
									</Table.Cell>
									<Table.Cell textAlign="right">
										{col.tipo === "VARIABLE"? <NumberFormat  
											value={this.state.user.ferreteria ? col.conbaja : Math.round( (col.total * (1-col.baja/100) * (1+col.gg/100+col.bi/100) * (1+col.igic/100))*100)/100}
											displayType={'text'} thousandSeparator={' '}  
											decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
											: "--"
										}		
									</Table.Cell>
								</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
  }
}

export default Facturacion;

class NuevaCertificacion extends Component {
	optionsType = [
		{text: "Variable", value: "VARIABLE"},
		{text: "Fijo", value: "FIJO"},
	]
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			fechaInicial: moment(),
			fechaFinal:  moment(),
			tipo: this.optionsType[0].value,
			user: Storage.getStorage("user"),
			canon_fijo_user: "0" 	// "0" is false, "1" is true
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.open !== this.state.open && this.state.open){
			const empresas = Storage.getStorage("empresas_menu");
			const canon_fijo_user = (empresas.find(item => item.id === this.state.user.empresa)).canon_fijo;
			this.setState({canon_fijo_user});
		}
	}


	handleInicio = (date) => this.setState({ fechaInicial: date })
	handleFin = (date) => this.setState({ fechaFinal: date })
	
	createCertificacion = () => {
		const {fechaInicial, fechaFinal, canon_fijo_user, tipo} = this.state;
		if(fechaInicial && fechaFinal) {
			this.setState({invalid :false});
			const allFields = {
				inicio: fechaInicial.format('YYYY-MM-DD'),
				fin: fechaFinal.format('YYYY-MM-DD')
			}
			if(canon_fijo_user === "1") allFields.tipo = tipo;
			IO.axios({
				url:'/data/certificacion', 
				method: 'POST',
				data: JSON.stringify(allFields)})
				.then(response => {
					this.props.history.push('/facturacion/'+response.data.id);
				});
		}else{
			this.setState({invalid :true});
		}
	}
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  onChange = ({ target }) => this.setState({ [target.name]: target.value });

  render() {
	const { open, fechaFinal, fechaInicial, invalid, tipo, canon_fijo_user} = this.state
    return (
      <Modal
        open={open}
        onOpen={this.open}
        onClose={this.close}
        size='small'
				trigger={<Button primary fluid >Nueva Certificación</Button>}
      >
        <Modal.Header>Nueva Certificación</Modal.Header>
        <Modal.Content>
					<Form error={invalid}>
						<Form.Group widths='equal'>
							<Form.Field control={DatePicker} label='Fecha Inicial' selected={this.state.fechaInicial}  
												dateFormat="DD/MM/YYYY"	onChange={this.handleInicio} error={!fechaInicial && invalid}/>
							<Form.Field control={DatePicker} label='Fecha Final'  selected={this.state.fechaFinal}
												dateFormat="DD/MM/YYYY" onChange={this.handleFin} error={!fechaFinal && invalid}/>
							{canon_fijo_user === "1" && (
								<Form.Field control="select" label="Tipo" name="tipo" onChange={this.onChange}>
									{_.map(this.optionsType, (item, index) => (
										<option key={index} value={item.value} selected={tipo === item.value? item.value : ""}>{item.text}</option>
									))}
								</Form.Field>
							)}
						</Form.Group>
					</Form>
					{this.state.user.servicio &&
					<Message>
						<Message.Header>Nota sobre los partes</Message.Header>
						<p>Se incluirán todos los partes de operarios de las incidencias que estén entre las fechas indicadas.</p>
					</Message>
  					}
					{this.state.user.ferreteria &&
					<Message>
						<Message.Header>Nota sobre los pedidos</Message.Header>
						<p>Se incluirán todos los pedidos que estén entregados entre las fechas indicadas.</p>
					</Message>
  					}
        </Modal.Content>
        <Modal.Actions>
          <Button  content='Aceptar' onClick={this.createCertificacion} />
					<Button  content='Cancelar' onClick={this.close} />
        </Modal.Actions>
      </Modal>
    )
  }
}
