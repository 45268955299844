import _ from 'lodash';
import React, { Component } from 'react';
import { Table, Grid, Breadcrumb, Search} from 'semantic-ui-react';
import {IO} from './js/IO'

class Precios extends Component {	
	cacheParte = {};
	constructor(props) {
		super(props);
		this.state = { 
			precios:[],
			hormigas:[],
			empresageneral: this.props.empresageneral
		};
	}
	getData = (id,codigo) =>{
		var index = _.findIndex(this.state.hormigas,{content:codigo});
		if(index!==-1) return;
		IO.axios.get('/data/preciario.php/'+(id?id:""),{
			params:{
				empresa:(this.state.empresageneral!== -1 ? this.state.empresageneral : null)
			}
		}).then(response => {
			var hormigas = _.clone(this.state.hormigas);
			if(hormigas.length >0){
				hormigas[hormigas.length-1].link = true
				hormigas[hormigas.length-1].active = false
				hormigas[hormigas.length-1].onClick = this.handleClickHormigas
			} 
			hormigas.push( { key: id, content: codigo, active:true} );
			if(response.data.length >0){
				this.setState({precios: response.data, hormigas:hormigas, busqueda:null});
			}else if(this.state.busqueda){
				this.setState({precios: [this.state.busqueda], hormigas:hormigas, busqueda:null});
			}
		});
	 }
	componentDidMount() {
		this.getData('','INICIO');
	 }
	 componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({hormigas:[], empresageneral: next.empresageneral}, () => this.getData('','INICIO'));
		this.cacheParte = {};
	}
	handleClickHormigas = (event, data) => {
		var index = _.findIndex(this.state.hormigas,{content:data.content});
		var item = _.find(this.state.hormigas,{content:data.content})
		var hormigas = _.slice(this.state.hormigas,0,index);
		this.setState({hormigas}, () => {this.getData(item.key, (item.content === 'INICIO ...') ? 'INICIO' : item.content);})
	}
	handleResultSelect = (e, { result }) => {
		this.setState({hormigas:[{ key: '', content: 'INICIO ...'}], busqueda:result}, () => {this.getData(result.key, result.codigo);})
	}
	handleSearchChange = (e, { value }) => {
		this.setState({isLoading: true})
		if (value.length < 3) return 
		if ( value in this.cacheParte ) {
			this.setState({ isLoading: false, results: this.cacheParte[ value ]});
		}else {
			IO.axios.get('/data/search_precio.php', {
				params: {
					term: value,
					empresa:(this.state.empresageneral!== -1 ? this.state.empresageneral : null)
				},
			}).then(response => {
				var precios = _.map(response.data,(precio) => {return {	title:'['+precio.codigo+'] '+precio.resumen,
																		key:precio.id, unidad:precio.unidad, codigo:precio.codigo,
																		resumen:precio.resumen, precio: precio.precio, tipo:precio.tipo,
																		description: precio.precio+' € / '+precio.unidad }})
				this.setState({ isLoading: false, results: precios});
				this.cacheParte[ value ] = precios;
			});
		}
	}
	render() {
		const { precios, hormigas, isLoading, results } = this.state;
		return (
			<Grid container style={{ marginTop: '3em' }}>
				<Grid.Row>
					<Grid.Column width='13'>
						<Breadcrumb icon='right angle' sections={hormigas}/>
					</Grid.Column>
					<Grid.Column width='3'>
						<Search
							loading={isLoading}
							onResultSelect={this.handleResultSelect}
							onSearchChange={this.handleSearchChange}
							results={results}
							noResultsMessage='No hay resultados'
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
				<Table celled selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Código</Table.HeaderCell>
							<Table.HeaderCell>Ud.</Table.HeaderCell>
							<Table.HeaderCell>Resumen</Table.HeaderCell>
							<Table.HeaderCell>Precio</Table.HeaderCell>
							<Table.HeaderCell>Tipo</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(precios,(precio)=>
							<Table.Row key={precio.id} onClick={() => this.getData(precio.id, precio.codigo)} >
								<Table.Cell>{precio.codigo}</Table.Cell>
								<Table.Cell>{precio.unidad}</Table.Cell>
								<Table.Cell>{precio.resumen}</Table.Cell>
								<Table.Cell>{precio.unidad ==='' ? '' : precio.precio}</Table.Cell>
								<Table.Cell>{precio.tipo}</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>
				</Table>
				</Grid.Row>
			</Grid>
				
					
				
		);
  }
}

export default Precios;