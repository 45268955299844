import React, { Component } from 'react';
import {Container, Header} from 'semantic-ui-react';
import {Storage} from './js/storage';
import HomeAdministracion from './HomeAdministracion'
import HomeServicio from './HomeServicio'
import HomeFerreteria from './HomeFerreteria'

class Home extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			usuario: Storage.getStorage('user'),
		};
	 };
	render() {
		return (
			<Container style={{padding: "20px 0px 20px 0px"}}>
				{this.state.usuario.servicio && <HomeServicio />}
				{this.state.usuario.administracion && <HomeAdministracion empresageneral={this.props.empresageneral}/>}
				{this.state.usuario.ferreteria && <HomeFerreteria />}

				{!this.state.usuario.servicio && !this.state.usuario.administracion && !this.state.usuario.ferreteria &&
					<Header size='huge'>
						Gmanti
						<Header.Subheader>Gestión de mantenimiento de instalaciones</Header.Subheader>
					</Header>}
				
			</Container>
		
		);
  }
}

export default Home;
