import _ from 'lodash'
import React, { Component } from 'react';
import {List, Checkbox, Accordion, Label, Icon} from 'semantic-ui-react';
import NumberFormat from 'react-number-format'

class ListaPartesCertificadosPorTipoFerreteria extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			certificacion : [],
			activeIndex: 0,
			subIndex: 0,
			checked: []
		};
	};
	componentWillReceiveProps(next){
		if(next.certificacion === this.props.certificacion) return
		var data = next.certificacion;
		this.setState({checked: _.map(data.partes,(a) => a.id)});
	}
	handleAccordion = (e, titleProps) => {
		const { index } = titleProps
		const { activeIndex } = this.state
		const newIndex = activeIndex === index ? -1 : index
		this.setState({ activeIndex: newIndex })
	 }

	render() {
		const { activeIndex, subIndex, checked} = this.state
		const { certificacion} = this.props		
		var groupcentros = _.groupBy(certificacion.partes,(a) => a.centro_id);
		var centros = _.map(groupcentros, (value,key) => 
			{
				var inci = _.groupBy(value,'incidencia_id');
				var incidencias = _.map(inci, (value,key) => {
					return {id:key, descripcion:inci[key][0].incidencia, partes:value}
				});
				return {
					id:key,
					nombre:groupcentros[key][0].centro,
					incidencias:incidencias
				}
			});
		certificacion.centros = centros;		
		return (	
			<Accordion fluid styled>	
				{_.map(certificacion.centros,(centro) => 												
				<div key={centro.id}>
					<Accordion.Title key={centro.id} active={activeIndex === parseInt(centro.id,10)} index={parseInt(centro.id,10)} onClick={this.handleAccordion}>
						<Icon name='dropdown' /> {centro.nombre}		
						<Label className='derecha' color='olive' style={{width:'120px', textAlign:'center', marginLeft:'5px'}}>
							<NumberFormat value={Math.round( ( (_.reduce(_.map(_.map(centro.incidencias, 'partes'),(a) => _.reduce(a, (x,y) => x+ parseFloat(y.conbaja),0)), (a,b) => a+b, 0)) * (1+certificacion.igic/100))*100)/100}
											displayType={'text'} thousandSeparator={' '}  prefix='PEC: '
											decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
						</Label>		
						<Label className='derecha' color='teal' style={{width:'120px', textAlign:'center'}}>
							<NumberFormat  value={Math.round(_.reduce(_.map(_.map(centro.incidencias, 'partes'),(a) => _.reduce(a, (x,y) => x+ parseFloat(y.subtotal),0)), (a,b) => a+b, 0)*100)/100}
										displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
						</Label>
					</Accordion.Title> 
					<Accordion.Content active={activeIndex === parseInt(centro.id,10)}>
						{_.map(centro.incidencias,({id,descripcion,partes}) =>
						<div key={id}>
								<List>
								{_.map(partes,({id,fecha,conbaja,subtotal,codigo,incidencia_id}) =>
									<List.Item key={codigo}>
										<List.Content>
											<List.Header>
												{incidencia_id+ " - " +descripcion}
												<Label basic  className='derecha' color='olive' style={{width:'120px', textAlign:'center', marginLeft:'5px'}}> 
													<NumberFormat value={Math.round( ( (conbaja ? conbaja : 0) * (1+certificacion.igic/100))*100)/100}
														displayType={'text'} thousandSeparator={' '}  prefix='PEC: '
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
												</Label>
												<Label basic className='derecha' color='teal' style={{width:'120px', textAlign:'center'}}> 
													<NumberFormat  value={subtotal ? subtotal : 0}
														displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
												</Label>
											</List.Header>
										</List.Content>
									</List.Item>
								)}
							</List>
						</div>
						)}
					</Accordion.Content>								
				</div>					
				)}	
			</Accordion>
		);
  }
}

export default ListaPartesCertificadosPorTipoFerreteria;