import _ from 'lodash'
import React, { Component } from 'react';
import {List, Checkbox, Accordion, Label, Icon} from 'semantic-ui-react';
import NumberFormat from 'react-number-format'

class ListaPartesCertificadosPorTipoVariable extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			certificacion : [],
			activeIndex: 0,
			subIndex: 0,
			checked: []
		};
	};
	componentWillReceiveProps(next){
		if(next.certificacion === this.props.certificacion) return
		var data = next.certificacion;
		this.setState({checked: _.map(data.partes,(a) => a.id)});
	}
	handleAccordion = (e, titleProps) => {
		const { index } = titleProps
		const { activeIndex } = this.state
		const newIndex = activeIndex === index ? -1 : index
		this.setState({ activeIndex: newIndex })
	 }
	handleSubAccordion = (e, titleProps) => {
		const { index } = titleProps
		const { subIndex } = this.state
		const newIndex = subIndex === index ? -1 : index
		this.setState({ subIndex: newIndex })
	 }
	handleChecked = (e, {name}) => {
		var partes = this.state.checked;
		if(partes.indexOf(""+name) > -1){
			partes.splice(partes.indexOf(""+name), 1);
		}else{
			partes.push(""+name);
		}
		this.setState({checked: partes})
		this.props.updateChecked(partes);
	 }
	render() {
		const { activeIndex, subIndex, checked} = this.state
		const { certificacion} = this.props		
		var groupcentros = _.groupBy(certificacion.partes,(a) => a.centro_id);
		var centros = _.map(groupcentros, (value,key) => 
			{
				var inci = _.groupBy(value,'incidencia_id');
				var incidencias = _.map(inci, (value,key) => {
					return {id:key, descripcion:inci[key][0].incidencia, partes:value}
				});
				return {
					id:key,
					nombre:groupcentros[key][0].centro,
					incidencias:incidencias
				}
			});
		certificacion.centros = centros;		
		return (	
			<Accordion fluid styled>	
				{_.map(certificacion.centros,(centro) => 												
				<div key={centro.id}>
					<Accordion.Title key={centro.id} active={activeIndex === parseInt(centro.id,10)} index={parseInt(centro.id,10)} onClick={this.handleAccordion}>
						<Icon name='dropdown' /> {centro.nombre}		
						<Label className='derecha' color='olive' style={{width:'120px', textAlign:'center', marginLeft:'5px'}}>
							<NumberFormat value={Math.round( ( (_.reduce(_.map(_.map(centro.incidencias, 'partes'),(a) => _.reduce(a, (x,y) => x+ parseFloat(y.subtotal),0)), (a,b) => a+b, 0)) * (1-certificacion.baja/100) * (1+certificacion.gg/100+certificacion.bi/100) * (1+certificacion.igic/100))*100)/100}
											displayType={'text'} thousandSeparator={' '}  prefix='PEC: '
											decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
						</Label>		
						<Label className='derecha' color='teal' style={{width:'120px', textAlign:'center'}}>
							<NumberFormat  value={Math.round(_.reduce(_.map(_.map(centro.incidencias, 'partes'),(a) => _.reduce(a, (x,y) => x+ parseFloat(y.subtotal),0)), (a,b) => a+b, 0)*100)/100}
										displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
						</Label>
					</Accordion.Title> 
					<Accordion.Content active={activeIndex === parseInt(centro.id,10)}>
						<Accordion fluid styled>
						{_.map(centro.incidencias,({id,descripcion,partes}) =>
						<div key={id}>
							<Accordion.Title key={id} active={subIndex === parseInt(id,10)} index={parseInt(id,10)} onClick={this.handleSubAccordion}>
								<Label basic  className='derecha' color='olive' style={{width:'120px', textAlign:'center', marginLeft:'5px'}}>
									<NumberFormat value={Math.round( ( (_.reduce(_.map(partes,(a) => parseFloat(a.subtotal)), (a,b) => a+b, 0)) * (1-certificacion.baja/100) * (1+certificacion.gg/100+certificacion.bi/100) * (1+certificacion.igic/100))*100)/100}
											displayType={'text'} thousandSeparator={' '}  prefix='PEC: '
											decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>
								<Label basic className='derecha' color='teal' style={{width:'120px', textAlign:'center'}}>
									<NumberFormat  value={Math.round(_.reduce(_.map(partes,(a) => parseFloat(a.subtotal)), (a,b) => a+b, 0)*100)/100}
										displayType={'text'} thousandSeparator={' '}   prefix='PEM: '
										decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>
								<Icon name='dropdown' /> {id + '  ' }
								<div dangerouslySetInnerHTML={{ __html: descripcion }} />					
							</Accordion.Title> 
							<Accordion.Content active={subIndex === parseInt(id,10)}>
								<List>
								{_.map(partes,({id,fecha,oficio,subtotal,codigo,incidencia_id}) =>
									<List.Item key={id}>
										<List.Content>
											<List.Header>
												<Checkbox name={id} label={incidencia_id+'.'+codigo+'  ('+fecha + ')   '+oficio} 
													disabled={certificacion.estado !== 'BORRADOR'} 
													checked={checked.indexOf(""+id)!==-1} onChange={this.handleChecked}/>
												<Label basic  className='derecha' color='olive' style={{width:'120px', textAlign:'center', marginLeft:'5px'}}> 
													<NumberFormat value={Math.round( ( (subtotal ? subtotal : 0) * (1-certificacion.baja/100) * (1+certificacion.gg/100+certificacion.bi/100) * (1+certificacion.igic/100))*100)/100}
														displayType={'text'} thousandSeparator={' '}  prefix='PEC: '
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
												</Label>
												<Label basic className='derecha' color='teal' style={{width:'120px', textAlign:'center'}}> 
													<NumberFormat  value={subtotal ? subtotal : 0}
														displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
												</Label>
											</List.Header>
										</List.Content>
									</List.Item>
								)}
							</List>
							</Accordion.Content>
						</div>
						)}
						</Accordion>
					</Accordion.Content>								
				</div>					
				)}	
			</Accordion>
		);
  }
}

export default ListaPartesCertificadosPorTipoVariable;