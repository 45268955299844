import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import unregister from './registerServiceWorker';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AppProvider } from './context/AppContext';

const AppState = () => {
    return (
        <AppProvider>
            <App/>
        </AppProvider>
    );
}

ReactDOM.render(<AppState/>, document.getElementById('root'));
unregister();
