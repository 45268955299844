import React, { Component } from 'react';
import { Label, Segment, Form, Table, Dropdown, Input, Icon} from 'semantic-ui-react';
import {IO} from '../js/IO'
import _ from 'lodash'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {PieChart, Pie, Tooltip, Legend, Cell} from 'recharts';
import {COLORS} from '../js/constdata';
import NumberFormat from 'react-number-format';
import createReport from 'docx-templates';
import domtoimage from 'dom-to-image';
import { YCSFormFieldTipoDeMaterial_v2 } from '../YCSComponents/YCSFormFieldTipoDeMaterial_v2';

class InformeMaterial  extends Component {	
	
	constructor(props) {
		super(props);
		this.state = {
			detalles: false, grafica: false, 
			inicio: moment(), fin: moment(),
			data: {},					// Es la info que se muestra en el viewport
			grahpdata: [],				// Es la info que se muestra en el viewport
			dataBackup: {},				// Es una copia de data, usada solo para filtrar
			grahpdataBackup: {},		// Es una copia de grahpdata, usada solo para filtrar
			empresageneral: this.props.empresageneral,
			tipos: []					// Almacenmos los tipos de material a filtrar
		};
	};

	handleCheckChange = (e, {name}) => this.setState({ [name]: !this.state[name] })
	handleInicio = (date)  => this.setState({inicio: date},() => this.getData())	
	handleFin = (date)  => this.setState({fin: date},() => this.getData());

	getData = () => {
		IO.axios({url:'/data/informes.php/material',
		params: {
			empresa: (this.state.empresageneral!== -1 ? this.state.empresageneral : null),
			fechaIni: this.state.inicio.format("YYYY-MM-DD"),
			fechaFin: this.state.fin.format("YYYY-MM-DD")
		}})
		.then(response => {
			var groupMaterial = _.groupBy(response.data,(a) => a.titulo);
			var graph = this.groupMaterialMethod(groupMaterial);
			this.setState({
				data: groupMaterial,
				dataBackup: groupMaterial,
				grahpdata: graph,
				grahpdataBackup: graph
			});
		});
	 }

	/**
	 * Abstracción del método groupMaterialMethod cuando se trae la infomración en componentDidMount
	 * y cuando se filtra
	 * @param {object} groupMaterial
	 * @returns Array
	 */
	 groupMaterialMethod = (groupMaterial) => {
		return _.map(groupMaterial,(value,key) => {
			return {name:key, 
					value:Math.round(_.reduce(_.map(value,(row) => parseFloat(row.subtotal)), (x,y) => x+y,0)*100)/100,
					pec: Math.round( _.reduce(_.map(value,(row) => parseFloat(row.subtotal*100 * (1-row.baja/100) * (1+row.gg/100+row.bi/100) * (1+row.igic/100))), (x,y) => x+y,0))/100
				}
		});
	 }

	componentDidMount(){
		this.getData();
		this.getTypeOfMaterial();
	}

	getTypeOfMaterial = () => {
		IO.axios.get('/data/tipomaterial')
		.then(response => {
			this.setState({ tipos: JSON.parse(_.replace(JSON.stringify(response.data),/title/g,'name')) });
		});
	 }

	 componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.getData());
	}

	descargar = () => {
		document.body.style.cursor = 'wait';
		const graf = this.state.grafica;
		const centros = _.map(this.state.data, (value,key)  => {
							return {name: key,
							pem: Math.round(_.reduce(_.map(value,(row) => parseFloat(row.subtotal)), (x,y) => x+y,0)*100)/100,
							pec: Math.round( _.reduce(_.map(value,(row) => parseFloat(row.subtotal*100 * (1-row.baja/100) * (1+row.gg/100+row.bi/100) * (1+row.igic/100))), (x,y) => x+y,0))/100,
							detalles: this.state.detalles,
							partes: this.state.detalles 
								? _.map(value, o => _.extend({pem: Math.round(parseFloat(o.subtotal)*100)/100, pec:Math.round( parseFloat(o.subtotal*100 * (1-o.baja/100) * (1+o.gg/100+o.bi/100) * (1+o.igic/100)))/100}, o)) 
								: []
						}});
		const totalpem = Math.round(_.reduce(_.map(this.state.data,(value) => _.reduce( _.map(value,(row) => parseFloat(row.subtotal)), (x,y) => x+y,0)), (n,m) => n+m,0)*100)/100;
		const totalpec = Math.round(_.reduce(_.map(this.state.data,(value) => _.reduce( _.map(value,(row) => parseFloat(row.subtotal) * (1-row.baja/100) * (1+row.gg/100+row.bi/100) * (1+row.igic/100) ), (x,y) => x+y,0)), (n,m) => n+m,0)*100)/100;
		const inicio = this.state.inicio.format("DD-MM-YYYY");
		const fin = this.state.fin.format("DD-MM-YYYY");

		IO.axios({url:'/templates/download.php?file=economico_material.docx',responseType: 'arraybuffer'}).then(response => {
			const template = response.data;
			var node = document.getElementById('htmltoprint');	
			domtoimage.toPng(node).then(function (dataUrl) {
				const data = dataUrl.slice('data:image/png;base64,'.length);	
				createReport({
					template,
					data: { title: 'Informe económico por material', centros, totalpec, totalpem, inicio, fin },
					additionalJsContext: {image: url => {if( graf ) return { width: 18, height: 7, data, extension: '.png' }}}
				  }).then( docx => {
					let blob = new Blob([docx], { type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document' } );
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = "economico_material.docx";
					link.click();
					document.body.style.cursor = '';
				  });
			}).catch(error => {document.body.style.cursor = '';throw error});

		}).catch(error => {document.body.style.cursor = '';throw error});
	 }


	/**
	 * Filtramos sobre el inventariado en función del tipo de material del componente
	 * YCSFormFieldTipoDeMaterial_v2.jsx
	 * @param {Object} name is a string, value is always a buffer 
	 */
	handleItemSelected = ({ name, value }) => {
		const {dataBackup} = this.state;
		let groupMaterial = {};
		if(value.length !== 0) {
			let informeFiltrado = _.filter(dataBackup, e => {
				let resultAux = _.map(value, tipo => {
					if(e[0].titulo === tipo){
						return e
					}
				});

				let resultCleaned = _.filter(resultAux, e => e !== undefined);
				if(resultCleaned.length > 0){
					return resultCleaned;
				}
			});

			for(let i = 0;  i< informeFiltrado.length; i++){
				groupMaterial[informeFiltrado[i][0].titulo] = informeFiltrado[i];
			}
			this.setState({ 
				data: informeFiltrado.length > 0? groupMaterial : informeFiltrado,
				grahpdata: informeFiltrado.length > 0? this.groupMaterialMethod(groupMaterial) : informeFiltrado
			});
		}else{
			this.setState(state => ({ 
				data: state.dataBackup,
				grahpdata: state.grahpdataBackup
			}));
		}
	}

	render(){
		const totalpem = Math.round(_.reduce(_.map(this.state.data,(value) => _.reduce( _.map(value,(row) => parseFloat(row.subtotal)), (x,y) => x+y,0)), (n,m) => n+m,0)*100)/100;
		const totalpec = Math.round(_.reduce(_.map(this.state.data,(value) => _.reduce( _.map(value,(row) => parseFloat(row.subtotal) * (1-row.baja/100) * (1+row.gg/100+row.bi/100) * (1+row.igic/100) ), (x,y) => x+y,0)), (n,m) => n+m,0)*100)/100;
		// console.log("this.state InformeMaterial.js: ", this.state);

		return(
			<Segment raised>
				<Label ribbon content="Informe económico por Material"/>			
				<Form>
					<Form.Group width='equal' >
						<Form.Field control={DatePicker} dateFormat="DD/MM/YYYY" selected={this.state.inicio} label='Fecha Inicial' onChange={this.handleInicio}/>
						<Form.Field control={DatePicker} dateFormat="DD/MM/YYYY" selected={this.state.fin} label='Fecha Final' onChange={this.handleFin} />

						<div style={{ width: '200px'}} >
							<Form.Field control={YCSFormFieldTipoDeMaterial_v2}  label='Tipo de material'  handleItemSelected={this.handleItemSelected}/>
						</div>

						<Form.Button circular icon='download' size='mini' onClick={this.descargar}/>
						<Form.Checkbox toggle label='Detalles' checked={this.state.detalles} name='detalles' onChange={this.handleCheckChange}/>
						<Form.Checkbox toggle label='Gráfica' checked={this.state.grafica} name='grafica' onChange={this.handleCheckChange}/>
					</Form.Group>
				</Form>

				{_.map(this.state.data,(value,key) =>
					<Table key={key} structured compact>
						<Table.Header>
						<Table.Row>
							<Table.HeaderCell colSpan='4'>{key}</Table.HeaderCell>
							<Table.HeaderCell colSpan='2' textAlign='right'>
								<Label className='derecha' color='olive' style={{width:'120px', textAlign:'center', marginLeft:'5px'}}>
									<NumberFormat value={Math.round( _.reduce(_.map(value,(row) => parseFloat(row.subtotal*100 * (1-row.baja/100) * (1+row.gg/100+row.bi/100) * (1+row.igic/100))), (x,y) => x+y,0))/100}
													displayType={'text'} thousandSeparator={' '}  prefix='PEC: ' 
													decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>		
								<Label className='derecha' color='teal'  style={{width:'120px', textAlign:'center'}}>
									<NumberFormat  value={Math.round(_.reduce(_.map(value,(row) => parseFloat(row.subtotal)), (x,y) => x+y,0)*100)/100}
												displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
												decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>
							</Table.HeaderCell>
						</Table.Row>
						{this.state.detalles && <Table.Row>
							<Table.HeaderCell>Centro</Table.HeaderCell>
							<Table.HeaderCell>Empresa</Table.HeaderCell>							
							<Table.HeaderCell>Incidencia</Table.HeaderCell>
							<Table.HeaderCell>Parte</Table.HeaderCell>
							<Table.HeaderCell>Subtotal</Table.HeaderCell>
						</Table.Row>}
						</Table.Header>
						{this.state.detalles &&
						_.map(value,(row,index) => 
						<Table.Body key={index}>
							<Table.Row>
								<Table.Cell>{row.nombre}</Table.Cell>
								<Table.Cell>{row.empresa}</Table.Cell>
								<Table.Cell>{row.incidencia}</Table.Cell>
								<Table.Cell>{row.codigo + ' ('+moment(row.fecha,'YYYY-MM-DD').format('DD/MM/YYYY')+')'}</Table.Cell>
								<Table.Cell>
									<Label basic className='derecha' color='olive' style={{width:'120px', textAlign:'center', marginLeft:'5px'}}>
										<NumberFormat value={Math.round( parseFloat(row.subtotal*100 * (1-row.baja/100) * (1+row.gg/100+row.bi/100) * (1+row.igic/100)))/100}
														displayType={'text'} thousandSeparator={' '}  prefix='PEC: ' 
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
									</Label>		
									<Label basic className='derecha' color='teal'  style={{width:'120px', textAlign:'center'}}>
										<NumberFormat  value={Math.round(parseFloat(row.subtotal)*100)/100}
													displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
													decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
									</Label>
								</Table.Cell>
							</Table.Row>
						</Table.Body>
						)}
					</Table>
				)}

				{totalpem >0 &&
				<Table structured compact>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell with={13} textAlign='right'>Total</Table.HeaderCell>
							<Table.HeaderCell with={3} textAlign='right'>
							
								<Label className='derecha' color='olive' style={{width:'130px', textAlign:'center', marginLeft:'5px'}}>
									<NumberFormat value={totalpec} displayType={'text'} thousandSeparator={' '}  prefix='PEC: ' 
													decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>		
								<Label className='derecha' color='teal'  style={{width:'130px', textAlign:'center'}}>
									<NumberFormat value={totalpem} displayType={'text'} thousandSeparator={' '}  prefix='PEM: '
													decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
								</Label>
							
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
				</Table>
				}

				<div id="htmltoprint">
				{this.state.grafica && <PieChart width={800} height={Math.max(300,this.state.grahpdata.length * 19)}>
					<Pie data={this.state.grahpdata} outerRadius={150}>
					{this.state.grahpdata.map((value,index) => <Cell fill={COLORS[index % COLORS.length]}/>)}
					</Pie>
					<Tooltip formatter={(value, name, props) =>{return "PEM:"+value+";  PEC:"+props.payload.pec}}/>
					<Legend layout='vertical' align='right' verticalAlign='top'/>
				</PieChart>}
				</div>
			</Segment>
		)
	}
}

export default InformeMaterial