import _ from 'lodash';
import React, { Component } from 'react';
import {Segment, Label, Statistic} from 'semantic-ui-react';
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,LineChart, Line} from 'recharts';
import {IO} from './js/IO'
import moment from 'moment';
import {NuevaIncidencia} from './Incidencias';
import {Storage} from './js/storage'
import {Link } from 'react-router-dom';

class HomeAdministracion extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			usuario: Storage.getStorage('user'),
			empresageneral: this.props.empresageneral
		};
	 };
	 componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.getData());
	}
	getData = () =>{
		IO.axios.get('/data/homeadministracion',{params: {
			empresa: (this.state.empresageneral!== -1 ? this.state.empresageneral : null),
			}
		}).then(response => {
				//agrupo por empresas
				var empresas = {};
				_.forEach(	_.map(	_.groupBy(response.data.todas, 'nombre'), 
									(value,key) =>{return {[key]:JSON.parse("{"+_.map(value,(v) => ('"'+v.estado+'":'+v.total)).join(',')+"}")}}),
							(v,k) =>{ _.forEach(v,(i,j) => empresas[j] = i)});
				//creo la secuencia del ultimo mes
				var cambios = response.data.cambios;
				
				_.forEach(empresas, (e,i) =>{
					var datos = [{name: moment().format('DD/MM/YYYY'), NUEVA: 0, RESUELTA: 0, RECHAZADA: 0, REABIERTA:0, VALORADA:0}];
					datos[0].NUEVA += e.NUEVA ? e.NUEVA : 0; 
					datos[0].RESUELTA += e.RESUELTA ? e.RESUELTA : 0; 
					datos[0].RECHAZADA += e.RECHAZADA ? e.RECHAZADA : 0;
					datos[0].REABIERTA += e.REABIERTA ? e.REABIERTA : 0; 
					datos[0].VALORADA += e.VALORADA ? e.VALORADA : 0;
					e['id'] = _.filter(response.data.todas,{nombre:i})[0].empresa_id;
					e['nuevas'] = _.reduce(_.filter(cambios, {	estadoNuevo:'NUEVA', empresa_id:e['id']}
													),(a,b)=>a +parseInt(b.total,10),0);
					e['ejecutadas'] = _.reduce(_.filter(cambios,{estadoAntiguo:'NUEVA', empresa_id:e['id']}
													),(a,b)=>a +parseInt(b.total,10),0);
					for (var ii = 1; ii <= 30; ii++) {
						var dia = _.clone(datos[0]);
						dia.name =  moment().subtract(ii,'days').format('DD/MM/YYYY');
						var datosdia = _.filter(cambios,{fecha:moment().subtract(ii-1,'days').format('YYYY-MM-DD'), empresa_id:e['id']})
						_.forEach(datosdia , (e) => {
							dia[e.estadoNuevo] -= parseInt(e.total,10); 
							dia[e.estadoAntiguo] += parseInt(e.total,10); 
						});
						datos.unshift(dia);		
					}
					e['datos' ] = datos;
				})
				
				this.setState({empresas});					
		});
	 }
	componentDidMount() {
		this.getData();
	 }
	render() {
		return (
			<div style={{marginTop: "14px"}}>			
				{this.state.usuario.roles.IMD && <NuevaIncidencia updateList={this.getData} empresageneral={this.props.empresageneral}/>}
				{_.map(this.state.empresas, (value,key) => 
					<Segment raised key={key}>
						<Label attached='top' content={key} />
						<ResponsiveContainer height={200} width="100%">
							<LineChart data={value.datos} margin={{top: 10, right: 30, left: 0, bottom: 10}}>
								<XAxis dataKey="name"/>
								<YAxis/>
								<CartesianGrid strokeDasharray="3 3" height={36}/>
								<Tooltip/>		{/* Cuando se pasa el ratoón por encima de la gráfica se visualiza un menú de legend*/}
								<Legend margin={{ top: 10, left: 0, right: 0, bottom: 10 }}/>
								<Line type="monotone" dataKey="NUEVA" stroke="green" dot={<div>·</div>}/>
								<Line type="monotone" dataKey="RESUELTA" stroke="orange" dot={<div>·</div>}/>
								<Line type="monotone" dataKey="RECHAZADA" stroke="black" dot={<div>·</div>}/>
								<Line type="monotone" dataKey="REABIERTA" stroke="blue" dot={<div>·</div>}/>
								<Line type="monotone" dataKey="VALORADA" stroke="olive" dot={<div>·</div>}/>
							</LineChart>
						</ResponsiveContainer>
						<Statistic.Group widths='five'>
							<Statistic>
								<Statistic.Value as={Link} to={{pathname:'/incidencias/',search:'?empresa='+value.id+'&estado=nueva%2Creabierta&dashboard-tab=true'}}>
									{(value.NUEVA ? value.NUEVA : 0) + (value.REABIERTA ? value.REABIERTA :0)}
								</Statistic.Value>
								<Statistic.Label>Pendientes de ejecutar</Statistic.Label>
							</Statistic>
							<Statistic>
								<Statistic.Value as={Link} to={{pathname:'/incidencias/',search:'?empresa='+value.id+'&estado=rechazada&dashboard-tab=true'}}>{value.RECHAZADA ? value.RECHAZADA : 0}</Statistic.Value>
								<Statistic.Label>Rechazadas</Statistic.Label>
							</Statistic>
							<Statistic>
								<Statistic.Value as={Link} to={{pathname:'/incidencias/',search:'?empresa='+value.id+'&estado=resuelta%2Cvalorada&dashboard-tab=true'}}>{(value.RESUELTA ? value.RESUELTA : 0) + (value.VALORADA ? value.VALORADA : 0)}</Statistic.Value>
								<Statistic.Label>Pendientes de certificar</Statistic.Label>
							</Statistic>
							<Statistic color='green'>
								<Statistic.Value>{value.nuevas}</Statistic.Value>
								<Statistic.Label>Creadas último mes</Statistic.Label>
							</Statistic>
							<Statistic color='green'>
								<Statistic.Value>{value.ejecutadas}</Statistic.Value>
								<Statistic.Label>Ejecutadas último mes</Statistic.Label>
							</Statistic>
						</Statistic.Group>
					</Segment>
				)}
			</div>	
		);
  }
}

export default HomeAdministracion;
