import React, {useState, useEffect} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import {oficios} from '../js/constdata';

export function YCSFormFieldOficios(props){
    const [myOficios, setOficio] = useState([]);
    useEffect(() => {
        setOficio(oficios);
    }, []);
    
    return (
        <Form.Field
            {...props} control={Dropdown}  placeholder='Seleccione' 
             fluid selection search name='oficio' options={myOficios}
            value={props.value} onChange={props.onChange} 
        />
    );
}
