import _ from 'lodash';
import React, { Component } from 'react';
import { Segment, Button, Input, Message, Confirm, Grid, Modal, Form} from 'semantic-ui-react';
import {IO} from './js/IO'
import {Treebeard} from 'react-treebeard';
import tree from './js/treebeard'

class Equipamientos extends Component {	
	constructor(props) {
		super(props);
		this.state = { 
			cursor: {},
			tipos:[],
			open_delete: false,
			message_delete: false
		};
	}
	handleOpenDelete = () => this.setState({ open_delete : true, message_delete:false})
	handleCancelDelete = () => this.setState({ open_delete : false, message_delete:false})
	delete = () => {
		IO.axios({
			url:'/data/tipomaterial.php/'+this.state.cursor.key, 
			method: 'DELETE'})
			.then(response => {
				if (response.data.error === 0){
					this.setState({ cursor:{}, tipos:[], message_delete:false, open_delete:false },this.getData);
				} else{
					this.setState({message_delete:true, open_delete:false}, this.getUsers);
				}
			});
	}
	getData = () =>{
		IO.axios.get('/data/tipomaterial')
		.then(response => {
			var tipos = JSON.parse(_.replace(JSON.stringify(response.data),/title/g,'name'));
			tipos = _.forEach(tipos, (t) => this.setCode(t));
			this.setState({tipos, padres:_.cloneDeep(tipos)});
		});
	 }
	componentDidMount() {
		this.getData();
	 }
	setCode = (node) =>{
		if (node.children){
			_.forEach(node.children, (c) => this.setCode(c));
		}
		node.name = '[' + node.codigo+ '] '+node.name ;
	 }
	onToggle = (node, toggled) =>{
		
		if(this.state.cursor){
			var cursor = this.state.cursor;
			cursor.active = false;
			cursor.toggled = _.filter(cursor.children,{key:node.key}).length > 0 ? true : false;
			this.setState({cursor:cursor});
		}
        node.active = true;
        if(node.children){ node.toggled = toggled; }
        this.setState({ cursor: node, message_delete:false });
     }

	
	render() {
		const { tipos, cursor, padres } = this.state;
		return (
			<Grid container style={{ marginTop: '3em' }}>
				<Grid.Row>
					<Button.Group >
						<CreateEquipamiento update={this.getData} tipos={padres}
							trigger={<Button icon='file' content='Nuevo' primary/>} />
						<CreateEquipamiento update={this.getData} tipos={padres} method='PUT' data={cursor}
							trigger={<Button icon='edit' content={'Editar '+ (cursor.codigo ? cursor.codigo : '')} primary disabled={!cursor.codigo} />} />
						<Button icon='trash' content={'Eliminar '+ (cursor.codigo ? cursor.codigo : '')} primary disabled={!cursor.codigo} onClick={this.handleOpenDelete}/>
					</Button.Group>
					<Confirm
						open={this.state.open_delete}
						content='¿Seguro que desa elminar el tipo de material?'
						onCancel={this.handleCancelDelete}
						onConfirm={this.delete}
						/>
				</Grid.Row>
				<Grid.Row>
					{this.state.message_delete && <Message fluid warning
									header='No se ha podido eliminar el tipo de material.'
									content='No es posible eliminar si existe algún inventario del tipo que intenta eliminar.'
									/>}
					<Segment raised style={{width:'100%'}}>
						<Treebeard data={tipos} onToggle={this.onToggle} style={tree}/>
					</Segment>
				</Grid.Row>
			</Grid>
				
					
				
		);
  }
}

export default Equipamientos;


class CreateEquipamiento extends Component {
	constructor(props) {
		  super(props);
		  this.state = {
			  open: false,
			  nombre: (this.props.data ? this.props.data.nombre : ''),
			  codigo: (this.props.data ? this.props.data.codigo : ''),
			  cursor: (this.props.data ? this.props.data.cursor : null),
			  id: (this.props.data ? this.props.data.hey : null),
			  invalidate : null
		  };
	  };
  
	handleChange = (e, { name, value }) => {
		  this.setState({ [name]: value });
		  if (this.state.nombre && this.state.codigo) {
			  this.setState({invalidate:false});
		  }
	   }
	createEquipamiento = () => {
		  if (this.state.nombre && this.state.codigo) {
			  this.setState({invalidate:false});
			  var allFields = '{ "codigo":"'+this.state.codigo+
					  '", "nombre":"' +this.state.nombre+
					  '", "padre":"'+(this.state.cursor ? this.state.cursor.key : '')+'"}';
			  IO.axios({
				  url:'/data/tipomaterial.php/'+(this.props.method === 'PUT' ? this.state.id : ''), 
				  method: (this.props.method ? this.props.method : 'POST'),
				  data: allFields})
				  .then(response => {
							  this.props.update();	
							  this.resetComponent();
				  });
		  }else{
			  this.setState({invalidate:true});
		  }
	   }
	findParent = (tipos,key) => {
		_.forEach(tipos,(item)=>{
			item.active = false;
			item.toggled = false;
		})
		for( var item of tipos){
			if(item.key === key){return null;}
			if(item.children){
				if(_.filter(item.children,{key:key}).length > 0 ){
					item.active = true;
					item.toggled = true
					return item;
				}
				var result = this.findParent(item.children,key);
				if(result){
					item.toggled = true; 
					return result;
				}
			}
		}
	 }
	componentWillReceiveProps(next){
		if(!next.data || next.data === this.props.data) return	
		this.setState({
			nombre: (next.data.name ? next.data.name.replace('['+next.data.codigo+'] ','') : next.data.name),
			codigo: next.data.codigo,
			id: next.data.key,
			cursor: this.findParent(next.tipos,next.data.key),
		});
	 }
	resetComponent = () => this.setState({open: false, nombre: null, codigo:null, cursor: null, invalidate : null, id:null})
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	onToggle = (node, toggled) =>{
		  if(this.state.cursor){
			  var cursor = this.state.cursor;
			  cursor.active = false;
			  cursor.toggled = _.filter(cursor.children,{key:node.key}).length > 0 ? true : false;
			  this.setState({cursor:cursor});
		  }
		  node.active = true;
		  if(node.children){ node.toggled = toggled; }
		  this.setState({ cursor: node });
	  }
	render() {
	  const { open, invalidate, nombre, codigo, cursor } = this.state
  
	  return (
		<Modal open={open} onOpen={this.open} onClose={this.close} size='small'
		  trigger={this.props.trigger}
		>
		  <Modal.Header>Crear Equipamiento</Modal.Header>
		  <Modal.Content>
				<Form error={invalidate}>
					<Form.Field control={Input} value={nombre} label='Nombre' name='nombre' onChange={this.handleChange} error={!nombre && invalidate} />
					<Form.Field control={Input} value={codigo} label='Código' name='codigo' onChange={this.handleChange} error={!codigo && invalidate} />
					<Form.Field label={'Padre : '+ (cursor ? cursor.name: '')} control={Treebeard} data={this.props.tipos} onToggle={this.onToggle} style={tree}/>
					<Message
						error
						header='Error en formulario'
						content='Complete todos los campos.'
					/>
				</Form>
		  </Modal.Content>
		  <Modal.Actions>
					  <Button  content='Aceptar' onClick={this.createEquipamiento} />
					  <Button  content='Cancelar' onClick={this.resetComponent} />
		  </Modal.Actions>
		</Modal>
	  )
	 }
  }