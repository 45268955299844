import _ from 'lodash';
import {Rabbit, enc, SHA256} from 'crypto-js';
import {IO} from './IO';
const cifrate = process.env.REACT_APP_CIFRATE === 'true' ? true: false;		// Todo las variables de .env son strings

const aa='aserejejadeje';
class Storage {
	static getStorage = (key) => {
		if(typeof(Storage)!=="undefined"){
			var obj = cifrate ? sessionStorage.getItem(SHA256(key).toString())
							  : sessionStorage.getItem(key);

			if (obj)
				return cifrate ? JSON.parse(Rabbit.decrypt(obj,aa).toString(enc.Utf8))
							   : JSON.parse(obj)
			else return null;
		}else {
			if (typeof(window[key]) !== "undefined" && window[key]!=null){
				return window[key];
			}
			return null;
		}
	}
	static setStorage = (key, value) => {
		if(typeof(Storage)!=="undefined"){
			if (cifrate)
				sessionStorage.setItem(SHA256(key).toString(),Rabbit.encrypt(JSON.stringify(value),aa).toString());
			else
				sessionStorage.setItem(key,JSON.stringify(value));
		}else {
			window[key] = value;
		}
	}
	static removeStorage = (key) => {
		if(typeof(Storage)!=="undefined"){
			if (cifrate)
				sessionStorage.removeItem(SHA256(key).toString());
			else
				sessionStorage.removeItem(key);
		}else {
			window[key] = null;
		}
	}
	static removeAll = (ke) => {
		if(typeof(Storage)!=="undefined"){
				sessionStorage.clear();
		}
	}
}

class StorageUtils {
	static loadEmpresas = () => { 
		!Storage.getStorage("empresas") &&  
		IO.axios.get('/data/empresa')
			.then(response => {
				var empresas = _.map(response.data, o => _.extend({key: o.id, text:'['+o.servicio_desc +'] '+o.nombre, value:o.id}, o)); 
				var empresas_tn = _.filter(empresas, (value) => {return value.incidencias==="1"}); 
				Storage.setStorage("empresas", empresas_tn);
				var empresas_ac = _.filter(empresas, (value) => {return value.activa==="1" && value.id!=="0"}); 
				Storage.setStorage("empresas_menu", empresas_ac);
			});
	}
	static loadCentros = (empresa, force) => { 
		(!Storage.getStorage("centros_todos") || force) &&  
		IO.axios.get('/data/centros_id',{
			params:{
				empresa: (empresa!== -1 ? empresa : null)
			}
		}).then(response => {
				var todos = _.map(response.data, o => _.extend({key: o.id, text:o.nombre, value:o.id}, o)); 
				var padres = _.filter(todos, (value) => {return !value.centro_id});
				todos = _.concat([{key: null, text:' ', value:null}],todos)
				Storage.setStorage("centros_todos", todos);
				Storage.setStorage("centros_padres", padres);
			});
	}
	static  loadUsers = () => { 
		if(!Storage.getStorage("usuarios"))
			return IO.axios.get('/data/users')
				.then(response => {
					var usuarios = _.map(response.data, o => 
						_.extend({key: o.id, text:o.nombre+' '+o.apellidos, value:o.id}, o));
					Storage.setStorage("usuarios", usuarios);
					var inspectores = _.filter(usuarios, (value) => 
							{return value.activo=="1" && _.filter(value.roles,(rol) => {return rol.nombre==="INSPECTOR"}).length > 0});
					Storage.setStorage("inspectores", inspectores);
					var auxiliares = _.filter(usuarios, (value) => 
							{return value.activo=="1" && _.filter(value.roles,(rol) => {return rol.nombre==="AUXILIAR"}).length > 0});						
					auxiliares.push({key:'-1', text:'Otros ...', value:'-1'});
					auxiliares = _.concat([{key: null, text:' ', value:null}],auxiliares)
					Storage.setStorage("auxiliares", auxiliares);
					var operarios = _.filter(usuarios, (value) => 
							{return value.activo=="1" && _.filter(value.roles,(rol) => {return rol.nombre==="OPERARIO"}).length > 0});
					Storage.setStorage("operarios", operarios);
				});
		return Promise.resolve();
	}
}

export {Storage, StorageUtils}