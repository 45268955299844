/**
 * Filter properties (by type) in object of first level according to several parameters, e.g.
 * filterQuery( 
 * {
    empresa: "",
    fecha: "2022-06-29",
    isla: null,
    driver: undefined,
    filters: []
    },
    [null, "", 0, undefined, []],
    ["offset"]
    );
* 
* 
* @param {Object} queryObject 
* @param  {array} values buffer with values to delete
* @param  {string[]} properties buffer with properties to deleted
* @returns {Object} result new filtered query object
*/
export const filterQuery = (queryObject = {}, values = [], properties = []) => {
    const newQueryObject = JSON.parse(JSON.stringify(queryObject));
    values.forEach(item => {
        for(const property in newQueryObject){
            if(newQueryObject[property] === item){
                delete newQueryObject[property];
            }
            if(Array.isArray(item) && Array.isArray(newQueryObject[property])){
                delete newQueryObject[property];
            }
        }        
    });
    properties.forEach( item => {
        delete newQueryObject[item];
    });
    return newQueryObject;
}

/**
 * Parse string query to object with queries
 * @param {string} queryString e.g. ?numero=1&empresa=c&fin=30&pec=1&total=2&estado=3&fecha=fecha&inicio=inicio
 * @returns {Object} object with queries
 */
export const getQueries = (queryString) => {
    const query = new URLSearchParams(queryString);
    const queryAux = {}
    query.forEach(function(value, key) {
        queryAux[key] = value;
    });
    return queryAux;
}

/**
 * En función del type se usará para url o endpoint.
 * type = "url" con encodeURIComponent
 * type= = "endpoint" sin encodeURIComponent
 * @param {object} obj objeto que será convertido en query
 * @param {string} type "url" | "endpoint"
 * @returns {string} query string ?query1=abc&query2=def...
 */
export const objToQueryString = (obj, type= "url") => {
    const keyValuePairs = [];
    if(type === "url") {
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return '?'+keyValuePairs.join('&');
    }else if(type === "endpoint"){
        for (const key in obj) {
            keyValuePairs.push(key + '=' + obj[key]);
            }
        return '?'+keyValuePairs.join('&');	
    }
}