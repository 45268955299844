import React, { Component } from 'react';
import { Dropdown, Container, Image, List, Menu, Segment, Icon, Grid, Search, Label } from 'semantic-ui-react';
import Home from './Home';
import {Avisos} from './Avisos';
import {Incidencias} from './Incidencias';
import Instalaciones from './Instalaciones';
import Facturacion from './Facturacion/Facturacion';
import Informes from './Informes';
import Empresas from './Empresas';
import Usuarios from './Usuarios';
import Equipamientos from './Equipamientos';
import Precios from './Precios';
import Perfil from './Perfil';
import Preventivo from './Preventivo';
import {DetalleIncidencia} from './DetalleIncidencia';
import DetalleInstalacion from './DetalleInstalacion';
import DetalleMaterial from './DetalleMaterial';
import DetalleCertificacion from './Facturacion/DetalleCertificacion';
import { Pedidos } from './Pedidos'; 
import { DetallePedido } from './DetallePedido'; 
import {Route, Link} from 'react-router-dom';
import {StorageUtils, Storage} from './js/storage';
import {Cookies, Legal, Contacto, Versiones} from './Legales';
import _ from 'lodash';
import {IO} from './js/IO';
import {version} from './js/constdata'; 
import styled from 'styled-components';
import { AppContext } from './context/AppContext';

class GeneralLayout extends Component {	
	static contextType = AppContext;
	cacheParte = {};
	breakSizeScreen = 980;

	constructor(props) {
		super(props);
		this.state = {
			activeItem: 'home',
			user: this.props.user,
			empresageneral: -1,
			empresas: [],
			widthScreenMobile : false,	// Si el size of viewport < 1024px estamos en movíl (true) y pasamos de navbar a sidebar	
			openDropdown: false			// true = open, false = close
		};
		this.handleItemClick = this.handleItemClick.bind(this);
	}

	componentDidMount(){
		StorageUtils.loadEmpresas();
		StorageUtils.loadCentros(-1);
		StorageUtils.loadUsers();

		IO.axios.get('/data/empresa').then(response => {
			var empresas = _.map(response.data, o => _.extend({key: o.id, text:'['+o.servicio_desc +'] '+o.nombre, value:o.id}, o)); 
			var empresageneral = (empresas.length === 1) ? empresas[0].key : -1;

			empresas = _.concat([{key: -1, text:'TODOS', value:-1}], Storage.getStorage('empresas_menu') );
			this.setState({empresas, empresageneral});
		});
		IO.axios.get('/data/configuracion?clave=version').then(response => {
			if (version!==response.data[0].valor) {
				throw "Está usando la version "+version+" y en el servidor está disponible la "+response.data[0].valor+
						". Para actualizar vaya a la página inicial y presione CTRL + R"
			}
		});	

		this.setState({ widthScreenMobile: screen.width < this.breakSizeScreen? true : false});
		window.addEventListener('resize', this.calculateSizeWindow);

		this.searchNumberOfWarnings();
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.empresageneral != this.state.empresageneral){
			this.searchNumberOfWarnings();
		}
	}

	/**
	 * Mostramos el número de avisos en función de la empresa seleccionada tanto en componentDidMount como en componentDidUpdate
	 */
	searchNumberOfWarnings = () => {
		const {setNumberOfWarnings} = this.context;
		IO.axios.get('/data/avisos', {
			params: {
				empresa: this.state.empresageneral === -1 ? null : this.state.empresageneral
			}	
		}).then(response => {
			setNumberOfWarnings( response.data.length );
		});
	}

	/**
	 * Calculamos el ancho de la pantalla en píxeles para mostrar el sidebar o el menú
	 * @returns void
	 */
	calculateSizeWindow = () => this.setState(state => ({ 
		widthScreenMobile: window.innerWidth < this.breakSizeScreen? true : false,
		openDropdown:  window.innerWidth < this.breakSizeScreen? state.openDropdown : false
	}));

	componentWillUnmount(){
		window.removeEventListener('resize', this.calculateSizeWindow);
	}

	handleItemClick = (e, { name }) => this.setState({ activeItem: name });

	handleChange = (e, {name,value}) => this.setState({empresageneral:value}, StorageUtils.loadCentros(value, true));

	handleSearchChange = (e, { value }) => {
		if (value.length < 4) return
		this.setState({ isLoading: true, articulo: {} });
		if ( value in this.cacheParte ) {
			this.setState({ isLoading: false, results: this.cacheParte[ value ]});
		}else {
			IO.axios.get('/data/search.php', {params: {valor: value}})
			.then(response => {
				var incidencias = _.map(response.data,(inc) => {return {	
						title:'['+inc.id+'] '+ inc.centro + " ("+inc.estado+")",
						key:inc.id, 
						description: inc.descripcion.length>20 ? (inc.descripcion.substring(0,20)+" ..."):inc.descripcion }})
				this.setState({ isLoading: false, results: incidencias});
				this.cacheParte[ value ] = incidencias;
			});
		}
	}
	handleResultSelect = (e, { result }) => { 
		window.location.href = '/incidencias/'+result.key;
	}

	handleOpenOrCloseDropdown = () => this.setState(state => ({ openDropdown: !state.openDropdown }));

	render() {
		const {user, empresageneral, empresas, widthScreenMobile, openDropdown} = this.state;
		const {numberOfWarnings} = this.context;
		// console.log("this.state GeneralLayout.jsx: ", this.state);
		return (
			<>
				<div style={{ marginTop: "44px", minHeight: "calc(100vh - 164px)", padding: "15px 0px 15px 0px"}}>
					<Route exact path="/" render={(props) => ( <Home {...props} empresageneral={empresageneral}/> )}/>
					<Route path={`/incidencias/:Id`} render={(props) => ( <DetalleIncidencia {...props} empresageneral={empresageneral}/> )}/>
					<Route path={`/instalaciones/:Id/:section`} render={(props) => ( <DetalleInstalacion {...props} empresageneral={empresageneral}/> )}/>
					<Route path={`/instalaciones/:Id`} exact render={(props) => ( <DetalleInstalacion {...props} empresageneral={empresageneral}/> )}/>
					<Route path={`/material/:Id`} render={(props) => ( <DetalleMaterial {...props} empresageneral={empresageneral}/> )}/>
					<Route path={`/facturacion/:Id`} render={(props) => ( <DetalleCertificacion {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/empresas'} render={(props) => ( <Empresas {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/usuarios'} render={(props) => ( <Usuarios {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/equipamientos'} render={(props) => ( <Equipamientos {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/precios'} render={(props) => ( <Precios {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/perfil'} render={(props) => ( <Perfil {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/avisos'}  render={(props) => ( <Avisos {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/incidencias'}  render={(props) => ( <Incidencias {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/preventivo'}  render={(props) => ( <Preventivo {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/instalaciones'}  render={(props) => ( <Instalaciones {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/facturacion'}  render={(props) => ( <Facturacion {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/informes'}  render={(props) => ( <Informes {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/pedidos_ferreteria'}  render={(props) => ( <Pedidos {...props} empresageneral={empresageneral}/> )}/>
					<Route exact path={'/pedidos/:Id'}  render={(props) => ( <DetallePedido {...props} empresageneral={empresageneral}/> )}/>					
				</div>

				<Menu fixed='top' inverted size='mini'>
					<Menu.Item as={Link} to='/' header name='home' active={window.location.href.indexOf('home') !==-1 } 
							onClick={this.handleItemClick} color='blue'>
						<Image size="tiny" spaced="right"  src="../gmanti.png"/>
						{/* <Image size="tiny" spaced="right"  src={baseUrl+'/images/'+user.logo}/> */}
					</Menu.Item>

					{/* Renderizamos un menú u otro en función del size of viewport */}
					{widthScreenMobile?
						<Dropdown button className="icon" icon="bars" open={openDropdown} 
							onClick={this.handleOpenOrCloseDropdown} onBlur={() => this.setState({ openDropdown: false })}
							style={{ background: "#1b1c1d", color : "white", lineHeight: "20px" }}
						>
							<Dropdown.Menu>
								{(user.servicio || user.administracion) && <Dropdown.Item as={Link} to={'/avisos'} name={'avisos'}
									active={window.location.href.indexOf('avisos') !==-1 } 
									onClick={this.handleItemClick.bind(this)}
								>
									<Icon name="warning sign" /> Avisos
								</Dropdown.Item>}

								{(user.servicio || user.administracion) && <Dropdown.Item as={Link} to={'/incidencias'} name={'incidencias'} 
									active={window.location.href.indexOf('incidencias') !==-1 } 
									onClick={this.handleItemClick.bind(this)} color='blue'
								>
									<Icon name="warning sign" /> Incidencias
								</Dropdown.Item>}

								{(user.servicio || user.administracion) && <Dropdown.Item as={Link} to={'/preventivo'} name={'preventivo'} 
									active={window.location.href.indexOf('preventivo') !==-1 } 
									onClick={this.handleItemClick.bind(this)} color='blue'
								>
									<Icon name="calendar" /> Preventivo
								</Dropdown.Item>
								}
								{(user.ferreteria || user.administracion) && <Dropdown.Item as={Link} to={'/pedidos_ferreteria'} name={'pedidos_ferreteria'} 
									active={window.location.href.indexOf('pedidos_ferreteria') !==-1 } 
									onClick={this.handleItemClick.bind(this)} color='blue'
								>
									<Icon name="wrench" /> Ferretería
								</Dropdown.Item>
								}
								<Dropdown.Item as={Link} to={'/instalaciones'} name={'instalaciones'} 
									active={window.location.href.indexOf('instalaciones') !==-1 } 
									onClick={this.handleItemClick.bind(this)} color='blue'
								>
									<Icon name="marker" /> Instalaciones
								</Dropdown.Item>

								<Dropdown.Item as={Link} to={'/facturacion'} name={'facturacion'} 
									active={window.location.href.indexOf('facturacion') !==-1 } 
									onClick={this.handleItemClick.bind(this)} color='blue'
								>
									<Icon name="euro" /> Facturacion
								</Dropdown.Item>

								<Dropdown.Item as={Link} to={'/informes'} name={'informes'} 
									active={window.location.href.indexOf('informes') !==-1 } 
									onClick={this.handleItemClick.bind(this)} color='blue'
								>
									<Icon name="pie chart" /> Informes
								</Dropdown.Item>

								{(user.servicio || user.administracion) && <Dropdown.Item onClick={() => this.setState({ openDropdown: false })}> 
									<Search			
										size='mini' minCharacters={4}
										loading={this.state.isLoading}
										onResultSelect={this.handleResultSelect}
										onSearchChange={this.handleSearchChange}
										results={this.state.results}
										noResultsMessage='No hay resultados'
									/>
								</Dropdown.Item>}
							</Dropdown.Menu>
						</Dropdown>
						:
						<>
							{(user.servicio || user.administracion) && <Menu.Item	as={Link} to={'/avisos'} name={'avisos'} 
													active={window.location.href.indexOf('avisos') !==-1 } 
													onClick={this.handleItemClick.bind(this)} color='blue'>
								<Icon name="warning sign" /> Avisos
								{numberOfWarnings !== 0 && <MyWarning>{numberOfWarnings}</MyWarning>}
							</Menu.Item>}
							{(user.servicio || user.administracion) && <Menu.Item	as={Link} to={'/incidencias'} name={'incidencias'} 
													active={window.location.href.indexOf('incidencias') !==-1 } 
													onClick={this.handleItemClick.bind(this)} color='blue'>
								<Icon name="warning sign" /> Incidencias
							</Menu.Item>}
							{(user.servicio || user.administracion) && <Menu.Item	as={Link} to={'/preventivo'} name={'preventivo'} 
													active={window.location.href.indexOf('preventivo') !==-1 } 
													onClick={this.handleItemClick.bind(this)} color='blue'>
								<Icon name="calendar" /> Preventivo
							</Menu.Item>}
							{(user.ferreteria || user.administracion) && <Menu.Item	as={Link} to={'/pedidos_ferreteria'} name={'pedidos_ferreteria'} 
													active={window.location.href.indexOf('pedidos_ferreteria') !==-1 } 
													onClick={this.handleItemClick.bind(this)} color='blue'>
									<Icon name="wrench" /> Ferretería
								</Menu.Item>
								}
							<Menu.Item	as={Link} to={'/instalaciones'} name={'instalaciones'} 
													active={window.location.href.indexOf('instalaciones') !==-1 } 
													onClick={this.handleItemClick.bind(this)} color='blue'>
								<Icon name='marker' /> Instalaciones
							</Menu.Item>
							<Menu.Item	as={Link} to={'/facturacion'} name={'facturacion'} 
													active={window.location.href.indexOf('facturacion') !==-1 } 
													onClick={this.handleItemClick.bind(this)} color='blue'>
								<Icon name='euro' />Facturacion
							</Menu.Item>
							<Menu.Item	as={Link} to={'/informes'} name={'informes'} 
													active={window.location.href.indexOf('informes') !==-1 } 
													onClick={this.handleItemClick.bind(this)} color='blue'>
								<Icon name="pie chart" /> Informes
							</Menu.Item>
							{(user.servicio || user.administracion) && <Menu.Item>
								<Search			
									size='mini' minCharacters={4}
									loading={this.state.isLoading}
									onResultSelect={this.handleResultSelect}
									onSearchChange={this.handleSearchChange}
									results={this.state.results}
									noResultsMessage='No hay resultados'
								/>
							</Menu.Item>}
						</>					
					}

					<Menu.Menu position='right'>		
						{user.roles.IMD && <Menu.Menu>
							<Dropdown style={{ height: "100%" }} direction='left' item inline options={empresas} defaultValue={empresageneral} onChange={this.handleChange} defaultOpen={false}/>
							</Menu.Menu>}
						
						{user && <Dropdown item text={user.nombre} >
							<Dropdown.Menu>
								<Dropdown.Item name='adm' onClick={this.handleItemClick.bind(this)} as={Link} to='/perfil'>Datos personales</Dropdown.Item>
								<Dropdown.Divider />
								{user.roles.ADMINISTRACION && <Dropdown.Item name='adm' onClick={this.handleItemClick.bind(this)} as={Link} to='/empresas'>Empresas</Dropdown.Item>}
								{user.roles.ADMINISTRACION && <Dropdown.Item name='adm' onClick={this.handleItemClick.bind(this)} as={Link} to='/usuarios'>Usuarios</Dropdown.Item>}
								{(user.servicio || user.administracion) && user.roles.ADMINISTRACION && <Dropdown.Item name='adm' onClick={this.handleItemClick.bind(this)} as={Link} to='/equipamientos'>Tipos de material</Dropdown.Item>}
								{user.roles.ADMINISTRACION && <Dropdown.Item name='adm' onClick={this.handleItemClick.bind(this)} as={Link} to='/precios'>Precios</Dropdown.Item>}
								{user.roles.ADMINISTRACION && <Dropdown.Divider />}
								<Dropdown.Item onClick={this.props.onLogout}>Cerrar</Dropdown.Item>	
							</Dropdown.Menu>
						</Dropdown>		
						}	
					</Menu.Menu>
				</Menu>
				
				<Segment inverted vertical style={{ padding: '5em 0em'}}>
					<Container textAlign='center'>
						<Grid inverted>
							<Grid.Row>
								<Grid.Column width={8} textAlign='left'>
								© Copyright Gmanti. Todos los derechos reservados.
								</Grid.Column>
								<Grid.Column width={8} textAlign='right'>
								<List horizontal inverted divided link>
									<List.Item as='a' target='blank' href='http://www.gmanti.com'>Gmanti</List.Item>
									<Legal />
									<Cookies/>
									<Contacto/>
									<Versiones/>
								</List>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Segment>
			</>
		);
  }
}

/**
 * Div para mostrar el número de avisos existentes
 */
const MyWarning = styled.div`
	background-color: red;
	color: white;
	padding: 3px 5px;
	margin-left: 10px;
	border-radius: 5px;
`;

export default GeneralLayout