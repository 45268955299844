import _ from 'lodash'
import React, { Component } from 'react';
import {Confirm, Form, Segment, Header, Label, Menu, Icon, Grid, Modal, Button, Popup} from 'semantic-ui-react';
import {IO} from '../js/IO'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ListaPartesCertificadosPorTipoVariable from './ListaPartesCertificadosPorTipoVariable';
import NumberFormat from 'react-number-format'
import {Storage} from '../js/storage'
import ListaPartesCertificadosPorTipoFijo from './ListaPartesCertificadosPorTipoFijo';
import ListaPartesCertificadosPorTipoFerreteria from './ListaPartesCertificadosPorTipoFerreteria';
class DetalleCertificacion extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			certificacion : {},
			activeItem: 'detalles',
			checked: [],
			fecha:null, inicio:null, fin:null, numero:null,
			edit: false,
			user: Storage.getStorage('user')
		};
	 };
	handleInicio = (date) => this.setState({ inicio: date })
	handleFin = (date) => this.setState({ fin: date })
	handleFecha = (date) => this.setState({ fecha: date })
	handleNumero = (e,{value}) => this.setState({ numero: value })
	handleEdit = (e) => this.setState({ edit: !this.state.edit })
	openDelete = () => this.setState({ open_delete: true })
	cancelDelete = () => this.setState({ open_delete: false })
	getPending = () =>{
		IO.axios.get('/data/certificacion.php/'+this.props.match.params['Id']+"$" 
		).then(response => {this.setState({pendiente: response.data[0]});});
	 }
	changeEstado = (e) =>{
		var estado = (this.state.certificacion.estado === "BLOQUEADA") 
					? "CONFIRMADA":  
					(this.state.certificacion.estado === "BORRADOR") 
					? "BLOQUEADA"
					:""
		IO.axios({
			url:'/data/certificacion.php/'+this.state.certificacion.id, 
			method: 'PUT',
			data: '{"estado":"'+estado+'"}'})
			.then(response => {this.getData();});
	 }
	desbloquear = (e) =>{
		IO.axios({
			url:'/data/certificacion.php/'+this.state.certificacion.id, 
			method: 'PUT',
			data: '{"estado":"BORRADOR"}'})
			.then(response => {this.getData();});
	 }
	save = () => {
		var allFields = '{ "numero":"'+this.state.numero+'", "fecha":"'+this.state.fecha.format('YYYY-MM-DD')+
						'", "inicio":"'+this.state.inicio.format('YYYY-MM-DD')+'", "fin":"'+this.state.fin.format('YYYY-MM-DD')+
						'", "partes":"'+this.state.checked+'" }';
		IO.axios({
			url:'/data/certificacion.php/'+this.state.certificacion.id, 
			method: 'PUT',
			data: allFields})
			.then(response => {
				this.getData();	
				this.setState({edit:false});
			});
	 }
	delete = () => {
		IO.axios({
			url:'/data/certificacion.php/'+this.state.certificacion.id, 
			method: 'DELETE'})
			.then(response => { this.props.history.push('/facturacion');});
	 }
	
	handleItemClick = (e, { name }) => this.setState({ activeItem: name })
	handleChecked = (checked) => this.setState({checked: checked})
	handlePendientes = (checked) => this.setState({checked: _.concat(this.state.checked,checked)})
	getData = () => {
		IO.axios.get('/data/certificacion.php/'+this.props.match.params['Id']
			).then(response => {
				var data = response.data[0];
				this.setState({ 
								certificacion: data, 
								checked:_.map(data.partes,(a) => a.id),
								fecha: moment(data.fecha,'YYYY-MM-DD'), 
								inicio: moment(data.inicio,'YYYY-MM-DD'), 
								fin: moment(data.fin,'YYYY-MM-DD'),
								numero: data.numero
							});
			});
	 } 
	componentDidMount() {
		this.getData();
	 }
	descargas = (e, { name }) => {
		const location = "/utils/printCertificacion.php?id="+this.state.certificacion.id+"&tipo="+name.toUpperCase();
		const numero = this.state.certificacion.numero;
		IO.axios({url:location, responseType:'arraybuffer'}).then(function(response) {
			let blob = new Blob([response.data], { type:   'application/'+name } )
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = "certificacion_"+numero+'.'+name
			link.click()
		});	
	 }
	descargaResumen = () => {
		const location = "/utils/printResumen.php?id="+this.state.certificacion.id;
		const numero = this.state.certificacion.numero;
		IO.axios({url:location, responseType:'arraybuffer'}).then(function(response) {
			let blob = new Blob([response.data], { type:   'application/pdf' } )
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = "resumen_"+numero+'.pdf'
			link.click()
		});	
 	 }
	render() {
		const { certificacion, activeItem, edit, numero, fecha, inicio, fin, pendiente} = this.state
		const tipo_certificacion = certificacion.tipo === "VARIABLE";		
		const total_ejecucion_material = Math.round(certificacion.total*100) / 100;
		const total_ejecucion_material_baja = Math.round(certificacion.conbaja*100) / 100;

		const baja = this.state.user.ferreteria
						? total_ejecucion_material - total_ejecucion_material_baja
						: Math.round(certificacion.total * certificacion.baja ) / 100;
		const subtotal = Math.round( (total_ejecucion_material-baja) * 100 ) / 100;
		const gastos_generales = Math.round(subtotal * certificacion.gg) / 100;
		const beneficio_industrial = Math.round(subtotal * certificacion.bi) / 100;
		const igic = Math.round((subtotal+gastos_generales+beneficio_industrial) * certificacion.igic) / 100;
		

		
		return (
			<Grid container style={{ marginTop: '1em' }}>
				<Confirm open={this.state.open_delete} 
					name='open_delete'
					content='¿Seguro que desa eliminar la certificación?'
					onCancel={this.cancelDelete} onConfirm={this.delete}
				/>
				<PartesPendientesModal certificacion={pendiente} updateChecked={this.handlePendientes} guardar={this.save}/>
				<Grid.Row>
					<Grid.Column width={4}>
						<Menu pointing vertical>
							{activeItem==='detalles' 
							?	<Menu.Item active={activeItem === 'detalles'}>
									<Menu.Header>Detalles Certificación</Menu.Header>
									{this.state.user.roles.SERVICIO && <Menu.Menu>
										{/* {certificacion.estado === "BORRADOR" && <Menu.Item onClick={this.handleEdit}>{edit ? 'Cancelar' : 'Editar'}</Menu.Item>}
										{certificacion.estado === "BORRADOR" && <Popup trigger={<Menu.Item onClick={this.save}>Guardar Cambios</Menu.Item>} 
												content='Guarda los datos modificados en la certificación'/> } */}
										{certificacion.estado === "BORRADOR" && <Menu.Item onClick={this.openDelete}>Eliminar</Menu.Item>}
										{certificacion.estado === "BORRADOR" && <Popup  trigger={<Menu.Item onClick={this.changeEstado}>Bloquear</Menu.Item>}
												content='Bloquea la certificación y no se podrá editar.'/> }
										{certificacion.estado === "BORRADOR" && tipo_certificacion && !this.state.user.ferreteria &&
											<Popup trigger={<Menu.Item onClick={this.getPending}>Importar partes</Menu.Item>}
												content='Permite seleccionar partes no incluidos en la certificación.'/> }
									</Menu.Menu>}
									{this.state.user.roles.IMD && <Menu.Menu>										
										{certificacion.estado === "BLOQUEADA" && <Popup trigger={<Menu.Item onClick={this.changeEstado}>Confirmar</Menu.Item>}
												content='Confirma la certificación. Se pasarán a CERRADA las incidencias VALORADAS con todos sus partes confirmados.'/> }
										{certificacion.estado === "BLOQUEADA" && <Popup trigger={<Menu.Item onClick={this.desbloquear}>Desbloquear</Menu.Item>}
												content='Desbloquea la certificación.'/> }
									</Menu.Menu>}
								</Menu.Item>
							:	<Menu.Item name='detalles' active={activeItem === 'detalles'} onClick={this.handleItemClick} >
									Detalles Certificación
								</Menu.Item>
							}
							{activeItem==='descargas'
							? 	<Menu.Item active={activeItem === 'descargas'}>
									<Menu.Header>Descargas</Menu.Header>
									<Menu.Menu>
									{tipo_certificacion && !this.state.user.ferreteria && (
											<>
										<Menu.Item name='bc3' onClick={this.descargas}>BC3</Menu.Item>
										<Menu.Item name='resumen' onClick={this.descargaResumen}>Resumen</Menu.Item>
									</>
									)}
										<Menu.Item name='pdf' onClick={this.descargas}>Certificación</Menu.Item>
									{tipo_certificacion && !this.state.user.ferreteria && <Menu.Item name='csv' onClick={this.descargas}>Excel</Menu.Item>}
									</Menu.Menu>	
							</Menu.Item>
							:	<Menu.Item name='descargas' active={activeItem === 'descargas'} onClick={this.handleItemClick} >
									Descargas
								</Menu.Item>
							}
						</Menu>
					</Grid.Column>
					<Grid.Column width={12}>
						<Segment raised>
							<Form>
							<Label ribbon><Icon name={certificacion.estado === 'CONFIRMADA'? 'checkmark' : certificacion.estado === 'BLOQUEADA' ? 'lock' : 'unlock'} />{certificacion.estado}</Label>
							<Label color="teal" style={{ float: 'right' }}>{certificacion.tipo}</Label>
							<Grid celled>
							<Grid.Row>
								<Grid.Column width={9}>
									<Grid>
										<Grid.Row>
											<Grid.Column width={10}>
												<Header><Header.Subheader>Empresa</Header.Subheader>{certificacion.empresa}	</Header>
											</Grid.Column>
											<Grid.Column width={6}>
												<Header><Header.Subheader>Servicio</Header.Subheader>{certificacion.servicio}	</Header>	
											</Grid.Column>
										</Grid.Row>
										<Grid.Row columns={2}>
											<Grid.Column>
												<Header><Header.Subheader>Fecha</Header.Subheader>
												{edit 
													? <Form.Field control={DatePicker} dateFormat="DD/MM/YYYY" selected={fecha}  onChange={this.handleFecha} />
													:certificacion.fecha}	
												</Header>
											</Grid.Column>
											<Grid.Column>
												<Header><Header.Subheader>Número</Header.Subheader>
												{edit ? 
													<Form.Input value={numero}  onChange={this.handleNumero} />
													:certificacion.numero}	
												</Header>	
											</Grid.Column>
										</Grid.Row>
										<Grid.Row columns={2}>
											<Grid.Column>
												<Header><Header.Subheader>Inicio</Header.Subheader>
												{edit 
													? <Form.Field control={DatePicker} dateFormat="DD/MM/YYYY" selected={inicio}  onChange={this.handleInicio}/>
													:certificacion.inicio}	
												</Header>	
											</Grid.Column>
											<Grid.Column>
												<Header><Header.Subheader>Fin</Header.Subheader>
												{edit 
													? <Form.Field control={DatePicker} dateFormat="DD/MM/YYYY" selected={fin}  onChange={this.handleFin}/>
													:certificacion.fin}	
												</Header>	
											</Grid.Column>
										</Grid.Row>	
									</Grid>
								</Grid.Column>
								{tipo_certificacion && (
								<Grid.Column width={7}>
									<div>
										<span>TOTAL EJECUCIÓN MATERIAL</span> 
										<span className='derecha'>
										<NumberFormat  value={total_ejecucion_material} 
														displayType={'text'} thousandSeparator={' '}  
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										</span>
									</div>
									<div style={{ borderBottom: '1px solid' }}>
											<span>{certificacion.baja>0 && -certificacion.baja+" %"} Baja</span> 
											<span className='derecha'>
											<NumberFormat  value={-baja} 
															displayType={'text'} thousandSeparator={' '}  
															decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
											</span>
										</div>
									<div>
										<span>·</span>
										<span className='derecha'>
										<NumberFormat  value={subtotal} 
														displayType={'text'} thousandSeparator={' '}  
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										</span>
									</div>
									{certificacion.gg>0 &&
									<div>
										<span>{certificacion.gg} % Gastos generales</span> 
										<span className='derecha'>
										<NumberFormat  value={gastos_generales} 
														displayType={'text'} thousandSeparator={' '}  
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										</span>
									</div>
									}
									{certificacion.bi>0 &&
									<div style={{ borderBottom: '1px solid' }}>
										<span>{certificacion.bi} % Beneficio industrial</span> 
										<span className='derecha'>
										<NumberFormat  value={beneficio_industrial} 
														displayType={'text'} thousandSeparator={' '}  
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										</span>
									</div>
									}
									{(certificacion.bi>0 || certificacion.gg>0) &&
									<div>
										<span>·</span> 
										<span className='derecha'>
										<NumberFormat  value={Math.round((subtotal+gastos_generales+beneficio_industrial)*100)/100}
														displayType={'text'} thousandSeparator={' '}  
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										</span>
									</div>
									}
									<div style={{ borderBottom: '1px solid' }}>
										<span>{certificacion.igic} % I.G.I.C.</span> 
										<span className='derecha'>
										<NumberFormat  value={igic}
														displayType={'text'} thousandSeparator={' '}  
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
										</span>
									</div>
									
									<Header className='derecha'><Header.Subheader>TOTAL CERTIFICACION</Header.Subheader>
										<NumberFormat  value={Math.round((subtotal+gastos_generales+beneficio_industrial+igic)*100)/100}
														displayType={'text'} thousandSeparator={' '}  
														decimalScale={2} fixedDecimalScale={true} decimalseparator="," suffix=' €' />
									</Header>
								</Grid.Column>
								)}
								</Grid.Row>
							</Grid>									
							</Form>						
						</Segment>				
						<Segment raised>
							<Label attached='top left'>{this.state.user.ferreteria? "Pedidos" : "Partes"} incluidos</Label>
							{tipo_certificacion?
								(this.state.user.ferreteria
									?<ListaPartesCertificadosPorTipoFerreteria certificacion={certificacion}/>
									:<ListaPartesCertificadosPorTipoVariable certificacion={certificacion} updateChecked={this.handleChecked}/>
								):
								<ListaPartesCertificadosPorTipoFijo certificacion={certificacion}/>
							}
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
  }
 }

class PartesPendientesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: this.props.certificacion ? true : false};
	};
	open = () => this.setState({ open: true })
	close = () => {this.setState({ open: false }); this.props.guardar();}
	componentWillReceiveProps(next){
		if(next.certificacion === this.props.certificacion) return;
		var tiene = next.certificacion ? true : false;
		var data = next.certificacion;
		var groupcentros = _.groupBy(next.certificacion.partes,(a) => a.centro_id);
		var centros = _.map(groupcentros, (value,key) => 
			{
				var inci = _.groupBy(value,'incidencia_id');
				var incidencias = _.map(inci, (value,key) => {
					return {id:key, descripcion:inci[key][0].incidencia, partes:value}
				});
				return {
					id:key,
					nombre:groupcentros[key][0].centro,
					incidencias:incidencias
				}
			});
		data.centros = centros;
		this.setState({open: tiene});
	}
	render() {
	  return (
		<Modal
		  open={this.state.open} onOpen={this.open} onClose={this.close} size='small'>
		  <Modal.Header>Seleccionar partes a certificar</Modal.Header>
		  <Modal.Content>
			  <ListaPartesCertificadosPorTipoVariable certificacion={this.props.certificacion} updateChecked={this.props.updateChecked}/>
		  </Modal.Content>
		  <Modal.Actions><Button  content='Guardar' onClick={this.close} /></Modal.Actions>
		</Modal>
	  )
	 }
 }
export default DetalleCertificacion;