import React from 'react';
import { IO } from '../js/IO';

export const AppContext = React.createContext();

export class AppProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfWarnings: 0,        // Usado para mostrar el pill del número de
                                        // avisos en el navbar superior. Para esta variable en 
                                        // concreto no hace falta almacenarla en sessionStorage
                                        // debido que al recargar la página siempre se renderiza
                                        // GeneralLayout.jsx
        }
    }

    /**
     * @param {number} number al que se va a actualizar el número de avisos
     * de avisos
     */
    setNumberOfWarnings = (number) => {
        this.setState({ numberOfWarnings: number });
    }

    render(){
        const {numberOfWarnings} = this.state;
        return (
            <AppContext.Provider value={{ 
                numberOfWarnings,
                setNumberOfWarnings: this.setNumberOfWarnings
            }}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}