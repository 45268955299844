import React, { Component } from 'react';
import { Segment, Label, Popup, Modal, Button, Step, Dropdown, Header, Form, Message, TextArea } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import {IO} from './js/IO';
import {Storage} from './js/storage';
import BigCalendar from 'react-big-calendar';
import {Validations} from './js/validations';
import DatePicker from 'react-datepicker';
import {periodos} from './js/constdata'

class Preventivo extends Component {	
	constructor(props) {
		super(props);
		var monthsShortDot = 'ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.'.split('_'),
        monthsShort = 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_');
		moment.locale('es', {
			months : 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
			monthsShort : function (m, format) {
				if (/-MMM-/.test(format)) {
					return monthsShort[m.month()];
				} else {
					return monthsShortDot[m.month()];
				}
			},
			weekdays : 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
			weekdaysShort : 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
			weekdaysMin : 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
			longDateFormat : {
				LT : 'H:mm',
				LTS : 'LT:ss',
				L : 'DD/MM/YYYY',
				LL : 'D [de] MMMM [de] YYYY',
				LLL : 'D [de] MMMM [de] YYYY LT',
				LLLL : 'dddd, D [de] MMMM [de] YYYY LT'
			},
			calendar : {
				sameDay : function () {
					return '[hoy a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
				},
				nextDay : function () {
					return '[mañana a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
				},
				nextWeek : function () {
					return 'dddd [a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
				},
				lastDay : function () {
					return '[ayer a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
				},
				lastWeek : function () {
					return '[el] dddd [pasado a la' + ((this.hours() !== 1) ? 's' : '') + '] LT';
				},
				sameElse : 'L'
			},
			relativeTime : {
				future : 'en %s',
				past : 'hace %s',
				s : 'unos segundos',
				m : 'un minuto',
				mm : '%d minutos',
				h : 'una hora',
				hh : '%d horas',
				d : 'un día',
				dd : '%d días',
				M : 'un mes',
				MM : '%d meses',
				y : 'un año',
				yy : '%d años'
			},
			ordinalParse : /\d{1,2}º/,
			ordinal : '%dº',
			week : {
				dow : 1, // Monday is the first day of the week.
				doy : 4  // The week that contains Jan 4th is the first week of the year.
			}
		});
		BigCalendar.momentLocalizer(moment);
		this.state = {
			eventos : [],
			empresageneral: this.props.empresageneral,
			user: Storage.getStorage('user'),
		};
	};
	getData(date){
		if (date === undefined) {
			date = new Date();
		}
		IO.axios.get('/data/preventivo',
		{
			params:{
				mes: date.getFullYear()+""+(date.getMonth()+1),
				empresa: this.state.empresageneral!==-1 ? this.state.empresageneral: null
			}
		})
		.then(response => {
			this.setState({date, eventos : _.map(response.data, o => _.extend({
				start: moment(o.siguiente, "YYYYMM-DD").toDate(), 
				end:moment(o.siguiente, "YYYYMM-DD").toDate()}, o)), });
		});
	}
	update = () => {
		this.getData(this.state.date);
	}
	componentDidMount(){
		this.getData(new Date());
	}
	myEvent = ( event) => {
		const { centro, empresa, servicio_desc, padre, material_nombre,descripcion} = event.event;
		return (
				<Popup trigger={<span>{centro}</span>}  wide='very'
						content={
							<div>
								<div><strong>{centro}</strong></div>
								<div>{padre && padre}</div>
								{this.state.user.roles.IMD && this.props.empresageneral===-1 && 
									<div><Label basic icon='users' content={servicio_desc.toUpperCase()+ " - " + empresa}/></div>
								}
								<div><Label icon='cube' content={material_nombre} /></div>
								<div dangerouslySetInnerHTML={{ __html: descripcion }} />
							</div>
						}/>
		)
	  }
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.getData(this.state.date));
	}

	render() {
		return (			
			<Segment raised style={{ margin:'20px', marginTop:'60px', height: '500px'}}>
				<Nuevopreventivo recargar={this.update}/>
				<BigCalendar 
					events={this.state.eventos}
					views={['month']}
					culture='es'
					defaultDate={new Date()}
					messages={{
						previous: "Anterior",
						next: "Siguiente",
						today: "Hoy",
					}}
					components={{month: {event:this.myEvent}}}
					onNavigate={(e) => this.getData(e)}
					popup 
					onSelectEvent={event => this.props.history.push('/material/'+event.material)}
					/>
			</Segment>
		);
  }
}

class Nuevopreventivo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false, disablebutton: true,
			empresa: this.props.empresageneral !== -1 ? this.props.empresageneral : null,
			centros: Storage.getStorage('centros_padres'), subcentros:[], equipamientos:[],
			centro: null, subcentro:null, equipamiento:null, 
			periodo:'', unidad: null, inicio: null, descripcion: null,
		};

	};
	componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.cancelar());
		this.setState({centros: Storage.getStorage('centros_padres')});
	}
	handleCentroChange = (e, { name, value }) => {
		this.setState({ [name]: value });
		var subcentros=  _.filter(Storage.getStorage('centros_todos'),{centro_id:value});
		if (subcentros.length >0){
			this.setState({subcentros:_.concat([{id:-1,key:-1,nombre:"Ninguno",text:"Ninguno",value:-1}],subcentros)}); 
		}else{
			this.setState({subcentros:[{id:-1,key:-1,nombre:"Ninguno",text:"Ninguno",value:-1}], subcentro:-1}); 
		}
	}
	handleSubCentroChange = (e, { name, value }) => {
		this.setState({ [name]: value });
		IO.axios.get('/data/inventario', {params: {centro: (value!==-1 ? value : this.state.centro)}})
			.then(response => {
				this.setState({equipamientos :
					_.map(response.data, o => {return {key: o.id, text:o.nombre+' ['+o.codigo+']', value:o.id}}) }); 
			});
	}
	handleEquipamiento = (e, { name, value }) => {
		this.setState({ [name]: value, disablebutton: false });
	}
	handleFecha = (date) => this.setState({ inicio:date })	
	handleChange = (e, {name, value}) => this.setState({ [name]:value });
	enviar = (event) => {
		if(this.state.periodo && Validations.isNumber(this.state.periodo) && this.state.unidad 
			&& this.state.inicio && this.state.descripcion && this.state.empresa ){
			var allFields = {periodo:this.state.periodo, unidad:this.state.unidad, material:this.state.equipamiento,
				descripcion:this.state.descripcion, empresa:this.state.empresa, inicio:this.state.inicio.format('YYYY-MM-DD')};
			IO.axios({
				url:'/data/preventivo', 
				method: 'POST',
				data: JSON.stringify(allFields)})
			.then(response => {
						this.resetComponent();
						this.props.recargar();	
				});
		}else{
			this.setState({ invalidate: true})
		}
	 }
  	open = () => this.setState({ open: true })
  	close = () => this.setState({ open: false })
	cancel = () => {
		this.resetComponent();
		this.close();
	}
	resetComponent = () => this.setState({
		open: false, disablebutton:true,
		empresa: this.props.empresageneral !== -1 ? this.props.empresageneral : null,
		centros: Storage.getStorage('centros_padres'), subcentros:[], equipamientos:[],
		centro: null, subcentro:null, equipamiento:null,
		periodo:'', unidad: null, inicio: null, descripcion: null,
	  })
 	render() {
		const { open, centro, subcentro, equipamiento, centros, subcentros, equipamientos, invalidate, periodo, unidad, inicio, descripcion, empresa} = this.state
		return (
		<Modal open={open} onOpen={this.open} onClose={this.close} size='small'
			trigger={<Button primary style={{position: 'absolute',right: '15px'}}>Nuevo Preventivo</Button>}
		>
			<Modal.Header>Nuevo Preventivo</Modal.Header>
			<Modal.Content>
				<Step.Group ordered fluid>
					<Step completed={centro!=null} active={!centro}>
						<Step.Content>
							<Step.Title>Centro</Step.Title>
						</Step.Content>
					</Step>

					<Step completed={subcentro!=null} active={!subcentro}>
					<Step.Content>
						<Step.Title>Subcentro</Step.Title>
					</Step.Content>
					</Step>

					<Step completed={equipamiento!=null} active={!equipamiento}>
						<Step.Content>
							<Step.Title>Inventario</Step.Title>
						</Step.Content>
					</Step>
				</Step.Group>

				{!centro && <div> 
					<Header size='small'>Seleccione el Centro<Header.Subheader> Se usará para buscar subcentros y material inventariado</Header.Subheader> </Header>
					<Dropdown  selection search fluid
						name='centro' options={centros} value={centro} 
						onChange={this.handleCentroChange}/>
				</div>}
				{centro && !subcentro && <div> 
					<Header size='small'>Seleccione el Subcentro
						<Header.Subheader> Centro: { _.find(centros,{id:centro}).text}</Header.Subheader> 
					</Header>
					<Dropdown  selection search fluid
						name='subcentro' options={subcentros} value={subcentro} 
						onChange={this.handleSubCentroChange}/>
				</div>}
				{centro && subcentro && !equipamiento && <div> 
					<Header size='small'>Seleccione el Inventario
						<Header.Subheader>
							<div> Centro: { _.find(centros,{id:centro}).text}</div>
							<div> Subcentro: { _.find(subcentros,{id:subcentro}).text}</div>
						</Header.Subheader> 
					</Header>
					<Dropdown  selection search fluid
						name='equipamiento' options={equipamientos} value={equipamiento} 
						onChange={this.handleEquipamiento}/>
				</div>}
				{centro && subcentro && equipamiento &&
					<div><Header size='small'>Seleccione el Inventario
					<Header.Subheader>
						<div> Centro: { _.find(centros,{id:centro}).text}</div>
						<div> Subcentro: { _.find(subcentros,{id:subcentro}).text}</div>
						<div> Inventario: { _.find(equipamientos,{key:equipamiento}).text}</div>
					</Header.Subheader> 
					</Header>
					<Form error={invalidate}>
						<Form.Group widths='equal'>
							<Form.Input label='Periodo' value={periodo} name='periodo' onChange={this.handleChange} 
								error={(!periodo || !Validations.isNumber(periodo)) && invalidate}/>
							<Form.Field control={Dropdown} selection options={periodos} name="unidad" label="Unidad" value={unidad} onChange={this.handleChange}
								error={!unidad && invalidate}/>
							<Form.Field control={DatePicker} selected={inicio} onChange={this.handleFecha} name='inicio' label='Inicio'
								error={!inicio && invalidate} dateFormat='DD/MM/YYYY'/>																						
						</Form.Group>
						<Form.Field control={Dropdown} label='[Servicio] Empresa' placeholder='Seleccione' fluid selection search 
								name='empresa' options={Storage.getStorage('empresas')} value={empresa}
								error={!empresa && invalidate} onChange={this.handleChange}/>							
						<Form.Field control={TextArea} autoHeight onChange={this.handleChange} name='descripcion' style={{ width: '100%' }} label='Descripción'
								defaultValue={descripcion} error={!descripcion && invalidate}/>									
						<Message error header='Error en formulario' content='Complete los campos en rojo.'/>						 													
					</Form></div>
				}
			</Modal.Content>
			<Modal.Actions>
				<Button  content='Aceptar' onClick={this.enviar} disabled={this.state.disablebutton}/>	
				<Button  content='Cancelar' onClick={this.cancel}  />
			</Modal.Actions>
		</Modal>
		)
	}
 }
export default Preventivo;

