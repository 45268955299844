import React, {useState, useEffect} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import {Storage} from '../js/storage';

export function YCSFormFieldCentroDeportivo(props){
    const [centrosPadres, setCentrosPadres] = useState([]);
    useEffect(() => {
        setCentrosPadres(Storage.getStorage('centros_padres'));
    }, []);
    
    return (
        <Form.Field
            {...props} control={Dropdown}  label='Centro Deportivo' placeholder='Seleccione' 
             fluid selection search name='centros' options={centrosPadres}
            value={props.value} onChange={props.onChange} 
        />
    );
}
