import axios from "axios";
import {Storage} from './storage';
import {baseUrl} from './constdata'; 

var IO = {
	_singleton: null,
	get axios() {
	  if (!this._singleton) {
		this._singleton = axios.create({
			baseURL: baseUrl,
			withCredentials: true, 
			// `timeout` specifies the number of milliseconds before the request times out.
			// If the request takes longer than `timeout`, the request will be aborted.
			timeout: 10000,
			// `responseType` indicates the type of data that the server will respond with
			// options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
			responseType: 'json', // default		
			// `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
			xsrfCookieName: 'XSRF-TOKEN', // default
			// `xsrfHeaderName` is the name of the http header that carries the xsrf token value
			xsrfHeaderName: 'X-XSRF-TOKEN', // default
			// `maxContentLength` defines the max size of the http response content allowed
			maxContentLength: 2000,
			// `validateStatus` defines whether to resolve or reject the promise for a given
			// HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
			// or `undefined`), the promise will be resolved; otherwise, the promise will be
			// rejected.
			validateStatus: function (status) {
				return status >= 200 && status < 300; // default
			},
			// `maxRedirects` defines the maximum number of redirects to follow in node.js.
			// If set to 0, no redirects will be followed.
			maxRedirects: 5, // default
		});
		this._singleton.interceptors.response.use(
			(response) => {
				if(response.data){
					if(response.data.error === "201"){
						Storage.removeStorage('user');
						axios.get(baseUrl+'/logoutREST/',{withCredentials: true});
						window.location = '/'
					}else if(response.data.error && response.data.error !== "0"){
						return Promise.reject(response.data.mensaje);
					}
				}
				return response;
			}, 
			(error) => {
				return Promise.reject(error);
			}
		);
	  }
	  return this._singleton;
	}
  };
  // Invoke: IO.axios
export {IO}