import _ from 'lodash'
import React, { Component } from 'react';
import { Dropdown, Label, Card, Button, Menu, Input, Grid, Radio, Form} from 'semantic-ui-react';
import {IO} from './js/IO'
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import {tipoInstalaciones,distritos} from './js/constdata';
import ListaInstalaciones from './ListaInstalaciones';
import {Link } from 'react-router-dom';
import { YCSFormFieldTipoDeCentro } from './YCSComponents/YCSFormFieldTipoDeCentro';
import { YCSFormFieldDistrito } from './YCSComponents/YCSFormFieldDistrito';

const MyMapComponent = withGoogleMap(props => 
			<GoogleMap
				defaultZoom={12}
				defaultCenter={{ lat: 28.1100000, lng: -15.4400000 }}
			>
			{props.markers.map(marker => (
        <Marker
          key={marker.id}
          position={{ lat: parseFloat(marker.geolocalizacion.split(',')[0]), lng: parseFloat(marker.geolocalizacion.split(',')[1]) }}
					onClick={() => props.onMarkerClick(marker)}
					defaultIcon = {'/img/'+_.find(tipoInstalaciones,{value:marker.tipo}).icono}>
					{marker.showInfo && <InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
							<Card key={marker.id} link>
										<Card.Content>
										<Card.Header as={Link} to={'/instalaciones/'+marker.id}>{marker.nombre}</Card.Header>
										<Card.Meta>{marker.tipo}</Card.Meta>
										<Card.Description>{marker.distrito} - {marker.direccion}</Card.Description>
										<Card.Content extra>
											<Label  content={'Id: '+marker.id}/>
											<Label  content={'Codigo: '+marker.codigo_imd}/>
											<Label  content={'Patrimonio: '+marker.codigo_patrimonio}/>
										</Card.Content>
										</Card.Content>
							</Card>
      		</InfoWindow>}
				</Marker>
      ))}
			</GoogleMap>
		)

class Instalaciones extends Component {	
	tableData = [];
	constructor(props) {
		super(props);
		this.state = {
			data: this.tableData,
			isMapa: false,
			tipos: this.updateFiltro('tipos',false),
			distrito: this.updateFiltro('distrito',false),
			texto: this.updateFiltro('texto',false) || "",
			empresageneral: this.props.empresageneral,
			filter:"codigo_imd"
		};
	};
	borrarFiltros = () => {
		this.props.history.replace({...this.props.history.location, search: ""})
		this.setState({data: this.tableData,
			isMapa: false,
			tipos: this.updateFiltro('tipos',false),
			distrito: this.updateFiltro('distrito',false),
			texto: this.updateFiltro('texto',false) || "",
			empresageneral: this.props.empresageneral,
			filter:"codigo_imd"}, () => this.updateList());
	}
	updateFiltro = (name,array,defaul) =>{
		const query = new URLSearchParams(this.props.history.location.search);
		var filtro = query.get(name);
		if (!filtro || filtro===""){
			return defaul == null ? (array ? [] : null) :  defaul ;
		}else{
			return  array ? filtro.split(',') : filtro;
		}
	} 
	updateURLFiltros = (name,value) =>{
		const query = new URLSearchParams(this.props.history.location.search);
		query.set(name, value);
		this.props.history.replace({...this.props.history.location, search: query.toString()})
	}
	handleChange = (e, { name, value }) => {
		this.updateURLFiltros(name,value);
		this.setState({[name]:value}, () => this.updateList());
	 }
	updateList = () => {
		var filtrados = this.tableData.filter((a) => (!this.state.tipos || a.tipo===this.state.tipos) && (!this.state.distrito || a.distrito===this.state.distrito));
		if(this.state.texto){
			var valor = _.toUpper(this.state.texto)
			filtrados =  filtrados.filter((a) => _.toUpper(a.nombre).includes(valor) || a.codigo_imd.includes(valor) || a.codigo_patrimonio.includes(valor) );
		}
		var padres = this.tableData.filter((p) => _.indexOf(_.map(filtrados,(a) => a.centro_id),p.id) >-1)
		var datos = _.sortBy(_.uniqBy(_.concat(filtrados,padres), 'id'),(o) => parseFloat(o[this.state.filter]));
		
		this.setState({ data: datos });
	}
	handleOrder = (e, { value }) => {
		var datos = this.state.data;
		datos = _.sortBy(datos,(o) => parseFloat(o[value]));
		this.setState({filter:value, data: datos });
	
	}
	handleMap = () => this.setState({ isMapa: !this.state.isMapa })
	
	getData = () => {
		this.setState({data: [],  loading: true});
		this.tableData = [];
		IO.axios.get('/data/centros',{
			params: {
				empresa: (this.state.empresageneral!== -1 ? this.state.empresageneral : null)
			}	
		}).then(response => {
			this.setState({ loading: false });
			this.tableData = _.uniqBy(response.data, 'id')			
			this.updateList();
		});
	}
  componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.getData());
	}

	componentDidMount() {
		this.getData();
	}
	
	handleMarkerClick(targetMarker) {
    this.setState({
      data: this.state.data.map((marker) => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: true,
            markerIcon: true
          }
        } else {
          return {
            ...marker,
            showInfo: false
          }
        }
      })
    })
	}
	handleMarkerClose(targetMarker) {
    this.setState({
      data: this.state.data.map((marker) => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: false
          }
        }
        return marker
      })
    })
  }
	
	render() {
		const { data, isMapa, tipos, distrito, loading } = this.state;
		// console.log("this.state Instalaciones.jsx: ", this.state);

		return (	
			<Grid container style={{ marginTop: '1em' }}>
				<Grid.Row>
				  <Grid.Column width={4}>
					<Menu vertical>
						<Menu.Item>
							<Button.Group fluid>
								<Button primary={!isMapa} onClick={this.handleMap}>Lista</Button>
								<Button.Or text='' />
								<Button primary={isMapa} onClick={this.handleMap}>Mapa</Button>
							</Button.Group>
						</Menu.Item>
						<Menu.Item>
						  Filtros
						<Menu.Menu>
							<Menu.Item>
								Texto
								<Input placeholder='...' name='texto' value={this.state.texto} onChange={this.handleChange}/>
							</Menu.Item>
							<Menu.Item>
								Tipo
								<YCSFormFieldTipoDeCentro value={tipos} onChange={this.handleChange}/>
							</Menu.Item>
							<Menu.Item>
								Distrito
								<YCSFormFieldDistrito value={distrito} onChange={this.handleChange}/>
							</Menu.Item>
							<Menu.Item>
								<Button content="Borrar Filtros" size='mini' onClick={this.borrarFiltros}/>
							</Menu.Item>
						</Menu.Menu>
						</Menu.Item>

						<Menu.Item>
						  Ordenar
						<Menu.Menu>
							<Menu.Item>
							<Radio
									label='Código IMD'
									name='radioGroup'
									value='codigo_imd'
									checked={this.state.filter === 'codigo_imd'}
									onChange={this.handleOrder}
								/>
							</Menu.Item>
							<Menu.Item>
								<Radio
									label='Código Patrimonio'
									name='radioGroup'
									value='codigo_patrimonio'
									checked={this.state.filter === 'codigo_patrimonio'}
									onChange={this.handleOrder}
								/>
							</Menu.Item>
						</Menu.Menu>
						</Menu.Item>

					</Menu>
				</Grid.Column>

				<Grid.Column width={12}>
					{ isMapa ? (
						<MyMapComponent    markers={data}
							onMarkerClick={this.handleMarkerClick.bind(this)}
							onMarkerClose={this.handleMarkerClose.bind(this)}
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `500px` }} />}
  						mapElement={<div style={{ height: `100%` }} />
							}
						>
						</MyMapComponent>
					) : 
					( <ListaInstalaciones loading={loading} data={data}/>)}
				  </Grid.Column>
				</Grid.Row>
			</Grid>
		);
  }
}

export default Instalaciones;