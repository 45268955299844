import React, {useState, useEffect} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {IO} from '../js/IO';
import _ from 'lodash';

/**
 * Abstracción del filtro de Tipo de material.
 * props.resetFiltroTipoDeMaterial es un boolean que al cambiar de true a falso o viceversa
 * se resetea el componente
 * props.handleItemSelected función que actualiza el elemento seleccionado del listado de
 * tipo de materiales
 * @param {*} props 
 * @returns JSX.Element
 */
export function YCSFormFieldTipoDeMaterial_v2(props){
    const [tiposDeMaterial, setTipoDeMaterial] = useState([]);                       // Almacenamos el listado de los tipos de material
    const [tipoDeMaterialSeleccionado, setTipoDeMaterialSeleccionado] = useState([]); 

    useEffect(() => {
        getTypeOfMaterial();
    }, []);

    // Reseteamos el filtro siempre y cuando la prop resetFiltroTipoDeMaterial cambie
    // de true a false y viceversa
    useEffect(() => {
        setTipoDeMaterialSeleccionado([]);
    }, [props.resetFiltroTipoDeMaterial]);

    /**
     * Traemos los tipos de material del backend y los ordenamos para mostrarlos
     */
    const getTypeOfMaterial = () => {
		IO.axios.get('/data/tipomaterial')
		.then(response => {
            let data = JSON.parse(_.replace(JSON.stringify(response.data),/title/g,'name')) ;
            let result = searchChildrenForInventory(data);
            let newResult = transformInventory(result);
			setTipoDeMaterial( newResult );
		});
	 }

     /**
      * Función que desenrrolla a todos sus hijos
      * @param {Object} data buffer donde vamos a buscar sus hijos
      * @retrun {Array} result buffer con los hijos desenrrollados
      */
     const searchChildrenForInventory = (data) => {
        let result = _.cloneDeep(data), resultAux = [], existChildren = false, indexFound = 0;
        do{
            _.map(result, (item, index) => {
                if(item.children && item.children.length > 0){
                    let newItem = _.cloneDeep(item), newResult = [], bufferWithOffspring = [], itemChildren = [];
                    indexFound = _.findIndex( result, e => e.key === item.key );
                    delete newItem.children;
                    newResult.push( newItem );

                    // showArrow = true show arrow, false = hide arrow
                    // showArrowDown = true show arrow down, false = hide arrow down
                    bufferWithOffspring = JSON.parse( JSON.stringify( [ { ...newResult[0] }] ) );

                    // 1º Desenrrollamos los children
                    itemChildren = _.cloneDeep(item.children);
                    _.map(itemChildren, child => {
                        // Averiguamos si un hujo tiene children para realizar otra vuelta más del bucle
                        (child.children?.length > 0)? existChildren = true : existChildren = false;    
                        // showItem = true mostramos el item, false no lo mostramos
                        bufferWithOffspring.push({ ...child, key: `${newItem.key}-${child.key}`, keyFather: newItem.key });
                    });

                    // 2º Destrucción del buffer result:
                    let bufferParticionadoPrimeraParte = cutArray(result, 0, indexFound );
                    bufferParticionadoPrimeraParte.pop();
                    let bufferParticionadoSegundaParte = result.slice(indexFound + 1);

                    // 3º Concadenamos los children encontrados
                    bufferParticionadoPrimeraParte = _.concat(bufferParticionadoPrimeraParte, bufferWithOffspring);

                    // 4º Concadenos la segunda parte de result para volver a unirla
                    resultAux = _.concat(bufferParticionadoPrimeraParte, bufferParticionadoSegundaParte);
                }
                index = 0;
                if(resultAux.length > 0) result = _.cloneDeep(resultAux);
                resultAux = [];
            });
        }while(existChildren);
        return result;
     }

     /**
      * Recorta un buffer dado dos índices
      * @param {number} start indice a partir del cual se recorta el array
      * @param {finumbernal} final indice a partir del cual se limita el recorto el array
      * @param {Array} array buffer a partiicionar
      */
     const cutArray = (array, start, final) => {
        if( start > final || start < 0 || final < 0){
            return [];
        }
        return _.filter(array, (e,i) => {
            if(i >= start && i <= final){
                return e
            }
        });
     }

     /**
      * 
      * @param {Array} data Inventario a analizar
      * @returns {Array} newArray Buffer parecido a options de Dropdown.Item de semantic-ui-react 
      */
     const transformInventory = (data) => {
        return _.map(data, item => {
            return {
                key: item.name,
                text: item.name,
                value: item.name,
                content: (
                    <div style={{paddingLeft: item.key.split("-").length*10+'px'}}>{item.name}</div>
                ),
            }
        });
     }

	const handleChange = (e, { name, value }) => {
        setTipoDeMaterialSeleccionado(value);
        props.handleItemSelected({name, value});
    }
    
    return (
        <Dropdown
            placeholder='Seleccione'
            fluid search selection multiple
            options={tiposDeMaterial}
            name="tiposDeMaterial"
            value={tipoDeMaterialSeleccionado}
            onChange={handleChange}
        />
    );
}
