import React from 'react';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

export default class ListaPartesCertificadosPorTipoFijo extends React.Component {

/**
 * Replace all "<br/>"" by "\n". Important to use css "white-space: pre-line"
 * @param {string} text 
 * @returns string
 */
  convertString = (text) => {
      return text?.replaceAll("<br/>", "\n");
  }
  render() {
    const {certificacion} = this.props;
    if(!certificacion.partes){
        return null;
    }
    return (
      <List divided relaxed>
          {certificacion.partes.map( ({incidencia_id, fechaCreacion, centro, incidencia, fecha, descripcion, mensaje}, index) => (
              <List.Item>
                <List.Content>
                    <List.Header>
                        INCIDENCIA: {incidencia_id}
                        <span style={{ marginLeft: 20 }}>{fechaCreacion}</span>
                    </List.Header>
                    <List.Description>
                        <div style={{ margin: "10px 0 20px 0" }}>
                            <p>
                                CENTRO: {centro}
                            </p>
                            <P_Customized>
                                {this.convertString(incidencia)}
                            </P_Customized>
                            <p>
                                RESULUCIÓN: {fecha}
                            </p>
                            <ul>
                                <Li_Customized>
                                    Descripción: {this.convertString(descripcion)}
                                </Li_Customized>
                                <Li_Customized>
                                    Mensaje: {this.convertString(mensaje)}
                                </Li_Customized>
                            </ul>
                        </div>
                    </List.Description>
                </List.Content>
              </List.Item>
          ))}
      </List>
    )
  }
}

const Li_Customized = styled.li`
    white-space: pre-line;
`;
const P_Customized = styled.p`
    white-space: pre-line;
`;
