import _ from 'lodash'
import React, { Component } from 'react';
import {Modal, Tab, Message, Form, Dropdown, List, Segment, Image, Header, Label, Button, Menu, Grid, TextArea, Input} from 'semantic-ui-react';
import {IO} from './js/IO'
import {tipoInstalaciones, distritos} from './js/constdata'
import ListaInstalaciones from './ListaInstalaciones';
import ListaIncidencias from './ListaIncidencias';
import {ListaArchivos, SubirArchivo} from './ListaArchivos';
import {withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import {Link } from 'react-router-dom';
import {Storage} from './js/storage'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Validations} from './js/validations'
import {NuevaIncidencia} from './Incidencias'
import {Treebeard} from 'react-treebeard';
import tree from './js/treebeard'
import { YCSFormFieldTipoDeMaterial_v2 } from './YCSComponents/YCSFormFieldTipoDeMaterial_v2';
import styled from 'styled-components';

const MapCentro = withGoogleMap(props => 
	<GoogleMap
		defaultZoom={15}
		defaultCenter={{ lat: parseFloat(props.marker.geolocalizacion.split(',')[0]), 
						 lng: parseFloat(props.marker.geolocalizacion.split(',')[1]) }}
	>
		<Marker draggable={props.draggable} onDragEnd={props.onDragEnd}
  			position={{ lat: parseFloat(props.marker.geolocalizacion.split(',')[0]), 
			  			lng: parseFloat(props.marker.geolocalizacion.split(',')[1]) }}
			defaultIcon = {'/img/'+_.find(tipoInstalaciones,{value:props.marker.tipo}).icono}>
		</Marker>
	</GoogleMap>
)

class DetalleInstalacion extends Component {
	incidencias=[];

	constructor(props) {
		super(props);
		this.state = {
			instalacion: {},						// Se renderiza en la lista			
			instalacionBackup: {},					// Backup de instalacion
			info: {},
			activeItem: this.props.match.params['section'] ? this.props.match.params['section'] :'detalles',
			archivos: [] ,
			editInstalacion: false,
			editInfo: false,
			instalacionS : {},					// Trabajamos con esta variable y su backup es instalacion
			infoS : {},
			user: Storage.getStorage('user'),
			empresageneral: this.props.empresageneral,
			textFilter: "",						// Texto a filtrar
			resetFiltroTipoDeMaterial: false,	// Permite limpiar YCSFormFieldTipoDeMaterial_v2 cada vez que se cambia
												// de true a false y viceversa
			handleItemSelectedValue: [],		// Almacenamos el valor del filtro de Tipo de material, siempre son strings
		};
	 };

	 /**
	  * Obtenemos las queries del filtro indicado
	  * @param {string} name 
	  * @param {*} array 
	  * @param {*} defaul 
	  * @returns any
	  */
	 updateFiltro = (name,array,defaul) =>{
		const query = new URLSearchParams(this.props.history.location.search);
		var filtro = query.get(name);
		if (!filtro || filtro===""){
			return defaul == null ? (array ? [] : null) :  defaul ;
		}else{
			return  array ? filtro.split(',') : filtro;
		}
	} 

	handleItemClick = (e, { name }) => this.setState({ activeItem: name })
	changeToEdit = () => this.setState({ editInstalacion: true })
	changeToEditInfo = () => this.setState({ editInfo:true })
	cancelInstalacion = () => this.setState({ editInstalacion: false, instalacionS: _.clone(this.state.instalacion) })
	cancelInfo = () => this.setState({ editInfo: false, infoS : _.clone(this.state.info) })
	saveInstalacion = () => {
		var allFields = JSON.stringify(this.state.instalacionS);
		IO.axios({
			url:'/data/centros.php/'+this.state.instalacionS.id, 
			method: 'PUT',
			data: allFields})
		.then(response => {
				this.getData();	
				this.setState({ editInstalacion: false});
			});
	 }
	saveInfo = () => {
		const i = this.state.infoS;
		var allFields = "{ 	 \"nombre\":\""+(i.nombre && i.nombre)+"\","+
		" \"fechaAlta\":\"" +(i.fecha_alta && i.fecha_alta.format('YYYY-MM-DD') )+"\","+
		" \"descripcion\":\"" + (i.descripcion && i.descripcion.replace(/\r?\n/g, '<br/>'))+"\","+ 	
		" \"claseCatatro\":\"" +(i.clase_catastral && i.clase_catastral)+"\","+
		" \"claseUrb\":\"" +(i.clase_urbanistica && i.clase_urbanistica)+"\","+
		" \"observaciones\":\"" +(i.observaciones && i.observaciones.replace(/\r?\n/g, '<br/>'))+"\","+
		" \"finca\":\"" +(i.finca && i.finca)+"\","+
		" \"frente\":\"" +(i.lindero_frente && i.lindero_frente)+"\","+	
		" \"fondo\":\"" +(i.lindero_fondo && i.lindero_fondo)+"\","+	
		" \"derecha\":\"" +(i.lindero_derecha && i.lindero_derecha)+"\","+		
		" \"izquierda\":\"" +(i.lindero_izquierda && i.lindero_izquierda)+"\","+
		" \"solar\":\"" + (i.solar && i.solar)+"\","+	
		" \"destino\":\"" +(i.destino && i.destino)+"\","+	
		" \"uso\":\"" +(i.uso && i.uso)+"\","+		
		" \"fechaConstruccion\":\"" +(i.fecha_construccion && i.fecha_construccion.format('YYYY-MM-DD'))+"\","+		
		" \"superficie\":\"" +(i.superficie_costruida && i.superficie_costruida)+"\","+
		" \"rasante\":\"" +(i.sobre_rasante && i.sobre_rasante)+"\","+	
		" \"valorCatastro\":\"" +(i.valor_catastral && i.valor_catastral)+"\","+	
		" \"valorSuelo\":\"" +(i.valor_suelo && i.valor_suelo)+"\","+
		" \"valorReposicion\":\"" +(i.valor_reposicion && i.valor_reposicion)+"\","+
		" \"valorTotal\":\"" +(i.valor_total && i.valor_total)+"\"}";
		IO.axios({
			url:'/data/centros_info.php/'+this.state.instalacion.id, 
			method: 'POST',
			data: allFields})
		.then(response => {
					this.getData();	
					this.setState({ editInfo: false});
		});
	 }
	handleEditInstalacion = (e, {name,value}) => {
			var temp = this.state.instalacionS;
			temp[name] = value;
			this.setState({instalacionS: temp})
	 }
	onDragEnd = (e) =>{
		var temp = this.state.instalacionS;
		temp.geolocalizacion =  e.latLng.lat()+","+e.latLng.lng();
		this.setState({instalacionS: temp})
	}
	handleEditInfo = (e, {name,value}) => {
		var temp = this.state.infoS;
		temp[name] = value;
		this.setState({infoS: temp})
	 }
	handleFechaAlta = (date) => {
		var temp = this.state.infoS;
		temp.fecha_alta = date;
		this.setState({infoS: temp})
	 }
	handleFechaConstruccion = (date) => {
		var temp = this.state.infoS;
		temp.fecha_construccion = date;
		this.setState({infoS: temp})
	 }
	handleFilter = (e, { value }) => {
		var ins = this.state.instalacion;
		ins.partes =  this.incidencias.filter((a) => a.descripcion.includes(value) || a.inspector.includes(value) || a.id.includes(value))
		this.setState({ data: ins});
	 }
	getFiles = () => {
	 	IO.axios.get('/imageserver/fileCentros?centro='+this.props.match.params['Id'] 
		).then(response => {
			this.setState({ archivos: response.data.files});
		});
	 }
	getData = () => {
		this.getFiles()
		IO.axios.get('/data/centros.php/'+this.props.match.params['Id'],{
			params: {
				empresa: (this.state.empresageneral!== -1 ? this.state.empresageneral : null)
			}	
		}).then(response => {
				if(response.data && response.data.length>0){
					this.incidencias = response.data[0].partes;
					this.setState({ instalacion: response.data[0],  instalacionS: _.clone(response.data[0]), instalacionBackup: response.data[0] });
				}else{
					this.incidencias = []
					this.setState({ instalacion: {},  instalacionS: {}, instalacionBackup:{} });
				}
			});
		IO.axios.get('/data/centros_info.php/'+this.props.match.params['Id']
			).then(response => {
				var aux = _.clone(response.data);
				if (aux){
					aux.fecha_alta = aux.fecha_alta ? moment(aux.fecha_alta,'YYYY-MM-DD') : null;
					aux.fecha_construccion = aux.fecha_construccion ? moment(aux.fecha_construccion,'YYYY-MM-DD') : null;
					this.setState({ info: response.data, infoS: aux });
				}
			});
	 }
  
	componentDidMount() {
		this.getData();
	 }
	 componentWillReceiveProps(next){
		if(next.empresageneral === this.props.empresageneral) return
		this.setState({empresageneral: next.empresageneral}, () => this.getData());
	}

	// Actualizamos el input texto y filtramos
	handleChange = (e, {name, value}) => {
		this.setState({ [name]: value }, () => this.filterByText(value)); 
	}

	/**
	 * Filtramos por el código y el nombre
	 * @param {string} name
	 */
	filterByText = (value) => {
		let {instalacion} = this.state;
		if(value.length > 0){
			let bufferAux = this.searchInventoryByText(value, instalacion);
			this.setState(state => ({
				instalacion: {...state.instalacionBackup, inventario: bufferAux}
			}));
		}else{
			this.handleSearchTipeOfMaterialWithTextEmpty({
				name: "",
				value: this.state.handleItemSelectedValue
			});
		}
	}

	/**
	 * 
	 * @param {string} value texto del filtro a implementar
	 * @param {Object} instalacion 
	 * @returns {Array} inventario filtrado
	 */
	searchInventoryByText = (value, instalacion) => {
		return _.filter(instalacion.inventario, e => {
			if(_.includes( e.codigo.toLowerCase(), value.toLowerCase() ) || 
			_.includes( e.nombre.toLowerCase(), value.toLowerCase() )){
				return e;
			}
		});
	}

	/**
	 * Reseteo de los dos filtros Texto y Tipo de material
	 */
	resetFilters = () => this.setState(state => ({
		textFilter: "",
		resetFiltroTipoDeMaterial: !state.resetFiltroTipoDeMaterial,
		instalacion: state.instalacionBackup
	}));

	/**
	 * Filtramos sobre el inventariado en función del tipo de material del componente
	 * YCSFormFieldTipoDeMaterial_v2.jsx
	 * @param {Object} name is a string, value is always a buffer 
	 */
	handleItemSelected = ({ name, value }) => {
		const {textFilter, instalacionBackup} = this.state;
		if(value.length !== 0) {
			let inventarioFiltrado = _.filter(instalacionBackup.inventario, e => {
				let resultAux = _.map(value, tipo => {
					if(e.tipo === tipo){
						return e
					}
				});

				let resultCleaned = _.filter(resultAux, e => e !== undefined);
				if(resultCleaned.length > 0){
					return resultCleaned;
				}
			});
	
			this.setState(state => ({
				instalacion: {...state.instalacionBackup, inventario: inventarioFiltrado},
				handleItemSelectedValue: value
			}));
		}else{
			if(textFilter.length > 0){
				let bufferAux = this.searchInventoryByText(textFilter, instalacionBackup);
				this.setState(state => ({
					instalacion: {...state.instalacionBackup, inventario: bufferAux},
					handleItemSelectedValue: value
				}));
			}else{
				this.setState(state => ({
					instalacion: state.instalacionBackup,
					handleItemSelectedValue: value
				}));
			}
		}
	}

	/**
	 * En caso que el filtro del texto esté vacío se llama a este método que filtra por el
	 * tipo de material que esté seleccionado en ese momento
	 * @param {Objet} name is a string and value is always a buffer 
	 */
	handleSearchTipeOfMaterialWithTextEmpty = ({ name, value }) => {
		const {instalacionBackup} = this.state;
		if(value.length !== 0) {
			let inventarioFiltrado = _.filter(instalacionBackup.inventario, e => {
				let resultAux = _.map(value, tipo => {
					if(e.tipo === tipo){
						return e
					}
				});

				let resultCleaned = _.filter(resultAux, e => e !== undefined);
				if(resultCleaned.length > 0){
					return resultCleaned;
				}
			});
	
			this.setState(state => ({
				instalacion: {...state.instalacion, inventario: inventarioFiltrado},
				handleItemSelectedValue: value
			}));
		}else{
			this.setState(state => ({
				instalacion: state.instalacionBackup,
				handleItemSelectedValue: value
			}));
		}
	}

	/**
	 * Link del material por su id
	 * @param {number} id 
	 */
	viewMaterial = (id) => this.props.history.push({ pathname: '/material/'+id });

	render() {
		const { instalacion, activeItem, archivos, info, loading_files, editInfo, editInstalacion, instalacionS, infoS,
			 user, textFilter, resetFiltroTipoDeMaterial } = this.state;
		
		if (this.props.match.params['Id'] !== instalacion.id){
			if (instalacion.id){this.getData();}
			return '';
		}
		var auxiliar = _.filter(Storage.getStorage("auxiliares"),{id:instalacion.auxiliar});
		auxiliar = (auxiliar.length > 0) ? auxiliar[0].nombre+' '+auxiliar[0].apellidos : null;
		return (
			<Grid container style={{ marginTop: '1em' }}>
				<Grid.Row>
					<Grid.Column  width={4}>
					<Menu pointing vertical>
						{activeItem==='detalles' ?
							<Menu.Item active={activeItem === 'detalles'}>
								<Menu.Header>Detalles Instalación</Menu.Header>
								{(user.roles.IMD)  && <Menu.Menu>
									<Menu.Item name='editar' onClick={this.changeToEdit}>Editar</Menu.Item>
									<Menu.Item name='editar' onClick={this.changeToEditInfo}>Editar Información Adicional</Menu.Item>
								</Menu.Menu>}
							</Menu.Item>
						:	<Menu.Item name='detalles' active={activeItem === 'detalles'} onClick={this.handleItemClick} >
								Detalles Instalación
							</Menu.Item>
						}
						{!instalacion.centro_id	&&
							<Menu.Item name='centros' active={activeItem === 'centros'} onClick={this.handleItemClick} >
								Centros
								<Label>{instalacion.hijos ? instalacion.hijos.length : 0}</Label>
							</Menu.Item>
						}
						{activeItem==='inventario' ?
							<Menu.Item active={activeItem === 'inventario'} >
								<Menu.Header>Inventario</Menu.Header>
								<Menu.Menu> 
										<CrearInventario centro={instalacion.id} update={this.getData}/> 

										{/* Menú del filtro del inventario */}
										<Menu.Item>
											Texto
											<Input placeholder='...' name='textFilter' value={textFilter} onChange={this.handleChange}/>
										</Menu.Item>
										<Menu.Item>
											Tipo de material
											<YCSFormFieldTipoDeMaterial_v2 handleItemSelected={this.handleItemSelected} resetFiltroTipoDeMaterial={resetFiltroTipoDeMaterial}/>
										</Menu.Item>
										<Menu.Item>
											<Button content="Borrar Filtros" size='mini' onClick={this.resetFilters}/>
										</Menu.Item>
								</Menu.Menu>
							</Menu.Item>
						:	<Menu.Item name='inventario' active={activeItem === 'inventario'} onClick={this.handleItemClick} >
								Inventario
								<Label>{instalacion.inventario ? instalacion.inventario.length : 0}</Label>
							</Menu.Item>
						}
						{!instalacion.centro_id &&	
							<Menu.Item name='partes' active={activeItem === 'partes'} onClick={this.handleItemClick} >
								Incidencias
								<Label>{instalacion.partes ? instalacion.partes.length : 0}</Label>
								{activeItem==='partes' && <Menu.Menu>	
									<Menu.Item>  
										{user.roles.IMD  &&<NuevaIncidencia updateList={this.getData} centro={instalacion.id} fluid empresageneral={this.props.empresageneral}/>}
									</Menu.Item>
									<Menu.Item>
										Filtro
										<Input placeholder='...' onChange={this.handleFilter}/>
									</Menu.Item>
								</Menu.Menu>
								}
								
							</Menu.Item>
						}
						{activeItem==='archivos' ?
							<Menu.Item active={activeItem === 'archivos'}>
								<Menu.Header>Archivos</Menu.Header>
								<Menu.Menu>
									<SubirArchivo centro={instalacion.id} updateFiles={this.getData} files="fileCentros"/>
								</Menu.Menu>	
							</Menu.Item>
						: 	<Menu.Item name='archivos' active={activeItem === 'archivos'} onClick={this.handleItemClick} >
								Archivos
								<Label>{archivos.length}</Label>
							</Menu.Item>
						}
						</Menu>
					</Grid.Column>	
					
					<Grid.Column  width={12} textAlign='left'>
						<Header>
							
							{editInstalacion 
							? 
								(<Input fluid defaultValue={instalacionS.nombre} onChange={this.handleEditInstalacion} name='nombre' /> )
							:  
								<span> 	
									{instalacion.centro_id && <span><Link to={'/instalaciones/'+instalacion.centro_id}>{instalacion.padre.nombre}</Link>{' / '}</span>}
									{instalacion.nombre}
								</span>		
							}
						</Header>
						{ activeItem==='detalles' &&
						<div>
							<Segment raised>
								<Grid >
									<Grid.Row  columns={2}>
										<Grid.Column>
										{editInstalacion && 
											<Header size='small'><Header.Subheader>Complejo Deportivo</Header.Subheader>
												<Dropdown fluid selection search options={Storage.getStorage('centros_todos')} name="centro_id" value={instalacionS.centro_id} onChange={this.handleEditInstalacion}/>
											</Header>
										}
											
											<Grid>
												<Grid.Row>
												{!editInstalacion &&
													<Grid.Column width={2}>
													 <Image size='tiny' src={'/img/'+_.find(tipoInstalaciones,{value:instalacion.tipo}).icono} />
													</Grid.Column>
												}
												<Grid.Column width={editInstalacion ? 16 : 14}>
													<Header size='small'>
														<Header.Subheader>Tipo</Header.Subheader>
															{editInstalacion ? 
															<Dropdown fluid selection options={tipoInstalaciones} name="tipo" value={instalacionS.tipo} onChange={this.handleEditInstalacion}/>
															: instalacion.tipo}
														</Header>
												</Grid.Column>
												</Grid.Row>
											</Grid>
											
											<Header size='small'><Header.Subheader>Distrito</Header.Subheader>
												{editInstalacion ? 
												<Dropdown fluid selection options={distritos} name="distrito" value={instalacionS.distrito} onChange={this.handleEditInstalacion}/>
												: instalacion.distrito}
											</Header>	
											<Header size='small'><Header.Subheader>Dirección</Header.Subheader>
												{editInstalacion ? <Input fluid defaultValue={instalacionS.direccion} onChange={this.handleEditInstalacion} name='direccion' /> :  instalacion.direccion }
											</Header>
											<Grid >
												<Grid.Row   columns={2}>
													<Grid.Column as={Header} size='small'><Header.Subheader>Código</Header.Subheader>
														{editInstalacion ? <Input fluid defaultValue={instalacionS.codigo_imd} onChange={this.handleEditInstalacion} name='codigo_imd' /> : instalacion.codigo_imd}
													</Grid.Column>
													<Grid.Column as={Header} size='small'><Header.Subheader>Patrimonio</Header.Subheader>
														{editInstalacion ? <Input  fluid defaultValue={instalacionS.codigo_patrimonio} onChange={this.handleEditInstalacion} name='codigo_patrimonio' /> : instalacion.codigo_patrimonio}
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</Grid.Column>
										<Grid.Column>
											<MapCentro    marker={instalacionS} draggable={editInstalacion} onDragEnd={this.onDragEnd}
													loadingElement={<div style={{ height: `100%` }} />}
													containerElement={<div style={{ height: `100%` }} />}
													mapElement={<div style={{ height: `100%` }} />}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={8} as={Header} size='small'><Header.Subheader>Club</Header.Subheader>
											{editInstalacion ? <Input fluid defaultValue={instalacionS.club} onChange={this.handleEditInstalacion} name='club' /> : instalacion.club}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Email</Header.Subheader>
											{editInstalacion ? <Input fluid defaultValue={instalacionS.email} onChange={this.handleEditInstalacion} name='email' /> :instalacion.email}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Tlf</Header.Subheader>
											{editInstalacion ? <Input fluid defaultValue={instalacionS.tlf} onChange={this.handleEditInstalacion} name='tlf' /> :instalacion.tlf}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row  columns={2}>
										<Grid.Column as={Header} size='small'><Header.Subheader>Auxiliar</Header.Subheader>										
											{editInstalacion ? 
											<Dropdown fluid selection options={Storage.getStorage("auxiliares")} name="auxiliar" value={instalacionS.auxiliar} onChange={this.handleEditInstalacion}/>
											: auxiliar}
										</Grid.Column>
										<Grid.Column as={Header} size='small'><Header.Subheader>Otros contactos</Header.Subheader>
											{editInstalacion ? <Input fluid defaultValue={instalacionS.contacto} onChange={this.handleEditInstalacion} name='contacto' /> :instalacion.contacto}
										</Grid.Column>
									</Grid.Row>
								</Grid>			
								{editInstalacion && 
									<div  style={{ marginTop: '1em', maginBottom: '1em' }}>																				
										<Button  onClick={this.saveInstalacion}>Guardar</Button>
										<Button  color='red' onClick={this.cancelInstalacion}>Cancelar</Button>
									</div>
								}					
							</Segment>							
							
							<Segment raised>
								<Label attached='top left'>Información Adicional</Label>
								<Form>
								<Grid>
									<Grid.Row>
										<Grid.Column width={12} as={Header} size='small'><Header.Subheader>Nombre</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.nombre} onChange={this.handleEditInfo} name='nombre' /> : info.nombre}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Fecha Alta</Header.Subheader>
											{editInfo ?
											<DatePicker selected={infoS.fecha_alta} onChange={this.handleFechaAlta} name='fecha_alta' dateFormat="DD/MM/YYYY"/>
											: info.fecha_alta}
										</Grid.Column>						
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={12} as={Header} size='small'><Header.Subheader>Destino</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.destino} onChange={this.handleEditInfo} name='destino' /> :info.destino}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Fecha Construcción</Header.Subheader>
											{editInfo ? 
											<DatePicker  selected={infoS.fecha_construccion} onChange={this.handleFechaConstruccion} name='fecha_construccion' dateFormat="DD/MM/YYYY"/>
											: info.fecha_construccion}
										</Grid.Column>	
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={12} as={Header} size='small'><Header.Subheader>Descripción</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.descripcion} onChange={this.handleEditInfo} name='descripcion' /> :info.descripcion}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Uso</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.uso} onChange={this.handleEditInfo} name='uso' /> : info.uso}
										</Grid.Column>	
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={12} as={Header} size='small'><Header.Subheader>Clase Urbanística</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.clase_urbanistica} onChange={this.handleEditInfo} name='clase_urbanistica' /> : info.clase_urbanistica}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Clase Catastral</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.clase_catastral} onChange={this.handleEditInfo} name='clase_catastral' /> :info.clase_catastral}
										</Grid.Column>	
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={16} as={Header} size='small'><Header.Subheader>Observaciones</Header.Subheader>
											{editInfo ? <TextArea autoHeight onChange={this.handleEditInfo} name='observaciones' style={{ width: '100%' }} defaultValue={infoS.observaciones} /> :<div dangerouslySetInnerHTML={{ __html: info.observaciones }} />}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Finca</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.finca} onChange={this.handleEditInfo} name='finca' /> :info.finca}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Solar</Header.Subheader>
											{editInfo ? 
												<Form.Input fluid defaultValue={infoS.solar} onChange={this.handleEditInfo} name='solar' error={!Validations.isNumberOrVoid(infoS.solar)}/> 
												:info.solar
											}
										</Grid.Column>	
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Superficie Construida</Header.Subheader>
											{editInfo ? 
											<Form.Input fluid defaultValue={infoS.superficie_costruida} onChange={this.handleEditInfo} name='superficie_costruida' error={!Validations.isNumberOrVoid(infoS.superficie_costruida)}/> 
											: info.superficie_costruida}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Sobrerasante</Header.Subheader>
											{editInfo ? 
											<Form.Input fluid defaultValue={infoS.sobre_rasante} onChange={this.handleEditInfo} name='sobre_rasante'  error={!Validations.isNumberOrVoid(infoS.sobre_rasante)}/> 
											:info.sobre_rasante}
										</Grid.Column>	
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Lindero frente</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.lindero_frente} onChange={this.handleEditInfo} name='lindero_frente' /> : info.lindero_frente}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Lindero fondo</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.lindero_fondo} onChange={this.handleEditInfo} name='lindero_fondo' /> : info.lindero_fondo}
										</Grid.Column>	
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Lindero derecha</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.lindero_derecha} onChange={this.handleEditInfo} name='lindero_derecha' /> : info.lindero_derecha}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Lindero izquierda</Header.Subheader>
											{editInfo ? <Input fluid defaultValue={infoS.lindero_izquierda} onChange={this.handleEditInfo} name='lindero_izquierda' /> : info.lindero_izquierda}
										</Grid.Column>	
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Valor Catastral</Header.Subheader>
											{editInfo ? 
											<Form.Input fluid defaultValue={infoS.valor_catastral} onChange={this.handleEditInfo} name='valor_catastral'  error={!Validations.isNumberOrVoid(infoS.valor_catastral)}/> 
											: info.valor_catastral}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Valor Suelo</Header.Subheader>
											{editInfo ? 
											<Form.Input fluid defaultValue={infoS.valor_suelo} onChange={this.handleEditInfo} name='valor_suelo'  error={!Validations.isNumberOrVoid(infoS.valor_suelo)}/> 
											: info.valor_suelo}
										</Grid.Column>	
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Valor reposición</Header.Subheader>
											{editInfo ? 
											<Form.Input fluid defaultValue={infoS.valor_reposicion} onChange={this.handleEditInfo} name='valor_reposicion'  error={!Validations.isNumberOrVoid(infoS.valor_reposicion)}/> 
											: info.valor_reposicion}
										</Grid.Column>
										<Grid.Column width={4} as={Header} size='small'><Header.Subheader>Valor total</Header.Subheader>
											{editInfo ? 
											<Form.Input fluid defaultValue={infoS.valor_total} onChange={this.handleEditInfo} name='valor_total'  error={!Validations.isNumberOrVoid(infoS.valor_total)}/> 
											: info.valor_total}
										</Grid.Column>	
									</Grid.Row>
								</Grid>
								</Form>
								{editInfo && 
									<div  style={{ marginTop: '1em', maginBottom: '1em' }}>																				
										<Button  onClick={this.saveInfo}>Guardar</Button>
										<Button  color='red' onClick={this.cancelInfo}>Cancelar</Button>
									</div>
								}		
							</Segment>
						</div>
						}
						{ activeItem==='centros' &&
							<ListaInstalaciones data={instalacion.hijos} sincuentas={true}/>
						}
						{ activeItem==='partes' &&
							<ListaIncidencias data={instalacion.partes} update={this.getData} paginate={() => {}} empresageneral={this.props.empresageneral}/>
						}
						{ activeItem==='archivos' &&
							<ListaArchivos archivos={archivos} files="fileCentros"  updateFiles={this.getFiles} loading={loading_files}/>
						}

						{/* Lista de los elementos del inventario */}
						{ activeItem==="inventario" &&
							<List divided relaxed>
								{_.map(instalacion.inventario, ({ id, codigo, nombre, tipo }) => (
								<List.Item key={id}>
									{/* Solo usamos el List.Icon como envolvente para el identificador */}
 									<List.Icon size='large' verticalAlign='middle' onClick={() => this.viewMaterial(id)}> 
									 	<ContainerIdItemInInventory>
											{codigo}
										</ContainerIdItemInInventory>
									</List.Icon>

									<List.Content>
										<List.Header as={Link} to={'/material/'+id}>{ nombre }</List.Header>
										<List.Description>{tipo}</List.Description>
									</List.Content>
								</List.Item>
								))}
							</List>}
					</Grid.Column>
				</Grid.Row>

			</Grid>
		);
  }
 }

class CrearInventario extends Component {	
	constructor(props) {
		  super(props);
		  this.state = {
			  open: this.props.open, tipos: [], cursor: null,
			  nombre:'', centro: this.props.centro, tipo: null, descripcion: '', ubicacion: '', observaciones: '',
			  alto:'', ancho:'', fondo:'', peso:'', material:'', color:'', superficie:'', volumen:'',
			  marca:'', modelo:'', serie:'', proveedor:'', ffabricacion:null, fcompra:null, precio:''
		  };
	};	  
	panes = [
		{ menuItem: 'General', render: () => (<Tab.Pane attached='top'>
				<Form.Input value={this.state.nombre} label='Nombre' name='nombre' onChange={this.handleChange} error={!this.state.nombre && this.state.invalidate} />
				<Form.Field label='Material' control={Treebeard} data={this.state.tipos} onToggle={this.onToggle} style={tree} error={!this.state.cursor && this.state.invalidate}/>
				<Form.Input value={this.state.descripcion} label='Descripcion' name='descripcion' onChange={this.handleChange} />
				<Form.Input value={this.state.ubicacion} label='Ubicacion' name='ubicacion' onChange={this.handleChange} />
				<Form.Input value={this.state.observaciones} label='Observaciones' name='observaciones' onChange={this.handleChange} />
			</Tab.Pane>) },
		{ menuItem: 'Producto', render: () => <Tab.Pane attached='top'>
				<Form.Group widths='equal'>
					 <Form.Input value={this.state.alto} label='Alto (cm)' name='alto' onChange={this.handleChange} error={!Validations.isNumberOrVoid(this.state.alto)}/>
					 <Form.Input value={this.state.ancho} label='Ancho (cm)' name='ancho' onChange={this.handleChange} error={!Validations.isNumberOrVoid(this.state.ancho)}/>
				</Form.Group>
				<Form.Group widths='equal'>
					 <Form.Input value={this.state.fondo} label='Fondo (cm)' name='fondo' onChange={this.handleChange} error={!Validations.isNumberOrVoid(this.state.fondo)}/>
					 <Form.Input value={this.state.peso} label='Peso (gr)' name='peso' onChange={this.handleChange} error={!Validations.isNumberOrVoid(this.state.peso)}/>
				</Form.Group>
				<Form.Group widths='equal'>
					 <Form.Input value={this.state.material} label='Material' name='material' onChange={this.handleChange} />
					 <Form.Input value={this.state.color} label='Color' name='color' onChange={this.handleChange} />
				</Form.Group>
				<Form.Group widths='equal'>
					 <Form.Input value={this.state.superficie} label='Superficie (m2)' name='superficie' onChange={this.handleChange} error={!Validations.isNumberOrVoid(this.state.superficie)}/>
					 <Form.Input value={this.state.volumen} label='Volumen' name='volumen (m3)' onChange={this.handleChange} error={!Validations.isNumberOrVoid(this.state.volumen)}/>
				</Form.Group>
		</Tab.Pane> },
		{ menuItem: 'Comercial', render: () => <Tab.Pane attached='top'>
				<Form.Group widths='equal'>
					<Form.Input value={this.state.marca} label='Marca' name='marca' onChange={this.handleChange} />
					<Form.Input value={this.state.modelo} label='Modelo' name='modelo' onChange={this.handleChange} />
				</Form.Group>
				<Form.Group widths='equal'>
					<Form.Input value={this.state.serie} label='N. Serie' name='serie' onChange={this.handleChange} />
					<Form.Input value={this.state.precio} label='Precio' name='precio' onChange={this.handleChange} error={!Validations.isNumberOrVoid(this.state.precio)}/>
				</Form.Group>
				<Form.Group widths='equal'>
					<Form.Field control={DatePicker} dateFormar='DD/MM/YYY' selected={this.state.ffabricacion} label='Fecha fabricación' onChange={this.handleFechaFabricacion}/>
					<Form.Field control={DatePicker} dateFormar='DD/MM/YYY' selected={this.state.fcompra} label='Fecha Compra' onChange={this.handleFechaCompra}/>
				</Form.Group>
				<Form.Input value={this.state.proveedor} label='Proveedor' name='proveedor' onChange={this.handleChange} />
		</Tab.Pane> },
	  ]
	handleChange = (e, {name, value}) => this.setState({ [name]:value });
	handleFechaFabricacion = (date) => this.setState({ ffabricacion:date })
	handleFechaCompra = (date) => this.setState({ fcompra:date })	
	enviar = (event) => {
		if(this.state.centro && this.state.tipo && this.state.nombre){
			var allFields = _.clone(this.state)
			allFields.ffabricacion = (allFields.ffabricacion) ? allFields.ffabricacion.format('YYYY-MM-DD') : null;
			allFields.fcompra = (allFields.fcompra) ? allFields.fcompra.format('YYYY-MM-DD') : null; 
			allFields.tipos = null; allFields.open = null; allFields.cursor = null;
			IO.axios({
				url:'/data/inventario', 
				method: 'POST',
				data: JSON.stringify(allFields)})
			.then(response => {
						this.setState({ open: false})
						this.props.update();	
				});
		}else{
			this.setState({ invalidate: true})
		}
	 }
	open = () => this.setState({ open: true })
	close = () => this.setState({ open: false })
	resetComponent = () => this.setState({ 
		open: this.props.open, nombre:'', centro: this.props.centro, tipo: null, descripcion: '', ubicacion: '', observaciones: '',
		alto:'', ancho:'', fondo:'', peso:'', material:'', color:'', superficie:'', volumen:'',
		marca:'', modelo:'', serie:'', proveedor:'', ffabricacion:null, fcompra:null, precio:'', invalidate:null
	 })
	componentDidMount() {
		IO.axios.get('/data/tipomaterial')
		.then(response => {
			this.setState({ tipos: JSON.parse(_.replace(JSON.stringify(response.data),/title/g,'name')) });
		});
	 }
	onToggle = (node, toggled) => {
		if(this.state.cursor){
			var cursor = this.state.cursor;
			cursor.active = false;
			cursor.toggled = _.filter(cursor.children,{key:node.key}).length > 0 ? true : false;
			this.setState({cursor:cursor});
		}
        node.active = true;
        if(node.children){ node.toggled = toggled; }
        this.setState({ cursor: node, tipo: node.key });
    }
	render() {
		const estado = this.state;
		return (
			<Modal open={estado.open}  onOpen={this.open}  onClose={this.close}
			size='small' trigger={<Menu.Item name='enviar' >Nuevo inventario</Menu.Item>}
			>
				<Modal.Header>Nuevo inventario</Modal.Header>
				<Modal.Content>						
						<Form error={estado.invalidate}>
							<Tab panes={this.panes} menu={{ secondary: true, pointing: true }} />
							<Message
								error
								header='Error en formulario'
								content='Complete los campos en rojo.'
							/>						 													
						</Form>
				</Modal.Content>
				<Modal.Actions>
							<Button  content='Aceptar' onClick={this.enviar} />
							<Button  content='Cancelar' onClick={this.resetComponent} />
				</Modal.Actions>
			</Modal>
	  	)
	}
   }

const ContainerIdItemInInventory = styled.div`
	border: 1px solid #d5cfcf;
	border-radius: 3px;
	padding: 4px;
	width: 140px;
	color: #4183c4;
	font-size: 18px;
    padding: 6px 0px;
	font-family: "Lato";
    font-weight: 600;
	cursor: pointer;
`;

export default DetalleInstalacion;