import React, {useState, useEffect} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import {distritos} from '../js/constdata';

export function YCSFormFieldDistrito(props){
    const [Mydistritos, setDistritos] = useState([]);
    useEffect(() => {
        setDistritos(distritos);
    }, []);
    
    return (
        <Form.Field
            {...props} control={Dropdown}  placeholder='Seleccione' 
             fluid selection search name='distrito' options={Mydistritos}
            value={props.value} onChange={props.onChange} 
        />
    );
}
