import _ from 'lodash';
import React, { Component } from 'react';
import {Grid, Header, Segment, Label,  Button, Input, Message} from 'semantic-ui-react';
import {IO} from './js/IO'
import {Storage} from "./js/storage";
import {SHA1} from './js/sha1'

class Perfil extends Component {	
	constructor(props) {
		super(props);
		this.state = { 
			original: {password: ""}, 		// Almacenos la info del user del backend, solo se usa para restaurar datos, cuando se
											// hace click en el button Cancelar
			usuario: null,
			edit : false,					// false = all fileds disabled, true = all fields enabled
			errorEmptyPassword: false, 		// false = show msg, false = hide msg error
			errorEmptyEmail: false, 		// false = show msg, false = hide msg error
		};
	}

	/**
	 * habilitamos los campos y reseteamos la password a ""
	 */
	handleEdit = () => this.setState({ edit : true});

	cancel = () => this.setState({ edit : false, usuario: _.clone(this.state.original), errorEmptyPassword: false, errorEmptyEmail: false });

	/**
	 * Solo se guarda la info si la password es != ""
	 */
	save = () => {
		var u = this.state.usuario;
		if(!u.password){
			this.setState({errorEmptyPassword: true});
			return;
		}
		if(!u.email){
			this.setState({errorEmptyEmail: true});
			return;
		}
		var allFields = {
			nombre: u.nombre,
			apellidos: u.apellidos, 
			email: u.email,
			password: (u.password === this.state.original.password) ? u.password : SHA1(u.password)
		}		
		IO.axios({
			url:'/data/users.php/'+u.id, 
			method: 'PUT',
			data: JSON.stringify(allFields)})
		.then(response => {
				this.setState({ edit: false, usuario:null, errorEmptyPassword: false, errorEmptyEmail: false },this.getUsers);
		});
	}

	handleChange = (e, {name,value}) =>{
		var usuario = this.state.usuario;
		usuario[name] = value;
		this.setState({usuario});
	}

	getUsers = () =>{
		IO.axios.get('/data/users.php/'+Storage.getStorage('user').id)
		.then(response => {
			let copyResponse = _.cloneDeep( response.data[0] );
			copyResponse.password = "";
			this.setState({
				 original : response.data[0],
				  usuario : copyResponse
			});
		});
	}

	componentDidMount(){this.getUsers();}

	render() {
		const { original, usuario, edit , errorEmptyPassword, errorEmptyEmail} = this.state;
		// console.log("this..setState Perfil.jsx: ", this.state);
		return (
				<Grid container style={{ marginTop: '3em' }} width='100%'>
					<Grid.Row>
						<Segment raised>
							<Label attached='top left' content='Datos Personales'/>
							{/* <Label attached='top left' content={usuario?.username || ""}/> */}
							<Grid >
								<Grid.Row>
									<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Nombre</Header.Subheader>
										{!edit ? original.nombre
										:<Input name='nombre' input={{autoComplete:"off"}} fluid value={usuario.nombre} onChange={this.handleChange}/>}
									</Grid.Column>
									<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Apellidos</Header.Subheader>
										{!edit ? original.apellidos
										:<Input name='apellidos' input={{autoautoCompletecomplete:"off"}} fluid value={usuario.apellidos} onChange={this.handleChange}/>}
									</Grid.Column>
									<Grid.Column width={2} as={Header} size='small'><Header.Subheader>Usuario</Header.Subheader>
										{usuario?.username || ""}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Password</Header.Subheader>
										{!edit ? '******'
										:<Input name='password' type='password' input={{autoComplete:"off"}} fluid value={usuario.password} onChange={this.handleChange}/>}
									</Grid.Column>

									<Grid.Column width={6} as={Header} size='small'><Header.Subheader>Email</Header.Subheader>
										{!edit ? original.email
										: <Input name="email" size='small' fluid value={usuario.email} onChange={this.handleChange} />}
									</Grid.Column>
								</Grid.Row>

								{errorEmptyPassword && (
									<Grid.Row>
										<Grid.Column>
											<Message error header="Aviso!" content="La password no puede estar vac�a"/>
										</Grid.Column>
									</Grid.Row>
								)}

								{errorEmptyEmail && (
									<Grid.Row>
										<Grid.Column>
											<Message error header="Aviso!" content="El email no puede estar vac�o"/>
										</Grid.Column>
									</Grid.Row>
								)}

								<Grid.Row>
									<Grid.Column as={Header} size='small'><Header.Subheader>Perfiles</Header.Subheader>
										{_.map(original.roles,(a) => <Label key={a.id} style={{margin:'5px'}} content={a.nombre} />)}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>	
										{!edit && <Button content='Editar' onClick={this.handleEdit} primary floated='right' />}
										{edit && <Button content='Cancelar' onClick={this.cancel} color='red' floated='right' />}
										{edit && <Button content='Guardar' onClick={this.save} primary floated='right'/>}
									</Grid.Column>
								</Grid.Row>
							</Grid>															
						</Segment>
					</Grid.Row>
				</Grid>
		);
  }
}

export default Perfil;