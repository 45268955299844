import _ from 'lodash'
import React, { Component } from 'react';
import { Segment,  Dimmer, Loader, Label, Card, Image, Popup} from 'semantic-ui-react';
import {Link } from 'react-router-dom';
import {tipoInstalaciones} from './js/constdata';
import {Storage} from './js/storage';


class ListaInstalaciones extends Component {	
	
	render() {
		const {data} = this.props;
		const user = Storage.getStorage('user');

		return (	
			<Card.Group itemsPerRow={2}>
					<Dimmer inverted active={this.props.loading}>
						<Loader inverted >Cargando instalaciones ...</Loader>
					</Dimmer>
					{_.map(data, (centro) => (
						!centro.centro_id &&(
									<Card key={centro.id}>
										<Card.Content>
										<Image floated='right' size='mini' src={'/img/'+_.find(tipoInstalaciones,{value:centro.tipo}).icono} />
										<Card.Header as={Link} to={'/instalaciones/'+centro.id+'/detalles'}>{centro.nombre}</Card.Header>
										<Card.Meta>{centro.tipo}</Card.Meta>
										<Card.Description>{centro.distrito} - {centro.direccion}</Card.Description>
										<Card.Content extra>
											{/* <Label  content={'Id: '+centro.id}/> */}
											<Label  content={'Codigo: '+centro.codigo_imd}/>
											<Label  content={'Patrimonio: '+centro.codigo_patrimonio}/>
											{!this.props.sincuentas && (user.servicio || user.administracion) && <div className='derecha'>
												<Popup trigger={<Label circular color='green' as={Link} to={{pathname:'/incidencias/',search:'?centro='+centro.id+'&estado=nueva'}}>
																	{_.filter(centro.cuentas,{estado:'NUEVA'}).length > 0 ? _.filter(centro.cuentas,{estado:'NUEVA'})[0].total : 0}
																</Label>} 
														content='Incidencias Nuevas'/>
												<Popup trigger={<Label circular color='orange' as={Link} to={{pathname:'/incidencias/',search:'?centro='+centro.id+'&estado=resuelta'}}>
																	{_.filter(centro.cuentas,{estado:'RESUELTA'}).length > 0 ? _.filter(centro.cuentas,{estado:'RESUELTA'})[0].total : 0}
																</Label>} 
														content='Incidencias Resueltas'/>
												<Popup trigger={<Label circular color='olive' as={Link} to={{pathname:'/incidencias/',search:'?centro='+centro.id+'&estado=valorada'}}>
																	{_.filter(centro.cuentas,{estado:'VALORADA'}).length > 0 ? _.filter(centro.cuentas,{estado:'VALORADA'})[0].total : 0}
																</Label>} 
														content='Incidencias Valoradas'/>
											</div>}								
										</Card.Content>
										{_.filter(data,{centro_id:centro.id}).length > 0 &&
											<Segment raised>
												{_.map(_.filter(data,{centro_id:centro.id}), (subcentro) => 
													<div key={subcentro.id}>
														<Image  width='25px' src={'/img/'+_.find(tipoInstalaciones,{value:subcentro.tipo}).icono} />
														<Link to={'/instalaciones/'+subcentro.id } style={{ marginLeft: '1em' }}>{subcentro.codigo_imd + " - "+subcentro.nombre}</Link>
													</div>
												)
												}
											</Segment>
										}
										
										</Card.Content>
									</Card>
							)
					))}
			</Card.Group>
		);
	}
	
}

export default ListaInstalaciones;

